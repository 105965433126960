// StartApplication/InitialMessage.jsx
import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  icon: {
    fontSize: 48,
    color: theme.palette.info.main,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  message: {
    maxWidth: 600,
    margin: '0 auto',
    color: theme.palette.text.secondary,
  },
  infoBox: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: '#f5faff',
    borderRadius: theme.shape.borderRadius,
    color: '#000',
    maxWidth: 600,
    border: `1px solid #d7e9fb`,
  }
}));

const InitialMessage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.infoBox}>
        <Typography variant="body2">
          <strong>Important:</strong> If the student is eligible for both programs, you can apply for both scholarships. Each student needs a separate application.
        </Typography>
      </Box>
    </Box>
  );
};

export default InitialMessage;