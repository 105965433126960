import React, { useState, useEffect } from 'react';
import { Button, FormControl, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import API from '../../config/api';

const DDAgreements = ({ application, account, lock }) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [agree, setAgree] = useState(application.isAgreeStatements);

	const { period: applicationPeriod } = application;
	// handle success alert
	useEffect(() => {
		if (success) setTimeout(() => setSuccess(false), 1500);
	}, [success]);

	const handleAgreeChange = (event) => {
		const { checked } = event.target;
		setAgree(checked);
		updateStatements(checked);
	};

	const updateStatements = (agree) => {
		const { applicationId } = application,
			{ tokenAuth } = account;
		const data = { agree, applicationId };

		setLoading(true);

		API.SetAgreeStatements(tokenAuth, data).then((res) => {
			setLoading(false);
			setSuccess(true);
		});
	};

	return (
		<div className='form__section' style={{ marginBottom: 30 }}>
			<div className='form__section--header'>
				<h3>5. Agreement Statements</h3>
				{success && !loading && (
					<Button size='small' color='primary' endIcon={<SaveIcon />} className='text-transform-none' style={{ whiteSpace: 'nowrap' }} disableRipple>
						Auto-saved
					</Button>
				)}
				{loading && <CircularProgress size={15} color='primary' />}
			</div>

			<div className='section__student-agreements-statements'>
				<FormControl component='fieldset'>
					<div className='agreements-statements-box'>
						<h3 className='strong-text'>I agree to the following:</h3>
						<ul className='agreements__list bullet-list'>
							<li>
								Financial aid awarded to the applicant (s) must be used, as allowed by Arizona law, solely for tuition expenses at a qualified private school of the applicant’s choice. Any scholarship
								amount above the student’s tuition balance, must be returned by the private school, to the Arizona Leadership Foundation for reallocation.
							</li>
							<li>The sum of a student’s D/D scholarships is limited to 90% of state aid or cost of tuition, whichever is less.</li>
							<li>
								A student that receives ESA (Empowerment Scholarship Account) money from the Arizona Department of Education cannot receive funds from any school tuition organization for the same
								academic period. The applicant is not receiving ESA money for the {applicationPeriod} school year.
							</li>
							<li>Arizona Leadership Foundation has absolute and sole discretion in awarding financial aid.</li>
							<li>
								Financial aid is distributed without regard to ethnicity, sex, or handicap. Financial aid is only for the current academic year and continuing aid is based upon the applicant’s
								eligibility and funds available for disbursement.
							</li>
							<li>The applicant meets the criteria set forth in Section 2.</li>
						</ul>
						<FormControlLabel control={<Checkbox color='primary' checked={agree} />} label='I agree with the statements above' onChange={handleAgreeChange} />
					</div>
				</FormControl>
			</div>

			{lock && <div className='layer-locked' />}
		</div>
	);
};

export default DDAgreements;
