import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';

import LIStudentInformation from './LIStudentInformation';
import LIFinancialInformation from './LIFinancialInformation';
import LIFinancialInformationV2 from './LIFinancialInformationV2';
import LIAgreements from './LIAgreements';
import Loader from '../../component/Loader';
import ApplicationNotFound from './ApplicationNotFound';
import AppAlert from '../../component/Alert';
import AdminMessageAlert from '../../component/AdminMessageAlert';

import API from '../../config/api';
import HelloSignClass from '../../utils/hellosign';

import { setApplicationRdx, setLoadingApplicationRdx } from '../../redux/actions/application';
import { connect } from 'react-redux';

const LIApplication = ({ account, editId, onValidApplication, codeValidated, setApplicationRdx, setLoadingApplicationRdx }) => {
	const [openSignAlert, setOpenSignAlert] = useState(false);
	const [openAdminAlert, setOpenAdminAlert] = useState(false);
	const [dataVersion, setDataVersion] = useState(10);
	const [error, setError] = useState(null);
	const [submitError, setSubmitError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [application, setApplication] = useState({});

	const financialInformationRef = useRef(null);

	useEffect(() => initApplication(), []);

	// handle error on member file
	useEffect(() => {
		if (!submitError) return;
		if (submitError.field == 'member_file') financialInformationRef.current.openFirstFinancial();
	}, [submitError]);

	const initApplication = () => {
		!editId ? createApplication() : getApplication();
	};

	const createApplication = () => {
		setLoadingApplicationRdx();
		API.CreateLIApplication(account.tokenAuth).then((response) => {
			const { status, message, data } = response;
			if(status != 201) { 
				setError(message)
			}else{
				const newApp = { ...data };
				setApplication(newApp);
				setApplicationRdx(newApp);
				setDataVersion(newApp.version||10);
			}
			setLoading(false);
		});
	};

	const getApplication = () => {

		setLoadingApplicationRdx();
		API.GetApplication(account.tokenAuth, { id: editId }).then((response) => {
			const { status, message, data } = response;
			if (status != 201 || status == 600) {
				setError(message);
				onValidApplication(false);
				setLoading(false);
			} else {
				setApplicationRdx(data);
				setDataVersion(data.version||10);								
				setApplication(data);			
				setLoading(false);
			}		
		});
	};

	const onValidated = (isValidated) => {
		if (isValidated) requestSign();
	};

	const askForCode = () => {
		if (!codeValidated) return toggleAdminAlert();
		requestSign();
	};

	const requestSign = async () => {
		setSubmitLoading(true);
		setSubmitError(null);

		const HelloSign = new HelloSignClass();

		HelloSign.applicationId = application.applicationId;
		HelloSign.name = `${account.firstName} ${account.lastName}`;
		HelloSign.email = account.email;
		HelloSign.token = account.tokenAuth;

		// validate financial information before submittin the application
		const financialResponse = await financialInformationRef.current.saveFinancialOnSubmit();
		if (financialResponse && financialResponse.status != 201) {
			setSubmitError({ message: 'Financial member information is incomplete. Please see the financial information section for further instruction' });
			return setSubmitLoading(false);
		}

		const request = await HelloSign.RequestSignature(()=>{
			//setOpenSignAlert(true);
			setTimeout(() => {
				let modalContent = document.querySelector('.x-hellosign-embedded__modal-content');

				let div = document.createElement('div');
				div.innerHTML = '<p style="text-align: center;width: 100%;position: relative;top: 29px;height: 0;color: #ab0101;font-weight: bold;">*** Please scroll to the bottom to sign ***"</p>';

				modalContent.prepend(div.firstChild);		
			}, "4000")			
		});

		if (request.status != 201) setSubmitError({ field: request.field, message: request.message });
		

		setSubmitLoading(false);
	};

	const toggleAdminAlert = () => setOpenAdminAlert(!openAdminAlert);

	const htmlLabel = (label) => {
		return { __html: label };
	};

	const { status } = application;

	const { isClosedSubmission } = account.siteSettings;
	const allowSign = !isClosedSubmission || status == 7;

	let content;

	if (!error)
		content = (
			<Fragment>
				{application != null && (application.status === 6 || (application.status === 7 && application.rejectedReasons.length > 0)) && (
					<div style={{ marginBottom: 40, marginTop: 40 }}>
						<Alert severity='warning'>
							<AlertTitle>Reasons: </AlertTitle>
							<Fragment>
								<ul style={{ listStyleType: 'square', listStylePosition: 'inside' }}>
									{application.rejectedReasons.length > 0 &&
										application.rejectedReasons.map((x, i) => {
											return (
												<li key={i} style={{ paddingBottom: 15 }}>
													<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
												</li>
											);
										})}
								</ul>
							</Fragment>
							{application.rejectedNotes != null && (
								<Fragment>
									<AlertTitle>Note:</AlertTitle>
									<span>{application.rejectedNotes}</span>
								</Fragment>
							)}
						</Alert>
					</div>
				)}
				{application != null && (application.status === 4 || (application.status === 7 && application.incompletedReasons.length > 0)) && (				
					<div style={{ marginBottom: 40, marginTop: 40 }}>
						<Alert severity='warning'>
							<AlertTitle>Reasons: </AlertTitle>
							<Fragment>
								<ul style={{ listStyleType: 'square', listStylePosition: 'inside' }}>
									{application.incompletedReasons.length > 0 &&
										application.incompletedReasons.map((x, i) => {
											return (
												<li key={i} style={{ paddingBottom: 15 }}>
													<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
												</li>
											);
										})}
								</ul>
							</Fragment>
							{application.incompleteNotes != null && (
								<Fragment>
									<AlertTitle>Note:</AlertTitle>
									<span>{application.incompleteNotes}</span>
								</Fragment>
							)}
						</Alert>
					</div>
				)}
				<LIStudentInformation application={application} account={account} submitError={submitError} />
				{dataVersion <= 10 ? (
					<LIFinancialInformation ref={financialInformationRef} application={application} account={account} />
				): dataVersion <= 20 ? (
					<LIFinancialInformationV2 ref={financialInformationRef} application={application} account={account} />
				): (
					// <p>COMPONENTE PARA LA VERSION 30</p>
					<LIFinancialInformationV2 ref={financialInformationRef} application={application} account={account} />
				)}

				<LIAgreements application={application} account={account} />
				<div className='application-buttons'>
					{!submitLoading ? (
						<Button variant='contained' color='primary' startIcon={<SendIcon />} onClick={allowSign ? requestSign : askForCode} fullWidth disableRipple>
							Review & Submit
						</Button>
					) : (
						<Loader loading />
					)}
					<Link to='/' onClick={(e) => submitLoading && e.preventDefault()}>
						<Button color='primary' startIcon={<SaveIcon />} fullWidth disableRipple>
							Save and exit
						</Button>
					</Link>
				</div>
				{submitError && (
					<div style={{ marginTop: 20 }}>
						<Alert severity='error' onClose={(_) => setSubmitError(null)}>
							{submitError.message}
						</Alert>
					</div>
				)}

				<AppAlert
					showIcon={false}
					open={openSignAlert}
					text='After this page has loaded, please scroll to the bottom of the document to sign and complete this application'
					onConfirm={() => setOpenSignAlert(false)}
				/>

				<AdminMessageAlert type='LI' open={openAdminAlert} onClose={toggleAdminAlert} onValidated={onValidated} />
			</Fragment>
		);
	else content = <ApplicationNotFound message={error} />;

	return <div className='application-li-form'>{!loading ? content : <Loader loading={true} />}</div>;
};

const mapDispatchToProps = {
	setApplicationRdx, setLoadingApplicationRdx
};

export default connect(null, mapDispatchToProps)(LIApplication);
