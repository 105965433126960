import React, { useState, useEffect, useRef, Fragment } from 'react';
import moment from 'moment';
import { Grid, Button, FormGroup, FormControl, FormControlLabel, TextField, OutlinedInput, Select, InputLabel, MenuItem, Checkbox, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import WarningIcon from '@material-ui/icons/Warning';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import ModalAlert from '../../component/Alert';
import TextFieldAutoComplete from '../../component/AutoComplete';

import API from '../../config/api';

const DDStudentForm = ({ application, account, onESAChange, getName }) => {
	const [openAlert, setOpenAlert] = useState(false);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formState, setFormState] = useState({
		firstName: '',
		lastName: '',
		gender: '',
		dateOfBirth: null,
		school: '',
		grade: '',
		lastYearSchool: '',
		isActiveFosterCare: null,
		hasApplicantESA: null,
		additionalFactors: '',
		isSpotlightStudent: null,
	});

	const { period: applicationPeriod } = application;
	const autosaveRef = useRef(null);

	// handle fill form if edit mode
	useEffect(() => {
		const { students } = application;

		if (students.length == 0) return;

		let student = { ...students[0] };

		delete student.applicationId;
		delete student.hasBlueprintSubmitted;
		delete student.hasOtherProofSubmitted;

		setFormState(student);

		if (student.hasApplicantESA) {
			setOpenAlert(true);
			onESAChange(true);
		}
	}, []);

	// handle auto-save
	useEffect(() => {
		const timeout = setTimeout(() => autosaveRef.current && updateStudent(), 3000);
		return () => clearTimeout(timeout);
	}, [formState]);

	// handle success
	useEffect(() => {
		if (success) setTimeout(() => setSuccess(false), 3000);
	}, [success]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
		setFormError(null);
		autosaveRef.current = true;
	};

	const renderSchools = async () => {
		const results = await API.GetSchoolNames(account.tokenAuth);
		if (!results || !results.data) return;
		return results.data.map((x) => x.name);
	};

	const handleCheckboxChange = (event) => {
		const { name, value } = event.target;
		const checked = value == 'yes';
		setFormState({ ...formState, [name]: checked });
		setFormError(null);
		if (name == 'hasApplicantESA') {
			setOpenAlert(checked);
			onESAChange(checked);
		}
		autosaveRef.current = true;
	};

	const handleDateChange = (date) => {
		setFormState({ ...formState, dateOfBirth: date });
		autosaveRef.current = true;
	};

	const updateStudent = () => {
		const { applicationId } = application;

		setFormError(null);
		setLoading(true);

		let dateOfBirth = null;
		if(!!formState.dateOfBirth){			
			let onlyDate = null;
			if(moment.isMoment(formState.dateOfBirth)){
				onlyDate = formState.dateOfBirth.format('MM/DD/YYYY');
			}else{
				onlyDate = formState.dateOfBirth.split('T')[0];
			}
			dateOfBirth = moment(onlyDate).format('MM/DD/YYYY');
		}
		
		const data = { ...formState, dateOfBirth, applicationId };

		API.UpdateDDStudent(account.tokenAuth, data).then((res) => {
			setLoading(false);
			if (res.status !== 201) return setFormError(res.message);

			setSuccess(true);
			let name = res.data.firstName + ' ' + res.data.lastName;
			getName({ name });
		});
	};

	return (
		<Fragment>
			<div className='form__section'>
				<div className='form__section--header'>
					<h3>
						1. Student Information <small>(One student per application)</small>
					</h3>
					{success && !loading && (
						<Button size='small' color='primary' endIcon={<SaveIcon />} className='text-transform-none' disableRipple>
							Auto-saved
						</Button>
					)}
					{loading && <CircularProgress size={15} color='primary' />}
				</div>

				<div className='form__section--body'>
					<Grid container>
						<Grid item xs={12} lg={7}>
							<div className='section__user-information'>
								<FormControl component='fieldset' fullWidth style={{ marginTop: 30 }}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={6}>
											<TextField label='First Name' name='firstName' value={formState.firstName} color='primary' variant='outlined' inputProps={{ maxLength: 120 }} onChange={handleChange} fullWidth />
										</Grid>
										<Grid item xs={12} md={6}>
											<TextField label='Last Name' name='lastName' value={formState.lastName} color='primary' variant='outlined' inputProps={{ maxLength: 120 }} onChange={handleChange} fullWidth />
										</Grid>
										<Grid item xs={12} md={6}>
											<FormControl variant='outlined' fullWidth>
												<InputLabel id='gender-label'>Gender</InputLabel>
												<Select label='Gender' labelId='gender-label' id='gender' name='gender' value={formState.gender} onChange={handleChange}>
													<MenuItem value='M'>Male</MenuItem>
													<MenuItem value='F'>Female</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} md={6}>
											<MuiPickersUtilsProvider utils={MomentUtils}>
												<KeyboardDatePicker
													autoOk
													fullWidth
													disableFuture
													mask='__/__/____'
													openTo='year'
													variant='inline'
													format='MM/DD/YYYY'
													label='Date of Birth'
													inputVariant='outlined'
													emptyLabel='mm/dd/yyyy'
													InputAdornmentProps={{ position: 'start' }}
													value={formState.dateOfBirth}
													onChange={handleDateChange}
												/>
											</MuiPickersUtilsProvider>
										</Grid>
										<Grid item xs={12} md={8}>
											<TextFieldAutoComplete
												label='Private School Attending / Enrolled At:'
												noOptionsText='School not found. Please contact AZLF.'
												name='school'
												defaultValue={formState.school}
												onLoad={renderSchools}
												onChange={handleChange}
												inputProps={{ maxLength: 255 }}
											/>
										</Grid>
										<Grid item xs={12} md={4}>
											<FormControl variant='outlined' fullWidth>
												<InputLabel id='grade-label'>Grade</InputLabel>
												<Select label='Grade' labelId='grade-label' id='grade' name='grade' value={formState.grade} onChange={handleChange}>
													<MenuItem value='PK'>PK</MenuItem>
													<MenuItem value='K'>K</MenuItem>
													<MenuItem value='1'>1</MenuItem>
													<MenuItem value='2'>2</MenuItem>
													<MenuItem value='3'>3</MenuItem>
													<MenuItem value='4'>4</MenuItem>
													<MenuItem value='5'>5</MenuItem>
													<MenuItem value='6'>6</MenuItem>
													<MenuItem value='7'>7</MenuItem>
													<MenuItem value='8'>8</MenuItem>
													<MenuItem value='9'>9</MenuItem>
													<MenuItem value='10'>10</MenuItem>
													<MenuItem value='11'>11</MenuItem>
													<MenuItem value='12'>12</MenuItem>
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<TextField
												label='Please list the name of the school your child attended last school year'
												name='lastYearSchool'
												value={formState.lastYearSchool}
												color='primary'
												variant='outlined'
												inputProps={{ maxLength: 255 }}
												onChange={handleChange}
												fullWidth
											/>
										</Grid>
										<Grid item xs={12}>
											{openAlert &&
											<div className='ESA-locked__message'>
												<WarningIcon />												
												<span>
												  Student is ineligible for this scholarship because they have qualified for an ESA													
												</span>
											</div>
											}
											<FormControl className='form-control-checkbox' style={{ margin: 0 }}>
												<label>Does the applicant have an Empowerment Scholarship Account (ESA) from the Arizona Department of Education for the current school year ({applicationPeriod})?</label>
												<FormGroup>
													<FormControlLabel control={<Checkbox color='primary' name='hasApplicantESA' value='yes' checked={formState.hasApplicantESA} />} label='Yes' onChange={handleCheckboxChange} />
													<FormControlLabel
														control={<Checkbox color='primary' name='hasApplicantESA' value='no' checked={formState.hasApplicantESA == false} />}
														label='No'
														onChange={handleCheckboxChange}
													/>
												</FormGroup>
											</FormControl>
										</Grid>
										<Grid item xs={12}>
											<TextField
												label='Please list additional personal or economic factors (if any), that you feel Arizona Leadership Foundation  should take into  consideration while reviewing  this application'
												name='additionalFactors'
												value={formState.additionalFactors}
												color='primary'
												variant='outlined'
												className='multiline'
												onChange={handleChange}
												fullWidth
												multiline
											/>
										</Grid>
										<Grid item xs={12}>
											<FormControl className='form-control-checkbox' style={{ margin: 0 }}>
												<label>Would your student be interested in being featured as a student spotlight, sent to donors and featured on our website?</label>
												<FormGroup>
													<FormControlLabel
														control={<Checkbox color='primary' name='isSpotlightStudent' value='yes' checked={formState.isSpotlightStudent} />}
														label='Yes'
														onChange={handleCheckboxChange}
													/>
													<FormControlLabel
														control={<Checkbox color='primary' name='isSpotlightStudent' value='no' checked={formState.isSpotlightStudent == false} />}
														label='No'
														onChange={handleCheckboxChange}
													/>
												</FormGroup>
											</FormControl>
										</Grid>
										{formError && (
											<Grid item xs={12}>
												<Alert severity='error' onClose={() => setFormError(null)}>
													{formError}
												</Alert>
											</Grid>
										)}
									</Grid>
								</FormControl>
							</div>
						</Grid>
					</Grid>
				</div>
			</div>

			{/* <ModalAlert open={openAlert} title='Application Locked' text='Student is ineligible for this scholarship because they have qualified for an ESA' onConfirm={(_) => setOpenAlert(false)} /> */}
		</Fragment>
	);
};

export default DDStudentForm;
