import React, {Fragment} from 'react';
import { connect } from 'react-redux';

import { Grid, Button } from '@material-ui/core';
import { BorderedPanel, BorderedPanelHeader, BorderedPanelSection, BorderedPanelSectionHeader } from '../../../component/BorderedPanel';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';


import API from '../../../config/api';

import MediaManager from '../../../component/MediaManager';

const FILEKEYS = {
	FEDERALINCOMETAX: 'federal_income_tax',
	TAXEXTENSION: 'tax_extension',
	PROOFINCOME: 'proof_of_income',
	OTHERPROOFINCOME: 'other_proof_of_income',
	OTHERFILE: 'other_file',
	FOSTERSTATUS: 'foster_status',
};

const FinancialInformationView = (props) => {
	const handleViewFile = (financialId, fileKey) => {
		const { tokenAuth } = props.account;
		const data = { applicationId: props.id, financialId, fileKey };

		const viewFile = window.open();

		API.GetDownloadFileUrl(tokenAuth, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	const OptionManger = ({isActive, title, info}) => {

		return (
			<div className={`form-control-media`}>
					<label className="media-title">{title}</label>
					<span className="media-info">{info}</span>
					<div className='media__file'>
						<div className='simple-attachment__box'>
							<div className='attachment__box--header'>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 0 }}>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginRight: 20 }}>
										{isActive ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon />}
										<span style={{ marginLeft: 10 }}>Yes</span>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
										{!isActive ? <CheckBoxIcon color='primary' /> : <CheckBoxOutlineBlankIcon />}
										<span style={{ marginLeft: 10 }}>No</span>
									</div>
								</div>
							</div>
							
						</div>
					</div>
					
				</div>
		);
	};

	const renderMediaUploader = formState =>{	
		const isLiApplication = props.applicationType === 'LI'? true : false;			
		const isActiveFosterCare = props?.application?.data?.financialQuestions?.q6_foster_status;
		
		return (
			<div className="financial-files">
				<MediaManager title={`TAX RETURN for ${props.previousYear}`} info="Pages 1 & 2 of IRS FORM 1040" keyName={FILEKEYS.FEDERALINCOMETAX} readOnly="true"  />		
				<MediaManager title={`TAX EXTENSION for ${props.previousYear}`} info="Tax Extension Notice and pages 1 & 2 of the previous year IRS FORM 1040" keyName={FILEKEYS.TAXEXTENSION} readOnly="true"   />
				<MediaManager title={`OTHER Proof of Income`} info="Copy of other proof of income (most recent paystub(s), W-2 from employer, etc.) for ALL household members receiving income." keyName={FILEKEYS.PROOFINCOME} readOnly="true"   />
				<MediaManager title="OTHER Sources of Income" info="Nutrition Assistance (Food Stamp Program), Cash Assistance, AHCCCS, Social Security, Disability, Adoption Subsidy, Distribution from IRA, Pension, Annuity, Retirement, Trust, etc." keyName={FILEKEYS.OTHERPROOFINCOME} readOnly="true"  />
				<MediaManager title="Recent changes in your household income" info="notice from employer of layoff/furlough/reduced hours, unemployment (if receiving), current paystub(s) reflecting reduced hours or any additional documentation" keyName={FILEKEYS.OTHERFILE} readOnly="true"  />
				{isLiApplication?  
				<MediaManager title="Foster Status" info="Documentation for active foster status" keyName={FILEKEYS.FOSTERSTATUS} readOnly="true"  />
				: 
				<OptionManger title="Foster Status" info="Information for foster status" isActive={!!isActiveFosterCare}/>
				}				
			</div>
		);
	};

	const renderFileUploader = r =>{
		return (
			<Fragment>
				<div className='attachements'>
					<div className='attachements__body'>
						{(r.federal_income_tax_key || r.federal_income_tax2_key || r.federal_income_tax3_key) && (
							<div className='attachment__box'>
								<div className='attachment__box--header'>
									<h5>{props.previousYear} Federal Income Tax Return</h5>
								</div>
								<div className='attachment__box--body'>
									<p>IRS 1040</p>
									<p>Pages 1 & 2</p>
									{r.federal_income_tax_key && (
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'federal_income_tax')}>
											View file
										</Button>
									)}
									{r.federal_income_tax2_key && (
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'federal_income_tax2')}>
											View file
										</Button>
									)}
									{r.federal_income_tax3_key && (
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'federal_income_tax3')}>
											View file
										</Button>
									)}
								</div>
							</div>
						)}
						{r.tax_extension_key && (
							<div className='attachment__box'>
								<div className='attachment__box--header'>
									<h5>Tax Extension Notice and pages 1 & 2 of the previous year IRS FORM 1040</h5>
								</div>
								<div className='attachment__box--body'>
									<p>Tax extension return filing deadline is October 15th.</p>
									{
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'tax_extension')}>
											View file
										</Button>
									}
								</div>
							</div>
						)}
						{r.proof_of_income_key && (
							<div className='attachment__box'>
								<div className='attachment__box--header'>
									<h5>Copy of other proof of income</h5>
								</div>
								<div className='attachment__box--body'>
									<p>(most recent paystub(s), W-2 from employer, etc.) for ALL household members receiving income.</p>
									{
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'proof_of_income')}>
											View file
										</Button>
									}
								</div>
							</div>
						)}
						{r.other_proof_of_income_key && (
							<div className='attachment__box'>
								<div className='attachment__box--header'>
									<h5>Other Proof of Income</h5>
								</div>
								<div className='attachment__box--body'>
									<p>Nutrition Assistance / Food Stamp Program, Unemployment Program, Social Security Disability, etc.</p>
									{
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'other_proof_of_income')}>
											View file
										</Button>
									}
								</div>
							</div>
						)}
						{(r.other_file_key || r.extra_file1_key || r.extra_file2_key) && (
							<div className='attachment__box'>
								<div className='attachment__box--header'>
									<h5>Other Files</h5>
								</div>
								<div className='attachment__box--body'>
									<p>Other supportive files or documents</p>															
									{r.other_file_key && (
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'other_file')}>
											View file
										</Button>
									)}
									{r.extra_file1_key && (
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'extra_file1')}>
											View file
										</Button>
									)}
									{r.extra_file2_key && (
										<Button color='primary' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile(r.financialId, 'extra_file2')}>
											View file
										</Button>
									)}

								</div>
							</div>
						)}
					</div>
				</div>
			</Fragment>		
		);
	};



	return (
		<Grid container spacing={3}>
			<Grid item xs={12} lg={7}>
				<div className='section__student-financial'>
					<div className='financial-information__component'>
						<BorderedPanel color={'#707070'}>
							<BorderedPanelHeader>
								<h4>
									<span>List all members in household</span>
									<span>(Please include ALL guardians, children and other family members living in the home)</span>
								</h4>
							</BorderedPanelHeader>
							{props.financialInformation.map((r, i) => {
								return (
									<BorderedPanelSection key={i}>
										<BorderedPanelSectionHeader>
											<h4>
												Member {i + 1} -{' '}
												<span className='text-transform-none'>
													{r.firstName} {r.lastName}
												</span>
											</h4>
										</BorderedPanelSectionHeader>
										<div className='member-incomes'>
											<p>
												Type of Member: <span>{r.type}</span>
											</p>
											<p>
												Yearly Gross Earning from work: <span>{r.yearlyEarnings}</span>
											</p>
											<p>
												Yearly Welfare, Child Support, Alimony: <span>{r.yearlyWelfare}</span>
											</p>
											<p>
												Yearly Pensions, Retirement, Social Security: <span>{r.yearlyPensions}</span>
											</p>
											<p>
												Yearly Other Income: <span>{r.yearlyOthers}</span>
											</p>
										</div>
										<div className='total-income'>
											<p>
												Yearly Total Gross Income for family member: <span>{r.yearlyIncome}</span>
											</p>
										</div>
										{props.version == 10 && renderFileUploader(r)}
									</BorderedPanelSection>
								);
							})}
						</BorderedPanel>
					</div>
				</div>
			</Grid>
			<Grid item xs={12} lg={5}>				
				{props.version == 30 && props.financialInformation != null && renderMediaUploader(props.financialInformation[0])}
				{props.version == 20 && props.financialInformation != null && renderFileUploader(props.financialInformation[0])}
			</Grid>
		</Grid>
	);
};

const mapStateToProps = (s) => {
	return {
		application: s.applicationRdx 
	};
};

export default connect(mapStateToProps)(FinancialInformationView);
