import React from 'react';
import { Grid, Box } from '@material-ui/core';

import IncomeTable from '../../component/IncomeTable';

const FinancialTable = ({ previous }) => (
	<Grid item xs={12}>
		<div className='section__student-financial'>
			<div className='financial-information__component'>
				<Grid item xs={12}>
					<div className='financial-message-title'>
						<p>Applications must be completed by a legal guardian and submitted for each school year</p>
					</div>
				</Grid>
				<Grid container direction='row' justify='center' alignItems='center'>
					<Grid item xs={12} sm={6}>
						<div className='financial-message-list'>
							<h4>INCOME REQUIREMENT</h4>
							<ul className='agreements__list bullet-list' style={{ paddingTop: 20 }}>
								<li style={{ fontSize: 18 }}>
									The family’s income cannot exceed 185% of the income limit required to qualify for Reduced Price Meals under the National School Lunch and Child Nutrition Acts.
								</li>
								<li style={{ fontSize: 18 }}>
									Submit {previous} IRS Form 1040 pages 1&2 and if more than 4 dependents the Federal Supporting Statement Form 1040 Dependents. If your income is below the income threshold required
									for filing taxes, submit a proof of income.
								</li>
							</ul>
						</div>
					</Grid>
					<Grid item xs={12} sm={6}>
						<IncomeTable />
					</Grid>
				</Grid>
			</div>
		</div>
	</Grid>
);

export default FinancialTable;
