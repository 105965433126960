// StartApplication/LowIncomeDetails.jsx
import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Paper,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(3),
  },
  incomeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(0, 1),
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tableHeader: {
    backgroundColor: '#6b0805',
  },
  headerCell: {
    color: '#fff',
    fontWeight: 'bold',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  requirementsList: {
    listStyleType: 'none',
    padding: 0,
  },
  requirement: {
    position: 'relative',
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '0.5em',
      width: 8,
      height: 8,
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
    },
  },
  actionButton: {
    marginTop: theme.spacing(3),
  },
  // Para la vista de tabla en columnas
  columnContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  column: {
    flex: '1 1 calc(50% - 16px)',
    minWidth: 250,
  },
  tableTitle: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  footer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 25,
    paddingBottom: 10,
    borderBottom: '2px solid #000',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'wrap'
    }
  },
}));

const LowIncomeDetails = ({ incomes, previousYear, onStartApplication }) => {
  const classes = useStyles();
  const theme = useTheme();

  const renderIncomeTable = () => {
    return (
      <Box className={classes.columnContainer}>
        <Box className={classes.column}>
          <Typography variant="subtitle1" className={classes.tableTitle} style={{ marginTop: 0 }}>
            185% OF FEDERAL REDUCED PRICE MEALS GUIDELINE
          </Typography>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.headerCell}>Household</TableCell>
                  <TableCell className={classes.headerCell}>Income</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incomes.map(item => (
                  <TableRow key={item.householdSize} className={classes.tableRow}>
                    <TableCell>{item.householdSize}</TableCell>
                    <TableCell>{item.income}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  };


  return (
    <div className={classes.root}>
      <h3 className={classes.subTitle}>Income Requirement and Student Eligibility Criteria&nbsp;<small>(must meet 1)</small></h3>
      <Grid container spacing={3} direction={{ xs: 'column', md: 'row' }}>
        {/* Content - left on desktop, bottom on mobile */}
        <Grid
          item
          xs={12}
          md={8}
          lg={9}
          sx={{
            order: { xs: 2, md: 1 } // Order 2 on mobile (bottom), 1 on desktop (left)
          }}
        >
          <Box sx={{ bgcolor: 'secondary.light', p: 2, height: '100%' }}>
            <ul className={classes.requirementsList}>
              <li className={classes.requirement}>The family's income cannot exceed 185% of the income limit required to qualify for Reduced Price Meals under the National School Lunch and Child Nutrition Acts.</li>
              <li className={classes.requirement}>Submit {previousYear} IRS Form 1040 pages 1&2 and if more than 4 dependents the Federal Supporting Statement Form 1040 Dependents. If your income is below the income threshold required for filing taxes, submit a proof of income.</li>
              <li className={classes.requirement}>
                Attended an Arizona public school as a full-time student for at least 90 days of the prior fiscal year or one full semester and then transferred from the public school to a private school; Those students who transfer to a qualified school after the first full semester are eligible to receive a scholarship that same academic year.
              </li>
              <li className={classes.requirement}>
                Is enrolling in a private school kindergarten. The student must be 5 years old as of September 1st.
              </li>
              <li className={classes.requirement}>
                Is enrolling in a private preschool program for students with disabilities; Student must have an IEP, MET or ISP issued by an Arizona public school.
              </li>
              <li className={classes.requirement}>
                Is a dependent of a member of the armed forces of the United States who is stationed in Arizona pursuant to military orders.
              </li>
              <li className={classes.requirement}>
                Received a <u>low-income corporate scholarship</u> in a prior year and the child continued to attend a private school in subsequent years; OR Received an <u>original individual scholarship</u> or a <u>switcher individual scholarship</u> in a prior year and the child continued to attend a private school in subsequent years.
              </li>
              <li className={classes.requirement}>
                Student was <strong>homeschooled in a prior school year and then directly enrolled in a qualified private school.</strong> The student is also eligible if the transition occurs within the same academic year, as long as the transition is from homeschooling directly to the qualified private school.
              </li>
              <li className={classes.requirement}>
                Student moved to Arizona from <strong>out of state, or internationally, and enrolled directly</strong> in a qualified private school.
              </li>
              <li className={classes.requirement}>
                Student participated in the <strong>Empowerment Scholarship Account (ESA) program</strong> and did not renew the account or accept the scholarship, in order to accept an STO scholarship.
              </li>
            </ul>
          </Box>
        </Grid>

        {/* Sidebar - right on desktop, top on mobile */}
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          sx={{
            order: { xs: 1, md: 2 } // Order 1 on mobile (top), 2 on desktop (right)
          }}
        >
          <Box sx={{ bgcolor: 'primary.light', p: 2, height: '100%' }}>
            {renderIncomeTable()}
          </Box>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          size="large"
          className={classes.actionButton}
          onClick={onStartApplication}
        >
          Start new Low Income application
        </Button>
      </div>
      <Box className={classes.footer}>
        <Typography variant="body2">
          One student per application. If you need to apply for multiple students, you must complete one application for each student.
        </Typography>
      </Box>


    </div>
  );
};

export default LowIncomeDetails;