import React, { useState, useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import PropTypes from 'prop-types';
import BackdropModal from './BackdropModal';
import config from '../../config';
import { connect } from 'react-redux';
import { setAccount } from '../../redux/actions/account';

const IdleComponent = (props) => {
	const idleTimer = useRef(null);
	const [idle, setIdle] = useState({
		timeout: 1000 * config.session.expireTime * 1,
		showModal: false,
		userLoggedIn: false,
		isTimedOut: false,
	});
	const [counter, setCounter] = useState(0);

	const logout = () => {
		if (localStorage.getItem('tokenAuth')) {
			if (localStorage.getItem('code')) localStorage.removeItem('code');
			localStorage.removeItem('tokenAuth');
			props.setAccount({});
			props.history.push('/login');
		}
	};

	useEffect(() => {
		if (idle.showModal && counter > 0) {
			let timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
			return () => clearInterval(timer);
		} else {
			if (idle.showModal && counter === 0) logout();
		}
	}, [counter]);

	const onAction = (e) => {
		setIdle({ ...idle, isTimedOut: false });
	};

	const onActive = (e) => {
		setIdle({ ...idle, isTimedOut: false });
	};

	const onIdle = (e) => {
		const isTimedOut = idle.isTimedOut;
		if (isTimedOut) {
			props.history.push('/login');
		} else {
			setCounter(config.session.waitTime);
			setIdle({ ...idle, showModal: true, isTimedOut: true });
		}
	};

	const handleClose = () => {
		setIdle({ ...idle, showModal: false });
	};

	return (
		<>
			<IdleTimer
				ref={idleTimer}
				element={document}
				onActive={(e) => onActive(e)}
				onIdle={(e) => onIdle(e)}
				// onAction={(e) => onAction(e)}
				debounce={250}
				timeout={idle.timeout}
			/>
			<BackdropModal showModal={idle.showModal} handleClose={handleClose} history={props.history} counter={counter} logout={logout} />
		</>
	);
};

IdleComponent.propTypes = {};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

const mapDispatchToProps = {
	setAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(IdleComponent);
