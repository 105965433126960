import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Box } from '@material-ui/core';

import { getIncomes } from '../../redux/actions/incomes';

const IncomeTable = ({ account, incomes, dispatch }) => {
	useEffect(() => {
		if (incomes.length == 0) {
			dispatch(getIncomes(account.tokenAuth));
		}
	}, []);

	return (
		<>
			<h4 style={{ paddingTop: 20, textAlign: 'center' }}>185 % OF FEDERAL REDUCED PRICE MEALS GUIDELINE</h4>
			<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				<table style={{ paddingTop: 20 }}>
					<tbody>
						<tr style={{ backgroundColor: '#a0201c' }}>
							<td style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}># in Household</td>
							<td style={{ padding: 15, color: '#ffffff', fontWeight: 'bold' }}>
								Annual Family Gross <br /> Income Allowable
							</td>
						</tr>
						{incomes.map((item) => (
							<tr key={item.householdSize} style={{ textAlign: 'center' }}>
								<td style={{ padding: 8 }}>{item.householdSize}</td>
								<td>{item.income}</td>
							</tr>
						))}
					</tbody>
				</table>
			</Box>
		</>
	);
};

export default connect((s) => ({ incomes: s.incomes, account: s.account }))(IncomeTable);
