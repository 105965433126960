import React, { useState, useEffect, Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import Autocomplete from '@material-ui/lab/Autocomplete';

export default function TextFieldAutoComplete(props) {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [value, setValue] = useState(null);
	const [errorMsg, setError] = useState(props.error || null);
	const [loading, setLoading] = useState(false);
	const [firstLoad, setFirstLoad] = useState(false);

	useEffect(() => {
		if (props.defaultValue) setValue(props.defaultValue);
	}, [props.defaultValue]);

	useEffect(() => {
		if (props.error) setError(props.error);
	}, [props.error]);

	const handleOnChange = (event, value) => {
		const { name, onChange } = props;
		setValue(value);
		if (errorMsg) setError(null);
		onChange && onChange({ target: { name, value } });
	};

	const handleOpen = async () => {
		const { onLoad } = props;
		setOpen(true);
		if (options.length <= 0 && !firstLoad) {
			setLoading(true);

			const options = onLoad ? await onLoad() : [];

			setOptions(options);
			setFirstLoad(true);
			setLoading(false);
		}
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Grid item xs={12} md={12}>
			<Autocomplete
				open={open}
				onOpen={handleOpen}
				onClose={handleClose}
				onChange={handleOnChange}
				noOptionsText={props.noOptionsText || 'No items available.'}
				value={value}
				options={options}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						label={props.label}
						variant='outlined'
						fullWidth
						error={!!errorMsg}
						helperText={errorMsg}
						inputProps={{ ...params.inputProps, ...props.inputProps }}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? <CircularProgress color='inherit' size={15} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				)}
			/>
		</Grid>
	);
}
