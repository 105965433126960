import React, { useState, Fragment } from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { Grid, Button, Collapse, Hidden } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '../../component/Tooltip';
import Alert from '../../component/Alert';

import API from '../../config/api';

const dateFormat = 'MM/DD/YYYY';

const StatusTooltip = ({ text }) => {
	let li = text.reasons.map((x, i) => {
		return (
			<li key={i} style={{ paddingBottom: 15 }}>
				<span style={{ marginLeft: -10 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
			</li>
		);
	});

	return (
		<Tooltip
			className='status-tooltip'
			tooltipStyle={{ color: '#000', fontSize: 12, backgroundColor: '#fff', border: '1px solid #9bb43b' }}
			icon={<InfoOutlinedIcon color='secondary' />}
			title={<ul style={{ listStyleType: 'square', listStylePosition: 'inside', margin: 10 }}>{li}</ul>}
			transitionEffect='Zoom'
		/>
	);
};

const htmlLabel = (label) => {
	return { __html: label };
};

const ApplicationBox = ({ data, status, account, onDelete }) => {
	const [collapsed, setCollapsed] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);

	const toggleAlert = () => setOpenAlert(!openAlert);

	const handleDeleteApplication = () => {
		const { tokenAuth } = account,
			{ applicationId } = data;
		const body = { applicationId, delete: true };

		API.DeleteParentApplication(tokenAuth, body).then((res) => {
			onDelete(applicationId, status);
		});
	};

	const studentInformation = () => {
		let studentName = 'No student(s)',
			school = '---',
			city = '---',
			grade = '---';

		if (data.students.length > 0) {
			const student = data.students[0];

			studentName = student.studentName;
			school = student.school;
			city = student.city;
			grade = student.grade;
		}

		return { studentName, school, city, grade };
	};

	const handleCollapsed = () => setCollapsed(!collapsed);

	const { studentName, school, city, grade } = studentInformation();
	const createdDate = moment.utc(data.createdAt).tz("America/Phoenix").format(dateFormat);
	const modifiedDate = moment.utc(data.modifiedAt).tz("America/Phoenix").format(dateFormat);
	const submittedDate = data.submittedAt ? moment.utc(data.submittedAt).tz("America/Phoenix").format(dateFormat) : '';
	const applicationType = data.applicationType.toLowerCase();

	//const showReasons = (data.status === 4 || data.status === 6 || data.status === 7);
	// Requested Remove the incomplete reasons when the status show Under Review on 20250212
	const showReasons = (data.status === 6 || data.status === 7);
	return (
		<Fragment>
			<div id={`application_${data.applicationId}`} className={`application__box`}>
				<div className='box__left-column'>
					<div className='box__section'>
						<p className='name'>{studentName}</p>
					</div>
					<div className='box__section d-sm-none'>
						<p>
							Created at: <span>{createdDate}</span> -
							{status == 'submitted' ? (
								<Fragment>
									Submitted: <span>{submittedDate}</span>
								</Fragment>
							) : (
								<Fragment>
									Modified: <span>{modifiedDate}</span>
								</Fragment>
							)}
						</p>
					</div>
					<div className='box__section'>
						<p>
							Type: <span>{data.applicationTypeLabel}</span>
						</p>
						<p>
							Parent/Guardian: <span>{data.parentName}</span>
						</p>
						{showReasons ? (
							<Hidden only={['xs', 'sm']}>
								<p className='status'>
									Application Status: {data.statusLabel}
									{showReasons && <StatusTooltip text={data} />}
								</p>
							</Hidden>
						) : (
							<p className='status'>
								Application Status: {data.statusLabel}
								{showReasons && <StatusTooltip text={data} />}
							</p>
						)}
					</div>

					{showReasons && (
						<Hidden only={['md', 'lg', 'xl']}>
							<div className='box__students-guardians-column'>
								<div className='students-guardians--header-reasons' onClick={handleCollapsed}>
									<div className='students-members-count'>
										<Grid container spacing={1}>
											<Grid item xs={11}>
												<div className='box__section' style={{ marginBottom: 0 }}>
													<p className='status'>
														Application Status: {data.statusLabel} <span style={{ marginLeft: 5 }}>{<InfoOutlinedIcon fontSize='small' />}</span>
													</p>
												</div>
											</Grid>
										</Grid>
									</div>
									<ExpandMoreIcon color='primary' />
								</div>
								<Collapse in={collapsed}>
									<div className='students-guardians'>
										<Grid container>
											<Grid item xs={11}>
												<h5>Reasons:</h5>
												<ul style={{ listStyleType: 'square', listStylePosition: 'inside' }}>
													{data.reasons.map((x, i) => (
														<li key={i} style={{ paddingBottom: 15 }}>
															<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
														</li>
													))}
												</ul>
											</Grid>
										</Grid>
									</div>
								</Collapse>
							</div>
						</Hidden>
					)}
				</div>
				<div className='box__right-column'>
					<div className='box__section d-none d-sm-block'>
						<p>
							Created at: <span>{createdDate}</span> -
							{status == 'submitted' ? (
								<Fragment>
									Submitted: <span>{submittedDate}</span>
								</Fragment>
							) : (
								<Fragment>
									Modified: <span>{modifiedDate}</span>
								</Fragment>
							)}
						</p>
					</div>
					<div className='box__section'>
						<p>
							School: <span>{school}</span>
						</p>
						<p>
							City: <span>{city}</span>
						</p>
						<p>
							Grade: <span>{grade}</span>
						</p>
					</div>
				</div>

				<div className='box__actions-column'>
					<Grid container>
						<Grid item xs={6} sm={6} md={6} lg={12}>
							{status == 'submitted' && (
								<Link to={`application/${applicationType}/view/${data.applicationId}`}>
									<Button variant='contained' color='primary' startIcon={<VisibilityIcon />} disableRipple fullWidth>
										View
									</Button>
								</Link>
							)}
							{status == 'unsubmitted' && (
								<Link to={`application/${applicationType}/edit/${data.applicationId}`}>
									<Button variant='contained' color='primary' startIcon={<EditIcon />} disableRipple fullWidth>
										Edit
									</Button>
								</Link>
							)}
							{status == 'awaiting' && (
								<Link to={`application/${applicationType}/sign/${data.applicationId}`}>
									<Button variant='contained' color='primary' startIcon={<CheckIcon />} disableRipple fullWidth>
										Make Corrections
									</Button>
								</Link>
							)}
						</Grid>
						{status == 'unsubmitted' && (
							<Grid item xs={6} sm={6} md={6} lg={12}>
								<Button color='secondary' startIcon={<DeleteForeverIcon />} className='centered' onClick={() => toggleAlert()} disableRipple>
									Delete
								</Button>
							</Grid>
						)}
					</Grid>
				</div>

				<div className='box__students-guardians-column'>

				{data.processingStatus == 1 && (
					<div className='box__on-hold'>
							<div className='box__on-hold__header'>
								The processing of your application is On hold
							</div>
							<div className='box__on-hold__body'>
								<b>On hold reasons:</b> {data.processingNotes}
							</div>				
					</div>
				)}					

				{data.processingStatus == 2 && (
					<div className='box__awarded'>
							<div className='box__awarded__header'>
								The Application is Awarded
							</div>
					</div>					
				)}

				{data.processingStatus == 3 && (
					<div className='box__not__awarded'>
							<div className='box__not__awarded__header'>
								The Application is Not Awarded
							</div>
							<div className='box__not__awarded__body'>
								<b>Not awarded reasons:</b> {data.processingNotAwardedNotes}
							</div>
					</div>					
				)}					

					<div className='students-guardians--header' onClick={handleCollapsed}>
						<div className='students-members-count'>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<span>{`${data.students.length} Student${data.students.length != 1 ? 's' : ''}`}</span>
								</Grid>
								<Grid item xs={6}>
									<span>{`${data.financialMembers.length} Guardian${data.financialMembers.length != 1 ? 's' : ''}`}</span>
								</Grid>
							</Grid>
						</div>
						<ExpandMoreIcon color='primary' />
					</div>
					<Collapse in={collapsed}>
						<div className='students-guardians'>
							<Grid container>
								<Grid item xs={6}>
									<h5>Students</h5>
									{data.students.map((d, i) => (
										<p key={i}>{d.studentName}</p>
									))}
								</Grid>
								<Grid item xs={6}>
									<h5>Guardians</h5>
									{data.financialMembers.map((d, i) => (
										<p key={i}>{d.memberName}</p>
									))}
								</Grid>
							</Grid>
						</div>
					</Collapse>
				</div>
			</div>

			<Alert
				open={openAlert}
				title='Delete Application'
				text='Are you sure you want to delete this application?'
				confirmButtonText='Delete'
				onConfirm={handleDeleteApplication}
				onCancel={toggleAlert}
			/>
		</Fragment>
	);
};

export default ApplicationBox;
