import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PropTypes from 'prop-types';

const index = (props) => {
	return (
		<Grid container className='banner_box'>
			<Box className='banner_background' style={{ backgroundImage: `url(${props.showBackground ? props.backgroundBanner : null})` }}>
				<Container>
					<Box>
						<p className='title'>{props.title}</p>
						{props.period && <p className='period-title'>{props.period}</p>}
					</Box>
				</Container>
			</Box>
		</Grid>
	);
};

index.propTypes = {
	showBackground: PropTypes.bool,
	backgroundBanner: PropTypes.string,
	title: PropTypes.string,
};

export default index;
