import React, { Component, useState, useEffect, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

export default class ErrorCatch extends Component {
	state = {
		hasError: false,
		errorMessage: 'Generic message',
	};

	componentDidCatch(error, info) {
		console.error(error, info);
		this.setState({ hasError: true, errorMessage: error.message });
	}

	render() {
		const { children } = this.props;
		const { hasError, errorMessage } = this.state;

		if (!hasError) return children;
		else
			return (
				<Fragment>
					<Grid container style={{ backgroundColor: '#333940' }}>
						<Grid container direction='column' justify='center' alignItems='center'>
							<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' style={{ height: '100vh' }}>
								<span className='jam jam-close-circle-f' style={{ fontSize: 150, color: 'red' }}></span>
								<Typography variant='h3'>Ups, something went wrong!</Typography>
								<Typography variant='h4'>Please, Try Again</Typography>
								{/* {errorMessage && errorMessage.length > 0 ?
                                    <div className="pt-5 align-items-center">
                                        <h3 className="text-danger text-center">Message:</h3>
                                        <div className="p-3 bg-warning text-dark">{errorMessage}</div>
                                    </div> : null} */}
							</Box>
						</Grid>
					</Grid>
				</Fragment>
			);
	}
}
