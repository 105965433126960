import { combineReducers } from 'redux';
import account from './account';
import incomes from './incomes';
import applicationRdx from './application';

const rootReducer = combineReducers({
	account,
	incomes,
	applicationRdx,
});

export default rootReducer;
