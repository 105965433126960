import config from '../../config'; // load configurations file

export const BASE_URL = config.api.url;
export const FETCH_TIMEOUT = 60000;

export const LOGIN = 'account/login';
export const GETCURRENT = 'account/token';
export const COMPLETEACCOUNT = 'account/complete';
export const GETALLACCOUNT = 'account/all';
export const GETACCOUNT = 'account';
export const CREATEACCOUNT = 'account/create';
export const UPDATEACCOUNT = 'account/update';
export const DELETEACCOUNT = 'account/delete';
export const UPDATEPASSWORDACCOUNT = 'account/password/update';
export const RECOVERYPASSWORD = 'account/recovery-password';
export const RESETPASSWORD = 'account/reset-password';
export const VALIDATERECOVERYCODE = 'account/validate-recovery-code';
export const GETUSERBYEMAILPHONE = 'account/get-user-by-email-phone';
export const UPDATEQUESTIONS = 'account/update-questions';
export const CHECKEXPIRATIONDATE = 'account/complete';

// Security Question
export const GETQUESTION = 'question/all';
export const GETRANDOMQUESTIONSBYACCOUNTID = 'question/get-questions-by-accountid';
export const GETALLQUESTIONSBYACCOUNTID = 'question/get-all-questions-by-accountid';
export const VALIDATEQUESTION = 'question/validate-question';

// Application
export const GETCRITERIA = 'application/criteria';
export const CREATELIAPPLICATION = 'application/low-income/create';
export const GETPARENTAPPLICATIONS = 'application/lists/parent';
export const DELETEPARENTAPPLICATION = 'application/delete';
export const CREATEDDAPPLICATION = 'application/disabled/create';
export const CREATELISTUDENT = 'application/low-income/addStudent';
export const UPDATELISTUDENT = 'application/low-income/updateStudent';
export const DELETELISTUDENT = 'application/low-income/removeStudent';
export const UPDATEDDSTUDENT = 'application/disabled/updateStudent';
export const UPDATEDDPREREQUISITES = 'application/disabled/updatePrerequisites';
export const SETSHARINGPERMISSIONS = 'application/disabled/setSharingPermission';
export const SETAGREESTATEMENTS = 'application/disabled/setAgreeStatements';
export const SETSUBMITTEDAGREEMENTS = 'application/disabled/setSubmittedAgreements';
export const GETFINANCIALMEMBERS = 'application/financial-member/getAll';
export const CREATEAPPLICATIONFINANCIAL = 'application/financial-member/add';
export const UPDATEAPPLICATIONFINANCIAL = 'application/financial-member/update';
export const DELETEAPPLICATIONFINANCIAL = 'application/financial-member/remove';
export const REQUESTSIGNATURE = 'application/request-signature';
export const UPDATESIGNATURE = 'application/update-signature';
export const GETAPPLICATION = 'application/get';
export const GETSIGNATUREREQUESTDOWNLOAD = 'application/get-signature-request-download';
export const SETHOUSEHOLDMEMBERS = 'application/setHouseholdMembers';
export const UPDATEFINANCIALQUESTIONS = 'application/updateFinancialQuestions';
export const GETHISTORYAPPLICATIONS = 'application/lists/history';

// School
export const GETSCHOOLNAMES = 'school/list';

// File
export const GETUPLOADFILEDATA = 'file/getUploadFileData';
export const GETDOWNLOADFILEURL = 'file/getDownloadFileUrl';
export const DELETEFILE = 'file/deleteFile';

// Site
export const VALIDATESUBMISSIONS = 'site/validateSubmissionCode';
export const GETPERIODS = 'site/periods';
export const GETINCOMETABLE = 'site/income-table';

// Media
export const GETUPLOADMEDIADATA = 'media/getUploadMediaData';
export const GETDOWNLOADMEDIAURL = 'media/getDownloadMediaUrl';
export const DELETEMEDIA = 'media/deleteMedia';
export const ADDMEDIA = 'media/addMedia';



