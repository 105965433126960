import { LOAD_INCOME_TABLE } from '../constants';

const initialState = [];

export default function incomes(state = initialState, action) {
	switch (action.type) {
		case LOAD_INCOME_TABLE:
			return [...action.incomes];
		default:
			return state;
	}
}
