import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const CodeForm = ({ onClose, onSubmit, error }) => {
	const [code, setCode] = useState('');

	const handleChange = (event) => {
		const { value } = event.target;
		setCode(value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSubmit(code);
	};

	return (
		<form onSubmit={handleSubmit}>
			<h3 className='section-title'>Enter the code</h3>
			<TextField label='Code' name='code' color='primary' variant='outlined' onChange={handleChange} fullWidth required />
			{error && <Alert severity='error'>{error}</Alert>}
			<div className='admin-message-modal__buttons'>
				<Button variant='contained' color='primary' onClick={onClose} fullWidth disableRipple>
					Close
				</Button>
				<Button variant='contained' color='primary' type='submit' fullWidth disableRipple>
					Verify
				</Button>
			</div>
		</form>
	);
};

export default CodeForm;
