import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

const UserInformation = ({ renderButton, account }) => {
	const formatPhoneNumber = (phone) => {
		const phoneNumber = phone || '';
		const number = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);
		return phoneNumber ? `(${number[1]}) ${number[2]}-${number[3]}` : null;
	};

	return (
		<div className='user__information'>
			<div className='user-information'>
				<p>Registered as Parent/Guardian</p>
				<p className='p-name'>
					{account.firstName} {account.lastName}
				</p>
				<p>
					<span>{account.address}</span>
				</p>
				{(account.city || account.state || account.postalCode) && (
					<p>
						<span>
							{account.city}, {account.state}, {account.postalCode}
						</span>
					</p>
				)}
				{account.phone && (
					<p>
						<span>
							<PhoneIcon /> {formatPhoneNumber(account.phone)}
						</span>
					</p>
				)}
				{/* <p><span><EmailIcon /> {account.email}</span></p> */}
				<p className='ellipsis'>
					<EmailIcon /> {account.email}
				</p>
			</div>
			{renderButton || (
				<Link to='/user/profile' className='text-decoration-none'>
					<Button variant='contained' size='small' color='primary' disableRipple>
						<span>Profile</span>
					</Button>
				</Link>
			)}
		</div>
	);
};

UserInformation.propTypes = {
	user: PropTypes.object, // TODO: .isRequired
	renderButton: PropTypes.element,
};

UserInformation.defaultProps = {
	renderButton: null,
};

export default withRouter(connect((s) => ({ account: s.account }))(UserInformation));
