import React, { useState, useEffect, forwardRef, useImperativeHandle, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Grid, Button, FormControl, FormControlLabel, TextField, Radio, InputLabel, Select, MenuItem, InputAdornment } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import { BorderedPanelSection, BorderedPanelSectionHeader, BorderedPanelFooter } from '../BorderedPanel';
import Loader from '../../component/Loader';
import Snackbar from '../../component/Snackbar';

import API from '../../config/api';
import { REGEX } from '../../utils/constants';
import { errorField } from '../../utils/helpers';

const { currencyRegEx } = REGEX;

const initialFormState = {
	applicationId: null,
	accountId: null,
	firstName: '',
	lastName: '',
	type: '',
	noIncome: false,
	yearlyEarnings: '',
	yearlyWelfare: '',
	yearlyPensions: '',
	yearlyOthers: '',
	federal_income_tax_key: null,
	other_proof_of_income_key: null,
	other_file_key: null,
};

const CurrencyInput = (props) => {
	const { inputRef, value, ...other } = props;

	return <CurrencyFormat {...other} ref={(ref) => inputRef(ref ? ref.inputElement : null)} value={value} type='tel' thousandSeparator />;
};

const MemberForm = ({ account, members, editMode, application, selectedMember, memberAttachment, membersCount, onEdit, onClearAttachments, onUpdateMembers, onUpdateSelectedMember }, ref) => {
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(false);
	const [formState, setFormState] = useState(initialFormState);
	const [incomeDisabled, setIncomeDisabled] = useState(false);

	useImperativeHandle(ref, () => ({
		saveFinancialOnSubmit: () => saveFinancialOnSubmit(),
	}));

	// handle success alert
	useEffect(() => {
		if (formSuccess) setTimeout(() => setFormSuccess(false), 3000);
	}, [formSuccess]);

	// set applicationId / accountId
	useEffect(() => {
		const { applicationId } = application,
			{ accountId } = account;
		applicationId ? setFormState({ ...formState, applicationId }) : setFormState({ ...formState, accountId });
	}, [application]);

	// set attachment
	useEffect(() => {
		if (!memberAttachment) return;
		const { fileKey, responseKey } = memberAttachment;
		const data = { ...formState, [fileKey]: responseKey };
		setFormState(data);
		onUpdateSelectedMember(data);
	}, [memberAttachment]);

	// set formState on edit mode
	useEffect(() => {
		if (!editMode) return;
		if (selectedMember.noIncome) {
			setFormState({ ...selectedMember, yearlyEarnings: '', yearlyWelfare: '', yearlyPensions: '', yearlyOthers: '' });
		} else {
			const { yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers } = clearCurrency(selectedMember);
			setFormState({ ...selectedMember, yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers });
		}
		setIncomeDisabled(selectedMember.noIncome);
	}, [editMode]);

	const saveFinancialOnSubmit = async () => {
		let response = null;

		const { firstName, lastName, type, yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers, federal_income_tax_key, other_proof_of_income_key, other_file_key } = formState;

		if (
			firstName != '' ||
			lastName != '' ||
			type != '' ||
			yearlyEarnings != '' ||
			yearlyWelfare != '' ||
			yearlyPensions != '' ||
			yearlyOthers != '' ||
			federal_income_tax_key != null ||
			other_proof_of_income_key != null ||
			other_file_key != null
		) {
			setFormError(null);
			setLoading(true);

			const { yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers } = clearCurrency(formState);
			const data = { ...formState, yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers };

			if (!editMode) response = await API.CreateApplicationFinancial(account.tokenAuth, data);
			else response = await API.UpdateApplicationFinancial(account.tokenAuth, data);

			onSaveMember(response);
		}

		return response;
	};

	const clearCurrency = (object) => {
		const yearlyEarnings = object.yearlyEarnings.replace(currencyRegEx, '');
		const yearlyWelfare = object.yearlyWelfare.replace(currencyRegEx, '');
		const yearlyPensions = object.yearlyPensions.replace(currencyRegEx, '');
		const yearlyOthers = object.yearlyOthers.replace(currencyRegEx, '');

		return { yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers };
	};

	const totalMembersIncome = () => {
		let total = 0;
		let membersTotal = [];

		members.map((d) => {
			const amounts = clearCurrency(d);
			const total = Object.values(amounts)
				.map((x) => Number(x))
				.reduce((a, b) => a + b);
			membersTotal.push(total);
		});

		if (members.length > 0) total = membersTotal.reduce((a, b) => a + b);

		return <CurrencyFormat value={total} displayType='text' prefix='$' thousandSeparator fixedDecimalScale decimalScale={2} />;
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
		setFormError(null);
	};

	const handleRadioClick = (event) => {
		const noIncome = !formState.noIncome;

		setIncomeDisabled(noIncome);
		if (noIncome) {
			setFormState({ ...formState, noIncome, yearlyEarnings: '', yearlyWelfare: '', yearlyPensions: '', yearlyOthers: '' });
		} else {
			if (editMode) {
				const { yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers } = selectedMember;
				setFormState({ ...formState, yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers, noIncome });
			} else {
				setFormState({ ...formState, noIncome });
			}
		}

		setFormError(null);
	};

	const handleCancelEdit = () => {
		const { applicationId } = application;

		onEdit(null, false);
		setFormState({ ...initialFormState, applicationId });
		setIncomeDisabled(initialFormState.noIncome);
	};

	const onSaveMember = (res) => {
		const { applicationId } = application;

		setLoading(false);

		if (res.status !== 201) return setFormError({ field: res.field, message: res.message });

		setFormSuccess(true);
		setFormState({ ...initialFormState, applicationId });
		setIncomeDisabled(initialFormState.noIncome);

		onEdit(null, false);
		onClearAttachments();

		if (!editMode) onUpdateMembers(res.data);
		else onUpdateSelectedMember(res.data);
	};

	const addMember = (token, data) => {
		API.CreateApplicationFinancial(token, data).then((res) => onSaveMember(res));
	};

	const updateMember = (token, data) => {
		API.UpdateApplicationFinancial(token, data).then((res) => onSaveMember(res));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		setFormError(null);
		setLoading(true);

		const { yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers } = clearCurrency(formState);
		const data = { ...formState, yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers };

		if (!editMode) addMember(account.tokenAuth, data);
		else updateMember(account.tokenAuth, data);
	};

	const panelTitle = !editMode ? `Member #${membersCount + 1}` : `Member #${selectedMember.index} - ${selectedMember.firstName} ${selectedMember.lastName}`;

	const errors = errorField(formError);

	return (
		<form className='member-form' onSubmit={handleSubmit}>
			<BorderedPanelSection>
				<BorderedPanelSectionHeader title={panelTitle} />
				<div className='financial-panel__form'>
					<FormControl component='fieldset'>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} style={{ display: 'flex' }}>
								<FormControlLabel control={<Radio checked={formState.noIncome} color='primary' onClick={handleRadioClick} />} label='No Income' />
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl variant='outlined' error={errors.type} fullWidth>
									<InputLabel id='type-label'>Type of Member</InputLabel>
									<Select label='Type of Member' labelId='type-label' id='type' name='type' value={formState.type} onChange={handleChange}>
										<MenuItem value='C'>Child</MenuItem>
										<MenuItem value='G'>Guardian</MenuItem>
										<MenuItem value='O'>Other</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label='First Name'
									name='firstName'
									inputProps={{ maxLength: 120 }}
									value={formState.firstName}
									error={errors.firstName}
									variant='outlined'
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField label='Last Name' name='lastName' inputProps={{ maxLength: 120 }} value={formState.lastName} error={errors.lastName} variant='outlined' onChange={handleChange} fullWidth />
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label='Yearly Gross Earning from work'
									name='yearlyEarnings'
									variant='outlined'
									value={formState.yearlyEarnings}
									error={errors.yearlyEarnings}
									InputProps={{
										startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										inputComponent: CurrencyInput,
									}}
									disabled={incomeDisabled}
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label='Yearly Welfare, Child support, Alimony'
									name='yearlyWelfare'
									variant='outlined'
									value={formState.yearlyWelfare}
									error={errors.yearlyWelfare}
									InputProps={{
										startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										inputComponent: CurrencyInput,
									}}
									disabled={incomeDisabled}
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label='Yearly Pensions, Retirement, Social Security'
									name='yearlyPensions'
									variant='outlined'
									value={formState.yearlyPensions}
									error={errors.yearlyPensions}
									InputProps={{
										startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										inputComponent: CurrencyInput,
									}}
									disabled={incomeDisabled}
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label='Yearly Other Income'
									name='yearlyOthers'
									variant='outlined'
									value={formState.yearlyOthers}
									error={errors.yearlyOthers}
									InputProps={{
										startAdornment: <InputAdornment position='start'>$</InputAdornment>,
										inputComponent: CurrencyInput,
									}}
									disabled={incomeDisabled}
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							{formError && (
								<Grid item xs={12}>
									<Alert severity='error' onClose={() => setFormError(null)}>
										{formError.message}
									</Alert>
								</Grid>
							)}
							<Snackbar open={formSuccess} severity={'success'} text={'Member saved'} handleClose={() => setFormSuccess(false)} />
						</Grid>
					</FormControl>
				</div>
			</BorderedPanelSection>
			<BorderedPanelFooter>
				<div className='financial-panel__footer'>
					<div className='yearly-total-income'>
						Yearly Total Gross Income: <span>{totalMembersIncome()}</span>
					</div>
					<div className='add-member'>
						<span className='members-count'>{`${membersCount} Member${membersCount != 1 ? 's' : ''}`} in household</span>
						{!loading ? (
							!editMode ? (
								<Button type='submit' size='large' color='secondary' startIcon={<AddIcon />} className='new-member__button' fullWidth>
									{membersCount > 0 ? 'Add new member' : 'Save'}
								</Button>
							) : (
								<Fragment>
									<Button type='submit' size='large' color='secondary' startIcon={<SaveIcon />} disableRipple fullWidth>
										Save changes
									</Button>
									<Button size='large' color='secondary' variant='contained' startIcon={<CloseIcon />} onClick={handleCancelEdit} disableRipple fullWidth>
										Cancel
									</Button>
								</Fragment>
							)
						) : (
							<Loader loading={true} />
						)}
					</div>
				</div>
			</BorderedPanelFooter>
		</form>
	);
};

export default forwardRef(MemberForm);
