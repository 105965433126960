import React, { useRef, forwardRef, useImperativeHandle, Fragment } from 'react';

import FederalIncome from './FederalIncome';
import OtherProofIncome from './OtherProofIncome';
import OtherFile from './OtherFile';

import API from '../../config/api';

const FinancialAttachments = ({ applicationId, previousYear, selectedMember, token, onSetAttachment }, ref) => {
	const federalIncomeRef = useRef(null);
	const otherProofIncomeRef = useRef(null);
	const otherFileRef = useRef(null);

	useImperativeHandle(ref, () => ({
		clearAttachments: () => {
			federalIncomeRef.current.clearAttachment();
			otherProofIncomeRef.current.clearAttachment();
			otherFileRef.current.clearAttachment();
		},
	}));

	const handleViewFile = (fileKey) => {
		const { financialId } = selectedMember;
		const data = { applicationId, financialId, fileKey };

		const viewFile = window.open();

		API.GetDownloadFileUrl(token, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	const handleDeleteFile = async (fileKey) => {
		if (!selectedMember) return true;

		const { financialId } = selectedMember;
		const data = { applicationId, financialId, fileKey };

		await API.DeleteFile(token, data);
	};

	return (
		<Fragment>
			<FederalIncome
				ref={federalIncomeRef}
				applicationId={applicationId}				
				previousYear={previousYear}
				selectedMember={selectedMember}
				token={token}
				onSetAttachment={onSetAttachment}
				onViewFile={handleViewFile}
				onDeleteFile={handleDeleteFile}
			/>
			<OtherProofIncome
				ref={otherProofIncomeRef}
				applicationId={applicationId}
				selectedMember={selectedMember}
				token={token}
				onSetAttachment={onSetAttachment}
				onViewFile={handleViewFile}
				onDeleteFile={handleDeleteFile}
			/>
			<OtherFile
				ref={otherFileRef}
				applicationId={applicationId}
				selectedMember={selectedMember}
				token={token}
				onSetAttachment={onSetAttachment}
				onViewFile={handleViewFile}
				onDeleteFile={handleDeleteFile}
			/>
		</Fragment>
	);
};

export default forwardRef(FinancialAttachments);
