import React, { useState, Fragment } from 'react';
import { Button, IconButton, CircularProgress } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import FileUploader from '../../../component/FileUploader';

import API from '../../../config/api';

const FILEKEY = 'military_order_station';

const AttachmentBox = ({ formState, loading, onViewFile, onDeleteFile }) => (
	<div className='simple-attachment__box'>
		<div className='attachment__box--header'>
			<div className='left-content'>
				<h5>
					<CheckCircleIcon color='primary' />
					File uploaded
				</h5>
				{formState.studentId && (
					<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={onViewFile} disableRipple>
						View file
					</Button>
				)}
			</div>
			{!loading ? (
				<IconButton color='secondary' size='small' title='Remove file' onClick={onDeleteFile}>
					<DeleteForeverIcon />
				</IconButton>
			) : (
				<CircularProgress size={18} />
			)}
		</div>
	</div>
);

const Uploader = ({ applicationId, token, formState, onSetLoadingFile, onChange }) => {
	let opts = { token, applicationId, fileKey: FILEKEY };
	if (formState.studentId) opts.studentId = formState.studentId;

	return <FileUploader id={FILEKEY} {...opts} onChange={onChange} onUploadStart={() => onSetLoadingFile(true)} fullWidth />;
};

const MilitaryOrderStationFile = ({ applicationId, token, formState, setLoadingFile, setFormState }) => {
	const [loading, setLoading] = useState(false);
	const [uploaded, setUploaded] = useState(formState.military_order_station_key);

	const handleChange = (data) => {
		setUploaded(true);
		setLoadingFile(false);
		setFormState(data.fields.Key);
	};

	const handleViewFile = () => {
		const data = { applicationId, studentId: formState.studentId, fileKey: FILEKEY };

		const viewFile = window.open();

		API.GetDownloadFileUrl(token, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	const handleDeleteFile = () => {
		setLoading(true);

		API.DeleteFile(token, { applicationId, studentId: formState.studentId, fileKey: FILEKEY }).then((res) => {
			setUploaded(false);
			setLoading(false);
			setFormState(null);
		});
	};

	return (
		<Fragment>
			{!uploaded ? (
				<Uploader applicationId={applicationId} token={token} formState={formState} onSetLoadingFile={setLoadingFile} onChange={handleChange} />
			) : (
				<AttachmentBox formState={formState} loading={loading} onViewFile={handleViewFile} onDeleteFile={handleDeleteFile} />
			)}
		</Fragment>
	);
};

export default MilitaryOrderStationFile;
