import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Divider, IconButton, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
}));

const BackdropModal = (props) => {
	const classes = useStyles();

	return (
		<>
			<Backdrop className={classes.backdrop} open={props.showModal} onClick={props.handleClose}>
				<Grid container direction='column' justify='center' alignItems='center'>
					<div className='backdrop__modal'>
						<div className='backdrop__title'>
							<h2>Your Session is About to Expire!</h2>
						</div>
						<Divider />
						<div className='backdrop__body'>
							<div className='backdrop__body-content'>
								<p>Your session is about to expire.</p>
								<p>
									Redirecting in <b>{props.counter}s</b> seconds
								</p>
							</div>
						</div>
						<Divider />
						<div className='backdrop__buttons'>
							<Button variant='contained' onClick={() => props.logout()}>
								Logout
							</Button>
							<Button variant='contained' color='primary' onClick={props.handleClose}>
								Stay Connected
							</Button>
						</div>
					</div>
				</Grid>
			</Backdrop>
		</>
	);
};

BackdropModal.propTypes = {};

export default BackdropModal;
