class Config {
	get getProperties() {
		const env = process.env.REACT_APP_STAGE;
		switch (env) {
			case 'staging':
				return {
					app: {
						name: 'Arizona Leader Parent App',
						key: '4dd7b8bb-7519-4689-902c-d6237f182dfd',
					},
					api: {
						url: 'http://52.2.171.7:4000/api/',
					},
					hellosign: {
						skipDomainVerification: false,
					},
					session: {
						expireTime: 1800,
						waitTime: 60,
					},
				};
				break;
			case 'production':
				return {
					app: {
						name: 'Arizona Leader Parent App',
						key: '4dd7b8bb-7519-4689-902c-d6237f182dfd',
					},
					api: {
						url: 'https://api.arizonaleader.org/api/',
					},
					hellosign: {
						skipDomainVerification: false,
					},
					session: {
						expireTime: 3600,
						waitTime: 60,
					},
				};
				break;
			default:
				return {
					app: {
						name: 'Arizona Leader Parent App',
						key: '4dd7b8bb-7519-4689-902c-d6237f182dfd',
					},
					api: {
						url: 'http://localhost:4000/api/',
					},
					hellosign: {
						skipDomainVerification: true,
					},
					session: {
						expireTime: 300,
						waitTime: 60,
					},
				};
				break;
		}
	}
}
const config = new Config();
export default config.getProperties;
