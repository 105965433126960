/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../../utils/constants';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import PersonIcon from '@material-ui/icons/Person';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';

import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Container } from '@material-ui/core';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { setAccount } from '../../../redux/actions/account';

const index = (props) => {
	
	
	const [isExist, setIsExist] = useState(false);
	const [getToken, setToken] = useState(null);
	
    const [navOpen, setNavOpen] = useState(false);

	const anchorRef = useRef(null);	
    const studentsFamiliesRef = useRef(null);
    const corporateChampionsRef = useRef(null);
    const schoolsRef = useRef(null);
    const aboutUsRef = useRef(null);
    
    const [open, setOpen] = useState(false);
    const [openStudentsFamilies, setOpenStudentsFamilies] = useState(false);
    const [openCorporateChampions, setOpenCorporateChampions] = useState(false);
    const [openSchools, setOpenSchools] = useState(false);
    const [openAboutUs, setOpenAboutUs] = useState(false);

    // handle functions for menu
    const handleToggleStudentsFamilies = () => {
        setOpenStudentsFamilies(!openStudentsFamilies);
        setOpenCorporateChampions(false);
        setOpenSchools(false);
        setOpenAboutUs(false);
    };
    
    const handleToggleCorporateChampions = () => {
        setOpenCorporateChampions(!openCorporateChampions);
        setOpenStudentsFamilies(false);
        setOpenSchools(false);
        setOpenAboutUs(false);
    };  
    
    const handleToggleSchools = () => {
        setOpenSchools(!openSchools);
        setOpenStudentsFamilies(false);
        setOpenCorporateChampions(false);
        setOpenAboutUs(false);
    };
    
    const handleToggleAboutUs = () => {
        setOpenAboutUs(!openAboutUs);
        setOpenStudentsFamilies(false);
        setOpenCorporateChampions(false);
        setOpenSchools(false);
    };

    /* Gener alos eventos handleClose para los nuevas constantes de Ref */
    const handleCloseStudentsFamilies = (event) => {
        if (studentsFamiliesRef.current && studentsFamiliesRef.current.contains(event.target)) {
            return;
        }
        setOpenStudentsFamilies(false);
    };

    const handleCloseCorporateChampions = (event) => {
        if (corporateChampionsRef.current && corporateChampionsRef.current.contains(event.target)) {
            return;
        }   
        setOpenCorporateChampions(false);
    };

    const handleCloseSchools = (event) => {
        if (schoolsRef.current && schoolsRef.current.contains(event.target)) {
            return;
        }
        setOpenSchools(false);
    };

    const handleCloseAboutUs = (event) => {
        if (aboutUsRef.current && aboutUsRef.current.contains(event.target)) {
            return;
        }
        setOpenAboutUs(false);
    };

    /* genera cada handleListKeyDown para cada variable nueva de estado */
    function handleListKeyDownStudentsFamilies(event) {
        if (event.key === 'Tab') {
            event.preventDefault(); 
            setOpenStudentsFamilies(false);
        }
    }
    
    function handleListKeyDownAboutUs(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenAboutUs(false);
        }
    };
        





	const logout = () => {
		if (localStorage.getItem('tokenAuth')) {
			if (localStorage.getItem('code')) localStorage.removeItem('code');
			localStorage.removeItem('tokenAuth');
			props.dispatch(setAccount({}));
			props.history.push('/login');
		}
	};

	const handleToggle = () => {
		setNavOpen(!navOpen);
	};



	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(open);

    const prevOpenStudentsFamilies = useRef(openStudentsFamilies);
    const prevOpenCorporateChampions = useRef(openCorporateChampions);
    const prevOpenSchools = useRef(openSchools);
    const prevOpenAboutUs = useRef(openAboutUs);

    useEffect(() => {   
        if (localStorage.getItem('tokenAuth')) setToken(localStorage.getItem('tokenAuth'));
        if (props.account) setIsExist(isEmpty(props.account));
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        if (prevOpenStudentsFamilies.current === true && openStudentsFamilies === false) {
            studentsFamiliesRef.current.focus();
        }
        if (prevOpenCorporateChampions.current === true && openCorporateChampions === false) {
            corporateChampionsRef.current.focus();
        }
        if (prevOpenSchools.current === true && openSchools === false) {
            schoolsRef.current.focus();
        }
        if (prevOpenAboutUs.current === true && openAboutUs === false) {
            aboutUsRef.current.focus();
        }
        prevOpen.current = open;    
        prevOpenStudentsFamilies.current = openStudentsFamilies;
        prevOpenCorporateChampions.current = openCorporateChampions;
        prevOpenSchools.current = openSchools;
        prevOpenAboutUs.current = openAboutUs;
    }, [open, studentsFamiliesRef, corporateChampionsRef, schoolsRef, aboutUsRef]);

	return (
		<Fragment>
			<Container>
				<Grid container className='menu'>

                    {/* logo */}
					<Grid item xs={12} sm={12} md={3}>
						<Box className='logo' flexDirection='row' justifyContent='center'>
							<a href='https://arizonaleader.org/'>
								<img alt='' src='/assets/img/logo.svg' style={{maxWidth:'200px'}} />
							</a>
							<Hidden only={['md', 'lg', 'xl']}>
								<Button className='toggle-menu__button' ref={anchorRef} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup='true' onClick={handleToggle}>
									<MenuIcon />
								</Button>
							</Hidden>
						</Box>
					</Grid>

					{/* primary menu */}
					<Grid item xs={12} sm={9} className={!navOpen ? 'nav-hidden' : ''}>
						<Box className='main_menu'>
                            
                            {/* Students & Families  */}
                            <Box className='text_main_menu'>
                                <Button ref={studentsFamiliesRef} aria-controls={openStudentsFamilies ? 'menu-list-students-families' : undefined} aria-haspopup='true' onMouseEnter={handleToggleStudentsFamilies}>
                                    <a href='https://arizonaleader.org/students-families'>Students &amp; Families</a>
                                </Button>
                            </Box>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Box className='text_main_menu'>
                                    <Button style={{ marginLeft: 20 }}>
                                        <a href='https://arizonaleader.org/applications-closed/'>Apply for a Scholarship</a>
                                    </Button>
                                </Box>
                            </Hidden>

                            {/* Corporate Champions */}
                            <Box className='text_main_menu'>
                                <Button ref={corporateChampionsRef} aria-controls={openCorporateChampions ? 'menu-list-corporate-champions' : undefined} aria-haspopup='true' onMouseEnter={handleToggleCorporateChampions}>
                                    <a href='https://arizonaleader.org/corporate-champions'>Corporate Champions</a>
                                </Button>
                            </Box>
                                
                            {/* Schools  */}
                            <Box className='text_main_menu'>
                                <Button ref={schoolsRef} aria-controls={openSchools ? 'menu-list-schools' : undefined} aria-haspopup='true' onMouseEnter={handleToggleSchools}>
                                    <a href='https://arizonaleader.org/schools'>Schools</a>
                                </Button>
                            </Box>
                                
                            {/* About Us  */}    
                            <Box className='text_main_menu'>
                                <Button ref={aboutUsRef} aria-controls={openAboutUs ? 'menu-list-about-us' : undefined} aria-haspopup='true' onMouseEnter={handleToggleAboutUs}>
                                    <a href='https://arizonaleader.org/about-us'>About Us</a>
                                </Button>                            
                            </Box>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Box className='text_main_menu'>
                                    <Button style={{ marginLeft: 20 }}>
                                        <a href='https://arizonaleader.org/about-us/'>Mission</a>
                                    </Button>
                                </Box>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Box className='text_main_menu'>
                                    <Button style={{ marginLeft: 20 }}>
                                        <a href='https://arizonaleader.org/leadership/'>Leadership</a>
                                    </Button>
                                </Box>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Box className='text_main_menu'>    
                                    <Button style={{ marginLeft: 20 }}>
                                        <a href='https://arizonaleader.org/student-spotlights/'>Student Spotlights</a>
                                    </Button>
                                </Box>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Box className='text_main_menu'>
                                    <Button style={{ marginLeft: 20 }}>
                                        <a href='https://arizonaleader.org/community/'>Community</a>
                                    </Button>
                                </Box>
                            </Hidden>

                            <Box className='text_main_menu button_login_menu'>
                            {!isEmpty(getToken) && (
                                <Button onClick={logout} style={{ display: 'flex', alignItems: 'center' }}>
                                <AccountCircleOutlinedIcon style={{ marginRight: '8px' }} />Log Out</Button>
                            )}
                            {isEmpty(getToken) &&(
                                <Button style={{ display: 'flex', alignItems: 'center' }}>
                                <Link to="/login" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                                  <AccountCircleOutlinedIcon style={{ marginRight: '8px' }} />
                                  <span>Log In</span>
                                </Link>
                              </Button>
                            )}
                            </Box>
    					</Box>
					</Grid>

                    {/* Sub Menus */}                           
                    <Popper open={openStudentsFamilies} anchorEl={studentsFamiliesRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper onMouseLeave={() => openStudentsFamilies && setOpenStudentsFamilies(false)}>
                                    <ClickAwayListener onClickAway={handleCloseStudentsFamilies}>
                                        <MenuList autoFocusItem={openStudentsFamilies} id='menu-list-students-families' onKeyDown={handleListKeyDownStudentsFamilies} className='sub-menu-list'>
                                            <MenuItem>
                                                <a href='https://arizonaleader.org/applications-closed/' className=''>Apply for a Scholarship</a>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
					
                    <Popper open={openAboutUs} anchorEl={aboutUsRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper onMouseLeave={() => openAboutUs && setOpenAboutUs(false)}>
                                    <ClickAwayListener onClickAway={handleCloseAboutUs}>
                                        <MenuList autoFocusItem={openAboutUs} id='menu-list-about-us' onKeyDown={handleListKeyDownAboutUs} className='sub-menu-list'>
                                            <MenuItem>
                                                <a href='https://arizonaleader.org/about-us/'>Mission</a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a href='https://arizonaleader.org/leadership/'>Leadership</a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a href='https://arizonaleader.org/student-spotlights/'>Student Spotlights</a>
                                            </MenuItem>
                                            <MenuItem>
                                                <a href='https://arizonaleader.org/community/'>Community</a>
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>

                            </Grow>
                        )}
                    </Popper>

				</Grid>
				{/* </Grid> */}
			</Container>
		</Fragment>
	);
};

export default connect((s) => ({ account: s.account }))(index);
