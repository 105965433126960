import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import { BorderedPanel } from '../../BorderedPanel';
import MembersList from './MembersList';
import MemberForm from './MemberForm';
import MemberFiles from './MemberFiles';
import api from '../../../config/api';

const FinancialInformation = ({ application, account }, ref) => {
	const [members, setMembers] = useState([...application.financialMembers]);
	const [numMembers, setNumMembers] = useState(application.householdMembers || undefined);

	const { tokenAuth } = account;

	const [selectedMember, setSelectedMember] = useState(null);
	const [isEdit, setIsEdit] = useState(false);

	const handleSetNumMembers = (e) => {
		const { value } = e.target;
		setNumMembers(value);
		api.SetHouseholdMembers(tokenAuth, { applicationId: application.applicationId, quantityHouseholdMembers: value });
	};
	const memberFormRef = useRef(null);

	useImperativeHandle(ref, () => ({
		saveFinancialOnSubmit: () => memberFormRef.current?.saveFinancialOnSubmit(),
		openFirstFinancial: () => handleEdit({ ...members[0], index: 1 }, true),
	}));

	const handleUpdateMembers = (member) => setMembers([...members, member]);

	const handleUpdateSelectedMember = (member) => {
		let _members = [...members];
		const index = _members.findIndex((d) => d.financialId === member.financialId);
		_members[index] = member;
		setMembers(_members);
	};

	const handleEdit = (member, edit) => {
		setIsEdit(edit);
		setSelectedMember(member);
	};

	const handleDelete = (financialId) => setMembers(members.filter((d) => d.financialId != financialId));

	const handleSetAttachment = (name, key) => {
		const updatedMember = { ...members[0], [name]: key };
		setMembers([updatedMember, ...members.filter((d) => d.financialId !== members[0].financialId)]);
		if (selectedMember && selectedMember.index === 1) setSelectedMember(updatedMember);
	};

	return (
		<div className='financial-information-v2__component'>
			<BorderedPanel>
				<div className='member-count'>
					<h4>How many TOTAL household members live in your home, including yourself? </h4>
					<div className='member-count__content'>
						<h5>This includes all members that reside at your residence: children, parents, grandparents, guardians, roommates, etc...</h5>

						<FormControl variant='outlined' fullWidth>
							<InputLabel id='household-label'># of household members</InputLabel>
							<Select labelId='household-label' variant='outlined' fullWidth label='# of household members' onChange={handleSetNumMembers} value={numMembers || ''}>
								{Array(14)
									.fill()
									.map((_, i) => (
										<MenuItem key={i} value={i + 2}>
											{i + 2}
										</MenuItem>
									))}
							</Select>
						</FormControl>

						{numMembers < members.length && (
							<div className='member-count__warning'>
								<WarningIcon />
								<span>
									The number of household members has been reduced from a previously submitted application. Please confirm the selection of current household members and make any necessary edits to
									member names or income changes.
								</span>
							</div>
						)}
					</div>
				</div>
				{numMembers && (
					<div className='member-forms'>
						<MembersList account={account} application={application} members={members} isEdit={isEdit} onEdit={handleEdit} onDelete={handleDelete} />
						<MemberForm
							ref={memberFormRef}
							account={account}
							members={members}
							numMembers={numMembers}
							editMode={isEdit}
							application={application}
							selectedMember={selectedMember}
							membersCount={members.length}
							onEdit={handleEdit}
							onUpdateMembers={handleUpdateMembers}
							onUpdateSelectedMember={handleUpdateSelectedMember}
						/>
						{members.length >= numMembers && <MemberFiles account={account} application={application} onSetAttachment={handleSetAttachment} members={members} />}
					</div>
				)}
			</BorderedPanel>
		</div>
	);
};

FinancialInformation.defaultProps = {
	onSetSelectedParentMember: () => {},
};

export default forwardRef(FinancialInformation);
