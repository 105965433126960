// StartApplication/index.jsx
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Typography, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../component/partials/Layout';
import ApplicationTypeSelector from './ApplicationTypeSelector';
import AdminMessageAlert from '../../component/AdminMessageAlert';
import LowIncomeDetails from './LowIncomeDetails';
import DisabledDetails from './DisabledDetails';
import InitialMessage from './InitialMessage';
import { getIncomes } from '../../redux/actions/incomes';
import { Link } from 'react-router-dom';

import UserInformation from '../../component/partials/UserInformation';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    title: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.primary,
    },
    subtitle: {
        marginBottom: theme.spacing(3),
        color: theme.palette.text.secondary,
    },
    detailsContainer: {
        marginTop: theme.spacing(3),
    },
    footer: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    sectionTitle: {
        fontSize: '20px',
        fontFamily: 'HelveticaRoman',
        color: '#a0201c',
        marginBottom: '20px',
        paddingBottom: '8px',
        borderBottom: '1.5px solid #a0201c',
        '& small': {
            display: 'block',
            [theme.breakpoints.down('xs')]: {
                display: 'block',
                marginTop: theme.spacing(1),
            }
        }
    },
}));

const StartApplication = ({ account, history, incomes, dispatch }) => {
    const classes = useStyles();
    const [selectedType, setSelectedType] = useState(null);
    const [openCodeModal, setOpenCodeModal] = useState(false);
    const [newAppType, setNewAppType] = useState('');
    const { period, previousYear } = account.periodSettings || { period: '', previousYear: '' };

    useEffect(() => {
        // Load income data if Low Income is selected
        if (selectedType === 'lowIncome') {
            dispatch(getIncomes(account.tokenAuth));
        }
    }, [selectedType, account.tokenAuth]);

    const handleSelectType = (type) => {
        setSelectedType(type);
    };

    const handleStartApplication = (type) => {
        if (account.siteSettings.isClosedSubmission) {
            setNewAppType(type);
            setOpenCodeModal(true);
        } else {
            history.push({ pathname: `/application/${type}/create` });
        }
    };

    const onValidated = (isValidated) => {
        if (isValidated) {
            history.push({
                pathname: `/application/${newAppType}/create`,
                state: { isValidated }
            });
        }
        setOpenCodeModal(false);
    };

    return (
        <Layout history={history} period={period}>
            <div className='application-page'>
                <Grid container spacing={3}>
                    <Grid xs={12} item>
                        <div className='applications__column'>

                            <h3 className={classes.sectionTitle}>
                                Select which program application you would like to apply for (One application per student)
                                <small>if the student is elegible for both programs, you can apply for both scholarships.</small>
                            </h3>
                            <Link to='/' className='text-decoration-none'>   
                                <Button variant='contained' size='small' color='primary' disableRipple>
                                    <span>Back to dashboard</span>
                                </Button>
                            </Link>
                            <p style={{ marginTop: '15px' }}></p>
                            {/* <Typography variant="subtitle1" className={classes.subtitle} style={{ marginTop: '15px' }}>
                                Choose the scholarship type that best fits your circumstances
                            </Typography> */}

                            <ApplicationTypeSelector                                
                                selectedType={selectedType}
                                onSelectType={handleSelectType}
                            />
                            {!selectedType && <InitialMessage />}
                            {selectedType === 'lowIncome' && (
                                <LowIncomeDetails
                                    incomes={incomes}
                                    previousYear={previousYear}
                                    onStartApplication={() => handleStartApplication('li')}
                                />
                            )}

                            {selectedType === 'disabled' && (
                                <DisabledDetails
                                    onStartApplication={() => handleStartApplication('dd')}
                                />
                            )}


                        </div>
                    </Grid>
                </Grid>
            </div>
            <AdminMessageAlert type={newAppType} open={openCodeModal} onClose={() => setOpenCodeModal(false)} onValidated={onValidated} />
        </Layout>
    );
};

export default connect((state) => ({ incomes: state.incomes, account: state.account }))(StartApplication);