import React from 'react';
// import Layout from '../component/partials/Layout'
import Api from './api';
import { setAccount } from '../redux/actions/account';
import ErrorCatch from '../component/partials/ErrorCatch';
import { isEmpty } from 'lodash';

const auth = {
	storeUser: (history, store, redirect = '/') => {
		const { tokenAuth } = localStorage;
		const sendToLogin = (err) => {
			if (!err) localStorage.removeItem('tokenAuth');
			// history.push('/login');
		};

		Api.GetAccountToken(tokenAuth)
			.then((res) => {
				if (res.status === 201) {
					store.dispatch(setAccount({ ...res.data, tokenAuth }));
					history.push(redirect);
				} else sendToLogin();
			})
			.catch(sendToLogin);
	},

	authorize: (Composed, props, store, level) => {
		const { pathname } = props.history.location,
			{ account } = store.getState();

		if (!localStorage.tokenAuth) {
			const redirect = pathname == '/' ? '/login' : `/login?redirect_to=${pathname}`;
			props.history.push(redirect);
		}
		// if (!localStorage.tokenAuth) props.history.push('/login');

		if (!isEmpty(account)) {
			return <Composed {...props} />;
		} else auth.storeUser(props.history, store, pathname);

		return null;
	},

	validate: (Composed, props, store) => {
		const { account } = store.getState();
		if (!localStorage.tokenAuth)
			return (
				<ErrorCatch>
					<Composed {...props} />
				</ErrorCatch>
			);
		if (isEmpty(account)) auth.storeUser(props.history, store);
		return null;
	},
};

export default auth;
