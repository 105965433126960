import React, { forwardRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { Grid, CircularProgress } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';

import MaterialTable from 'material-table';
import PdfIcon from '@material-ui/icons/PictureAsPdf';

import API from '../../config/api';
import HelloSignClass from '../../utils/hellosign';

const mainColumns = [
	{ title: 'Applicant', field: 'applicant', cellStyle: { width: '30%' }, headerStyle: { width: '30%' } },
	{ title: 'School', field: 'school', cellStyle: { width: '25%' }, headerStyle: { width: '25%' } },
	{ title: 'Status', field: 'statusLabel', cellStyle: { width: '15%' }, headerStyle: { width: '15%' } },
	{ title: 'Type', field: 'applicationType', cellStyle: { width: '10%' }, headerStyle: { width: '10%' } },
	{
		title: 'Date Submitted',
		field: 'submittedAt',
		cellStyle: { width: '10%' },
		headerStyle: { width: '10%' },
		render: (row) => {
			let columnData = row['submittedAt'] ? moment.utc(row['submittedAt']).tz("America/Phoenix").format('MM/DD/YYYY') : '---';
			return columnData;
		},
	},
];

const periodColumn = { title: 'Period', field: 'period' };

const tableOptions = { search: false, showTitle: false, draggable: false, toolbar: false, pageSize: 5, paging: false, actionsColumnIndex: -1 };

const tableLocalization = { header: { actions: '' } };

const SelectPeriods = ({ data, current, period, onChange }) => {
	data = data.map((item) => {
		return { ...item, value: item.period };
	});
	data = [...data, { period: 'All Periods', year: null, value: 'all' }];

	const [selectedPeriod, setSelectedPeriod] = useState(period || current);

	const handleChange = (e) => {
		const { value } = e.target;
		setSelectedPeriod(value);
		if (onChange) onChange(value);
	};
	return (
		<FormControl className='MuiFormControl__filter-select' disabled={data.length > 0 ? false : true} variant='outlined' size='small' fullWidth>
			<InputLabel id='select-filters-label'>{data.length > 0 ? 'Select Period' : 'There are no periods'}</InputLabel>
			<Select labelId='select-filters-label' id='select-filters' label={data.length > 0 ? 'Select Period' : 'There are no periods'} onChange={(e) => handleChange(e)} value={selectedPeriod}>
				{data &&
					data.length > 0 &&
					data.map((x, i) => {
						return (
							<MenuItem key={i} value={x.value} name={x.period}>
								<Typography style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{x.period === current ? x.period + ' (Current)' : x.period}</Typography>
							</MenuItem>
						);
					})}
			</Select>
		</FormControl>
	);
};

const ApplicationHistory = ({ account }) => {
	const [loading, setLoading] = useState(true);
	const [periodList, setPeriodList] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [selectedPeriod, setSelectedPeriod] = useState(null);
	const [tableColumns, setTableColumns] = useState(mainColumns);

	const { period: currentPeriod } = account.periodSettings;

	useEffect(() => {
		getPeriods();
	}, []);

	useEffect(() => {
		if (selectedPeriod) {
			getApplications(selectedPeriod);
		} else {
			getApplications(currentPeriod);
		}
	}, [selectedPeriod]);

	const tableActions = [{ icon: () => <PdfIcon color='primary' fontSize='small' />, tooltip: 'View Application Pdf', onClick: (event, rowData) => handleViewPdf(rowData) }];

	const getPeriods = () => {
		API.GetPeriods(account.tokenAuth).then((res) => {
			if (res.status !== 201) return setLoading(false);

			setPeriodList(res.data);
			setLoading(false);
		});
	};

	const getApplications = (period) => {
		API.GetHistoryApplications(account.tokenAuth, { period }).then((res) => {
			if (res.status !== 201) return setLoading(false);

			initTableData(res.data);
			setLoading(false);
		});
	};

	const initTableData = (data) => {
		let tableData = [];
		data.map((d) =>
			tableData.push({
				...d,
				applicant: d.students.length > 0 ? d.students[0].studentName : 'No student',
				school: d.students.length > 0 ? d.students[0].school : '---',
			}),
		);

		setTableData(tableData);
		//setApplications(data);
	};

	const handleViewPdf = (rowData) => {
		const { tokenAuth } = account;
		const { applicationId } = rowData;
		API.GetApplication(tokenAuth, { id: applicationId }).then((res) => {
			if (res.status !== 201) return setLoading(false);

			const { signatureRequestId } = res.data;
			if (signatureRequestId) {
				const HelloSign = new HelloSignClass();

				HelloSign.signatureRequestId = signatureRequestId;
				HelloSign.token = tokenAuth;

				HelloSign.DownloadSignedDocument();
			}

			setLoading(false);
		});
	};

	const changePeriod = (e) => {
		if (e === 'all') {
			setTableColumns([...mainColumns, periodColumn]);
		} else {
			setTableColumns([...mainColumns]);
		}
		setSelectedPeriod(e);
	};

	return (
		<div className='profile-page__history--tab'>
			<Grid container spacing={3} direction='row' justify='space-between' alignItems='center' className='site-admin-container'>
				<Grid xs={12} md={9} item>
					<div className='applications__column'>
						<div className='unsubmitted-applications application__section'>
							<h3 className='section-title'>Profile: History </h3>
						</div>
					</div>
				</Grid>
				<Grid xs={12} md={3} item>
					<SelectPeriods data={periodList} current={currentPeriod} onChange={(e) => changePeriod(e)} />
				</Grid>
			</Grid>
			<Grid container>
				<div className='history-table'>
					{!loading ? (
						<MaterialTable
							columns={tableColumns}
							data={tableData}
							actions={tableActions}
							options={tableOptions}
							localization={tableLocalization}
							icons={{
								SortArrow: forwardRef((props, ref) => <span {...props} ref={ref} />),
							}}
						/>
					) : (
						<CircularProgress size={40} color='primary' />
					)}
				</div>
			</Grid>
		</div>
	);
};

export default ApplicationHistory;
