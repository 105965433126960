import React from 'react';
import PropTypes from 'prop-types';
import { Modal as MuiModal, Backdrop, Fade } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ open, onClose, showHeader, autoHeight, children }) => (
	<MuiModal open={open} onClose={onClose} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
		<Fade in={open}>
			<div className={`app-modal__container ${autoHeight ? 'auto-height' : ''}`}>
				{showHeader && (
					<div className='modal__header'>
						<button type='button' className='close-modal__button' onClick={onClose}>
							Close <CloseIcon />
						</button>
					</div>
				)}
				<div className='modal__body'>{children}</div>
			</div>
		</Fade>
	</MuiModal>
);

Modal.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	autoHeight: PropTypes.bool,
};

Modal.defaultProps = {
	showHeader: true,
	autoHeight: false,
};

export default Modal;
