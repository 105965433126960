import React, { useState, useEffect, useRef, Fragment } from 'react';
import moment from 'moment';
import {
	Grid,
	Button,
	FormControl,
	TextField,
	Select,
	Checkbox,
	MenuItem,
	InputLabel,
	RadioGroup,
	Radio,
	FormGroup,
	FormControlLabel,
	Collapse,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
// import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MomentUtils from '@date-io/moment';

import { BorderedPanelFooter, BorderedPanelSection, BorderedPanelSectionHeader } from '../../component/BorderedPanel';
import Loader from '../../component/Loader';
import Snackbar from '../../component/Snackbar';
import TextFieldAutoComplete from '../AutoComplete';

import HasDisabilityFile from './Attachments/HasDisabilityFile';
import AttendanceVerificationFile from './Attachments/AttendanceVerificationFile';
import PreschoolApplicationFile from './Attachments/PreschoolApplicationFile';
import MilitaryOrderStationFile from './Attachments/MilitaryOrderStationFile';
import AwardVerificationFile from './Attachments/AwardVerificationFile';

import API from '../../config/api';
import { errorField } from '../../utils/helpers';

import MediaManager from '../MediaManager';

const initialFormState = {
	studentId: null,
	firstName: '',
	lastName: '',
	gender: '',
	dateOfBirth: null,
	grade: '',
	hasDisability: null,
	school: '',
	city: '',
	isLastYearDifferentSchool: null,
	lastYearSchool: '',
	lastYearCity: '',
	lastYearState: '',
	achievements: '',
	involvements: '',
	merits: '',
	criteriaId: '',
	criteria_6_school: '',
	criteria_6_city: '',
	isSpotlightStudent: null,
	pk_has_disability_key: null,
	attendance_verification_key: null,
	preschool_application_key: null,
	military_order_station_key: null,
	award_verification_key: null,
};

const Criteria6 = ({ value, description, formState, errors, onChange }) => {
	return (
		<div className='criteria-6'>
			<FormControlLabel value={value} control={<Radio color='primary' />} label={description} />
			<Grid container spacing={3}>
				<Grid item xs={12} lg={6}>
					<TextField
						label='School'
						name='criteria_6_school'
						value={formState.criteria_6_school}
						error={errors.criteria_6_school}
						color='primary'
						variant='outlined'
						inputProps={{ maxLength: 45 }}
						onChange={onChange}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} lg={6}>
					<TextField
						label='City'
						name='criteria_6_city'
						value={formState.criteria_6_city}
						error={errors.criteria_6_city}
						color='primary'
						variant='outlined'
						inputProps={{ maxLength: 45 }}
						onChange={onChange}
						fullWidth
					/>
				</Grid>
			</Grid>
		</div>
	);
};

const StudentForm = ({
	account,
	editMode,
	application,
	criteria,
	selectedStudent,
	deletedStudent,
	studentsCount,
	onEdit,
	submitError,
	onUpdateStudents,
	onUpdateSelectedStudent,
	studentsLimit,
	onAutosaveLoading,
	onAutosaveSuccess,
}) => {
	const [loading, setLoading] = useState(false);
	const [loadingFile, setLoadingFile] = useState(false);
	const [formExpanded, setFormExpanded] = useState(true);
	const [expanded, setExpanded] = useState(true);
	const [formError, setFormError] = useState(null);
	const [formSuccess, setFormSuccess] = useState(false);
	const [formState, setFormState] = useState(initialFormState);

	const autosaveRef = useRef(null);
	const { period: applicationPeriod, version } = application;

	// set applicationId
	useEffect(() => {
		const { applicationId } = application;
		setFormState({ ...formState, applicationId });
		setFormExpanded(studentsCount == 0);
	}, [application]);

	// set formState on edit mode
	useEffect(() => {
		if (!editMode || !selectedStudent) return;

		let dateOfBirth = null;
		if(!!selectedStudent.dateOfBirth){			
			let onlyDate = null;
			if(moment.isMoment(selectedStudent.dateOfBirth)){
				onlyDate = selectedStudent.dateOfBirth.format('MM/DD/YYYY');
			}else{
				onlyDate = selectedStudent.dateOfBirth.split('T')[0];
			}
			dateOfBirth = moment(onlyDate).format('MM/DD/YYYY');
		}

		setFormState({ ...selectedStudent, dateOfBirth });
		setFormExpanded(true);
		setFormError(null);
		// setExpanded(false)
	}, [editMode, selectedStudent]);

	// handle auto-save
	useEffect(() => {
		const timeout = setTimeout(() => autosaveRef.current && autosaveStduent(), 3000);
		return () => clearTimeout(timeout);
	}, [formState]);

	// handle submit application errors
	useEffect(() => {
		if (submitError && submitError.field) setFormError(submitError);
	}, [submitError]);

	// deleted student
	useEffect(() => {
		const { applicationId } = application;

		setFormExpanded(studentsCount == 0);

		if (!deletedStudent || !selectedStudent) return;
		if (deletedStudent == selectedStudent.studentId) {
			setFormState({ ...initialFormState, applicationId });
			onEdit(null, false);
		}
	}, [deletedStudent]);

	const scrollTo = (selector, behavior = 'smooth') => {
		const element = document.querySelector(selector);
		element.scrollIntoView({ block: 'start', behavior });
	};

	const htmlLabel = (label) => {
		return { __html: label };
	};

	const renderCriteria = (errors) => {
		let content = [];

		criteria.map((d) => {
			const criteriaId = d.criteriaId.toString();

			const radio =
				criteriaId == 6 ? (
					<Criteria6 key={criteriaId} value={criteriaId} description={d.description} formState={formState} errors={errors} onChange={handleChange} />
				) : (
					<FormControlLabel key={criteriaId} value={criteriaId} control={<Radio color='primary' />} label={<span dangerouslySetInnerHTML={htmlLabel(d.description)} />} />
				);

			content.push(radio);
		});

		return content;
	};

	const renderSchools = async () => {
		const results = await API.GetSchoolNames(account.tokenAuth);
		if (!results || !results.data) return;
		return results.data.map((x) => x.name);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
		setFormError(null);
		autosaveRef.current = true;
	};

	const handleCheckboxChange = (event) => {
		const { name, value } = event.target;
		const checked = value == 'yes';
		setFormState({ ...formState, [name]: checked });
		setFormError(null);
		autosaveRef.current = true;
	};

	const handleDateChange = (date) => {
		setFormState({ ...formState, dateOfBirth: date });
		autosaveRef.current = true;
	};

	const handleRadioChange = (event) => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
		//if (value != 6) setExpanded(!expanded)
		setFormError(null);
		autosaveRef.current = true;
	};

	const handleExpansionChange = (event, isExpanded) => setExpanded(!expanded);

	const handleCancelEdit = () => {
		const { applicationId } = application;

		onEdit(null, false);
		setFormState({ ...initialFormState, applicationId });

		setFormError(null);
		setExpanded(true);
		scrollTo('.student-form');
	};

	const updateStudent = (token, data, isSubmit) => {
		const { applicationId } = application;

		API.UpdateLIStudent(token, data).then((res) => {
			setLoading(false);

			if (res.status !== 201) return setFormError({ field: res.field, message: res.message });

			setFormSuccess(true);

			if (isSubmit) {
				if (editMode) onUpdateSelectedStudent(res.data);
				else onUpdateStudents({ ...res.data, expanded });

				setFormState({ ...initialFormState, applicationId });
				setFormExpanded(false);
				onEdit(null, false);
				setTimeout(() => scrollTo('.financial-section', 'auto'), 300);
			} else {
				onUpdateSelectedStudent(res.data);
				onUpdateStudents(res.data);
				handleCancelEdit();
			}
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const isSubmit = e.nativeEvent.type == 'submit';

		let dateOfBirth = null;
		if(!!formState.dateOfBirth){			
			let onlyDate = null;
			if(moment.isMoment(formState.dateOfBirth)){
				onlyDate = formState.dateOfBirth.format('MM/DD/YYYY');
			}else{
				onlyDate = formState.dateOfBirth.split('T')[0];
			}
			dateOfBirth = moment(onlyDate).format('MM/DD/YYYY');
		}
		
		const data = { ...formState, dateOfBirth, autosave: false };

		if (!isSubmit && !formExpanded) return setFormExpanded(true);

		setFormError(null);
		setLoading(true);

		updateStudent(account.tokenAuth, data, isSubmit);
		autosaveRef.current = false;
	};

	const autosaveStduent = () => {
		const { tokenAuth } = account;

		let dateOfBirth = null;
		if(!!formState.dateOfBirth){			
			let onlyDate = null;
			if(moment.isMoment(formState.dateOfBirth)){
				onlyDate = formState.dateOfBirth.format('MM/DD/YYYY');
			}else{
				onlyDate = formState.dateOfBirth.split('T')[0];
			}
			dateOfBirth = moment(onlyDate).format('MM/DD/YYYY');
		}		

		const data = { ...formState, dateOfBirth, autosave: true };

		setFormError(null);
		onAutosaveLoading(true);

		API.UpdateLIStudent(tokenAuth, data).then((res) => {
			onAutosaveLoading(false);

			if (res.status != 201) return setFormError({ field: res.field, message: res.message });
			if (selectedStudent) {
				onUpdateSelectedStudent(res.data);
				onUpdateStudents(res.data);
			}

			onAutosaveSuccess(true);
			setFormState({ ...formState, studentId: res.data.studentId });
		});
		autosaveRef.current = false;
	};

	const renderMediaUploader = () =>{		
		return (
			<Grid item xs={12}>
				{formState.criteriaId == 1 && (							
					<MediaManager title="Upload Attendance Verification" keyName="attendance_verification" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)} />		
				)}
				{formState.criteriaId == 3 && (
					<MediaManager title="Preschool Application" keyName="preschool_application" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)}  />
				)}
				{formState.criteriaId == 4 && (
					<MediaManager title="Upload Military Order" keyName="military_order_station" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)}  />
				)}
				{(formState.criteriaId == 5 || formState.criteriaId == 7) && (
					<MediaManager title="Upload School Tuition Organization Award Verification" keyName="award_verification" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)}  />
				)}
				{((formState.hasDisability && formState.grade == 'PK') || formState.criteriaId == 3) && (
					<MediaManager title="Upload IEP or MET" keyName="pk_has_disability" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)} />
				)}
				{formState.criteriaId == 8 && (							
					<MediaManager title="Upload County Homeschool Withdrawal Form" keyName="homeschool_withdrawal" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)} />		
				)}			
				{formState.criteriaId == 9 && (							
					<MediaManager title="Upload Out of State School Enrollment" keyName="out_enrollment" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)} />		
				)}			
				{formState.criteriaId == 10 && (							
					<MediaManager title="Upload ESA Letter" keyName="esa_letter" onUploading={()=>setLoadingFile(true)} onUploaded={()=>setLoadingFile(false)} />		
				)}			

			</Grid>
		);
	};

	const renderFileUploader = () =>{
		return (
			<Fragment>
			{formState.criteriaId == 1 && (							
				<Grid item xs={12}>
					<FormControl className={`form-control-radio ${errors.attendance_verification_key ? 'error' : ''}`} fullWidth>
						<label>Upload Attendance Verification</label>
						<AttendanceVerificationFile
							applicationId={application.applicationId}
							token={account.tokenAuth}
							formState={formState}
							setLoadingFile={(bool) => setLoadingFile(bool)}
							setFormState={(key) =>
								setFormState({
									...formState,
									attendance_verification_key: key,
								})
							}
						/>
					</FormControl>
				</Grid>
			)}
			{formState.criteriaId == 3 && (
				<Grid item xs={12}>
					<FormControl className={`form-control-radio ${errors.preschool_application_key ? 'error' : ''}`} fullWidth>
						<label>Preschool Application</label>
						<PreschoolApplicationFile
							applicationId={application.applicationId}
							token={account.tokenAuth}
							formState={formState}
							setLoadingFile={(bool) => setLoadingFile(bool)}
							setFormState={(key) =>
								setFormState({
									...formState,
									preschool_application_key: key,
								})
							}
						/>
					</FormControl>
				</Grid>
			)}
			{formState.criteriaId == 4 && (
				<Grid item xs={12}>
					<FormControl className={`form-control-radio ${errors.military_order_station_key ? 'error' : ''}`} fullWidth>
						<label>Upload Military Order</label>
						<MilitaryOrderStationFile
							applicationId={application.applicationId}
							token={account.tokenAuth}
							formState={formState}
							setLoadingFile={(bool) => setLoadingFile(bool)}
							setFormState={(key) =>
								setFormState({
									...formState,
									military_order_station_key: key,
								})
							}
						/>
					</FormControl>
				</Grid>
			)}
			{(formState.criteriaId == 5 || formState.criteriaId == 7) && (
				<Grid item xs={12}>
					<FormControl className={`form-control-radio ${errors.award_verification_key ? 'error' : ''}`} fullWidth>
						<label>Upload School Tuition Organization Award Verification</label>
						<AwardVerificationFile
							applicationId={application.applicationId}
							token={account.tokenAuth}
							formState={formState}
							setLoadingFile={(bool) => setLoadingFile(bool)}
							setFormState={(key) =>
								setFormState({
									...formState,
									award_verification_key: key,
								})
							}
						/>
					</FormControl>
				</Grid>
			)}
			{((formState.hasDisability && formState.grade == 'PK') || formState.criteriaId == 3) && (
				<Grid item xs={12}>
					<FormControl className={`form-control-radio ${errors.pk_has_disability_key ? 'error' : ''}`} fullWidth>
						<label>Upload IEP or MET</label>
						<HasDisabilityFile
							applicationId={application.applicationId}
							token={account.tokenAuth}
							formState={formState}
							setLoadingFile={(bool) => setLoadingFile(bool)}
							setFormState={(key) =>
								setFormState({
									...formState,
									pk_has_disability_key: key,
								})
							}
						/>
					</FormControl>
				</Grid>
			)}			
			</Fragment>
		);
	};

	// const setStudentName = () => {
	//     const firstname = selectedStudent.firstName || ''
	//     const lastname = selectedStudent.lastName || ''

	//     return `${firstname} ${lastname}`
	// }

	// const panelTitle = !editMode || !selectedStudent
	//     ? `Student #${studentsCount + 1}`
	//     : `Student #${selectedStudent.index} - ${selectedStudent.firstName} ${selectedStudent.lastName}`

	// const panelTitle = editMode && selectedStudent ? setStudentName() : ''

	const isStudentsLimit = studentsCount == studentsLimit;

	// const isAddAnotherDisabled = (isStudentsLimit && formExpanded) || loadingFile
	const isSaveDisabled = isStudentsLimit || loadingFile;

	const errors = errorField(formError);

	return (
		<form className='student-form' onSubmit={handleSubmit}>
			<Collapse in={formExpanded}>
				<BorderedPanelSection>
					<BorderedPanelSectionHeader title={' '} />
					<FormControl component='fieldset' fullWidth>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									label='First Name'
									name='firstName'
									value={formState.firstName}
									error={errors.firstName}
									color='primary'
									variant='outlined'
									inputProps={{ maxLength: 120 }}
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label='Last Name'
									name='lastName'
									value={formState.lastName}
									error={errors.lastName}
									color='primary'
									variant='outlined'
									inputProps={{ maxLength: 120 }}
									onChange={handleChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl variant='outlined' error={errors.gender} fullWidth>
									<InputLabel id='gender-label'>Gender</InputLabel>
									<Select label='Gender' labelId='gender-label' id='gender' name='gender' value={formState.gender} onChange={handleChange}>
										<MenuItem value='M'>Male</MenuItem>
										<MenuItem value='F'>Female</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={4}>
								<MuiPickersUtilsProvider utils={MomentUtils}>
									<KeyboardDatePicker
										error={errors.dateOfBirth}
										autoOk
										fullWidth
										disableFuture
										mask='__/__/____'
										openTo='year'
										variant='inline'
										format='MM/DD/YYYY'
										label='Date of Birth'
										inputVariant='outlined'
										emptyLabel='mm/dd/yyyy'
										InputAdornmentProps={{ position: 'start' }}
										value={formState.dateOfBirth}
										onChange={handleDateChange}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={12} md={4}>
								<FormControl variant='outlined' error={errors.grade} fullWidth>
									<InputLabel id='grade-label'>Grade in {applicationPeriod}</InputLabel>
									<Select label={'Grade in ' + applicationPeriod} labelId='grade-label' id='grade' name='grade' value={formState.grade} onChange={handleChange}>
										<MenuItem value='PK'>PK</MenuItem>
										<MenuItem value='K'>K</MenuItem>
										<MenuItem value='1'>1</MenuItem>
										<MenuItem value='2'>2</MenuItem>
										<MenuItem value='3'>3</MenuItem>
										<MenuItem value='4'>4</MenuItem>
										<MenuItem value='5'>5</MenuItem>
										<MenuItem value='6'>6</MenuItem>
										<MenuItem value='7'>7</MenuItem>
										<MenuItem value='8'>8</MenuItem>
										<MenuItem value='9'>9</MenuItem>
										<MenuItem value='10'>10</MenuItem>
										<MenuItem value='11'>11</MenuItem>
										<MenuItem value='12'>12</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							{formState.grade == 'PK' && (
								<Grid item xs={12}>
									<FormControl className={`form-control-checkbox ${errors.hasDisability ? 'error' : ''}`} style={{ margin: 0 }}>
										<label>Does this student have a disability?</label>
										<FormGroup>
											<FormControlLabel control={<Checkbox color='primary' name='hasDisability' value='yes' checked={formState.hasDisability} />} label='Yes' onChange={handleCheckboxChange} />
											<FormControlLabel control={<Checkbox color='primary' name='hasDisability' value='no' checked={formState.hasDisability == false} />} label='No' onChange={handleCheckboxChange} />
										</FormGroup>
									</FormControl>
								</Grid>
							)}
							<Grid item xs={12} md={8}>
								<TextFieldAutoComplete
									label={'Private School in ' + applicationPeriod}
									noOptionsText='School not found. Please contact AZLF.'
									name='school'
									error={errors.school}
									defaultValue={formState.school}
									onLoad={renderSchools}
									onChange={handleChange}
									inputProps={{ maxLength: 255 }}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<TextField label='City' name='city' value={formState.city} error={errors.city} color='primary' variant='outlined' inputProps={{ maxLength: 45 }} onChange={handleChange} fullWidth />
							</Grid>
							<Grid item xs={12}>
								<FormControl className='form-control-checkbox' style={{ margin: 0 }}>
									<label>Did the student attend a different school last year?</label>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox color='primary' name='isLastYearDifferentSchool' value='yes' checked={formState.isLastYearDifferentSchool} />}
											label='Yes'
											onChange={handleCheckboxChange}
										/>
										<FormControlLabel
											control={<Checkbox color='primary' name='isLastYearDifferentSchool' value='no' checked={formState.isLastYearDifferentSchool == false} />}
											label='No'
											onChange={handleCheckboxChange}
										/>
									</FormGroup>
								</FormControl>
							</Grid>
							{formState.isLastYearDifferentSchool && (
								<Fragment>
									<Grid item xs={12}>
										<TextField
											label='Previous school Name'
											name='lastYearSchool'
											value={formState.lastYearSchool}
											error={errors.lastYearSchool}
											color='primary'
											variant='outlined'
											inputProps={{ maxLength: 255 }}
											onChange={handleChange}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label='Previous School City'
											name='lastYearCity'
											value={formState.lastYearCity}
											error={errors.lastYearCity}
											color='primary'
											variant='outlined'
											inputProps={{ maxLength: 45 }}
											onChange={handleChange}
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<TextField
											label='Previous School State'
											name='lastYearState'
											value={formState.lastYearState}
											error={errors.lastYearState}
											color='primary'
											variant='outlined'
											inputProps={{ maxLength: 45 }}
											onChange={handleChange}
											fullWidth
										/>
									</Grid>
								</Fragment>
							)}
							<Grid item xs={12}>
								<TextField
									label='Leadership and Extracurricular Activities'
									name='achievements'
									value={formState.achievements}
									color='primary'
									variant='outlined'
									className='multiline'
									onChange={handleChange}
									fullWidth
									multiline
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField label='Academic Merit' name='merits' value={formState.merits} color='primary' variant='outlined' className='multiline' onChange={handleChange} fullWidth multiline />
							</Grid>
							<Grid item xs={12}>
								<TextField
									label='Additional Personal or Economic Factors (If Any) That You Feel ALF Should Consider'
									name='involvements'
									value={formState.involvements}
									color='primary'
									variant='outlined'
									className='multiline margin'
									onChange={handleChange}
									fullWidth
									multiline
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl className='form-control-checkbox' style={{ margin: 0 }}>
									<label>Would your student be interested in being featured as a student spotlight, sent to donors and featured on our website?</label>
									<FormGroup>
										<FormControlLabel control={<Checkbox color='primary' name='isSpotlightStudent' value='yes' checked={formState.isSpotlightStudent} />} label='Yes' onChange={handleCheckboxChange} />
										<FormControlLabel
											control={<Checkbox color='primary' name='isSpotlightStudent' value='no' checked={formState.isSpotlightStudent == false} />}
											label='No'
											onChange={handleCheckboxChange}
										/>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<div className={`student-criteria ${errors.criteriaId ? 'error' : ''}`}>
									<ExpansionPanel className='transparent' expanded={expanded} onChange={handleExpansionChange}>
										<ExpansionPanelSummary id='criteria-expansion-panel' expandIcon={<ExpandMoreIcon />}>
											<p>
												Criteria for Eligibility
												{!formState.criteriaId && <i>(please select only one)</i>}
											</p>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<RadioGroup name='criteriaId' value={formState.criteriaId ? formState.criteriaId.toString() : ''} onChange={handleRadioChange}>
												{renderCriteria(errors)}
											</RadioGroup>
										</ExpansionPanelDetails>
									</ExpansionPanel>
									{formState.criteriaId && !expanded && (
										<span className='selected-criteria' dangerouslySetInnerHTML={htmlLabel(criteria.filter((d) => d.criteriaId == formState.criteriaId)[0].description)} />
									)}
								</div>
							</Grid>

							{version >= 30 ? renderMediaUploader():renderFileUploader()}							
							
							{formError && (
								<Grid item xs={12}>
									<Alert severity='error' onClose={() => setFormError(null)}>
										{formError.message}
									</Alert>
								</Grid>
							)}
							{isStudentsLimit && (
								<Grid item xs={12}>
									<Alert severity='error'>You have reached the limit of students for this submission</Alert>
								</Grid>
							)}
						</Grid>
					</FormControl>
				</BorderedPanelSection>
				<BorderedPanelFooter>
					<div className='user-information__panel-footer'>
						{/* <span>{studentsCount} student{studentsCount != 1 ? 's' : ''}</span> */}
						<div></div>
						{!loading ? (
							<div>
								{/* <Button type="button" size="large" color="secondary" startIcon={<AddIcon />} onClick={handleSubmit} disabled={isAddAnotherDisabled} disableRipple>Add another student</Button> */}
								<Button type='submit' size='large' color='secondary' variant='contained' startIcon={<SaveIcon />} disabled={isSaveDisabled} disableRipple>
									Save
								</Button>
							</div>
						) : (
							<Loader loading={true} />
						)}
					</div>
				</BorderedPanelFooter>
			</Collapse>
			<Snackbar open={formSuccess} severity={'success'} text={'Student saved'} handleClose={() => setFormSuccess(false)} />
		</form>
	);
};

StudentForm.defaultProps = {
	studentsLimit: 10,
};

export default StudentForm;
