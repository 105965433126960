import React, { Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import configureStore from './redux';
import { Provider } from 'react-redux';
import Auth from './config/auth';

import Login from './views/Login';
import Register from './views/Register';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import ForgotEmail from './views/ForgotEmail';
import PassSecurity from './views/PassSecurity';
import UserProfile from './views/UserProfile';
import NotFound from './views/NotAllowed';
import { ApplicationsList, ApplicationForm, ApplicationView } from './views/Application';

const store = configureStore();

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Switch>
						<Route exact path='/' component={(e) => Auth.authorize(ApplicationsList, e, store, 1)} />
						<Route exact path='/login' component={(e) => Auth.validate(Login, e, store)} />
						<Route exact path='/login/:email' component={(e) => Auth.validate(Login, e, store)} />
						<Route exact path='/register' component={Register} />
						<Route exact path='/forgot-password' component={ForgotPassword} />
						<Route exact path='/forgot-email' component={ForgotEmail} />
						<Route exact path='/user/profile' component={(e) => Auth.authorize(UserProfile, e, store, 1)} />
						<Route exact path='/account/complete/:code' component={PassSecurity} />
						<Route exact path='/application/li/create' component={(e) => Auth.authorize(ApplicationForm, e, store, 1)} />
						<Route exact path='/application/dd/create' component={(e) => Auth.authorize(ApplicationForm, e, store, 1)} />
						<Route exact path='/application/li/edit/:applicationId' component={(e) => Auth.authorize(ApplicationForm, e, store, 1)} />
						<Route exact path='/application/dd/edit/:applicationId' component={(e) => Auth.authorize(ApplicationForm, e, store, 1)} />
						<Route exact path='/application/li/sign/:applicationId' component={(e) => Auth.authorize(ApplicationForm, e, store, 1)} />
						<Route exact path='/application/dd/sign/:applicationId' component={(e) => Auth.authorize(ApplicationForm, e, store, 1)} />
						<Route exact path='/application/li/view/:applicationId' component={(e) => Auth.authorize(ApplicationView, e, store, 1)} />
						<Route exact path='/application/dd/view/:applicationId' component={(e) => Auth.authorize(ApplicationView, e, store, 1)} />
						<Route exact path='/account/reset/:code' component={ResetPassword} />
						<Route component={NotFound} />
					</Switch>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
