import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Alert = ({ open, showIcon, title, text, confirmButtonText, cancelButtonText, onConfirm, onCancel, children }) => {
	return (
		<Modal open={open} BackdropComponent={Backdrop} BackdropProps={{ timeout: 500, style: { backgroundColor: 'rgba(108, 108, 108, 0.7)' } }}>
			<Fade in={open}>
				<div className='app-alert__container'>
					{/* <div className="alert__header"></div> */}
					<div className='alert__body'>
						{showIcon && (
							<div className='alert__icon'>
								<ErrorOutlineIcon />
							</div>
						)}
						{children || (
							<Fragment>
								<h2>{title}</h2> <p>{text}</p>
							</Fragment>
						)}
						<div className='alert__buttons'>
							{onCancel && (
								<Button className='cancel' variant='contained' fullWidth disableRipple onClick={onCancel}>
									{cancelButtonText}
								</Button>
							)}
							<Button className='confirm' variant='contained' color='primary' fullWidth disableRipple onClick={onConfirm}>
								{confirmButtonText}
							</Button>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

Alert.propTypes = {
	open: PropTypes.bool.isRequired,
	showIcon: PropTypes.bool,
	title: PropTypes.string,
	text: PropTypes.string,
	confirmButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
};

Alert.defaultProps = {
	showIcon: true,
	confirmButtonText: 'OK',
	cancelButtonText: 'Cancel',
};

export default Alert;
