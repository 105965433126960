import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Layout from '../../component/partials/Layout';
import UserInformation from '../../component/partials/UserInformation';
import Alert from '../../component/Alert';
import LIApplication from './LIApplication';
import DDApplication from './DDApplication';

import API from '../../config/api';

const applications = { LIApplication, DDApplication };

const UserInformationButton = ({}) => (
	<Link to='/' className='text-decoration-none'>
		<Button variant='contained' size='small' color='primary' disableRipple>
			Back to dashboard
		</Button>
	</Link>
);

const SectionTitle = ({ applicationType, isEdit, isSign, toggleAlert, isValidApplication }) => {
	const applicationName = applicationType == 'LI' ? 'Low Income' : 'Disabled/Displaced';

	return (
		<Fragment>
			{!isEdit && <h3 className='section-title'>New {applicationName} Application</h3>}
			{isEdit && !isSign && (
				<div className='section-title__container'>
					<h3 className='section-title'>Edit {applicationName} Application</h3>
					{isValidApplication && (
						<Button variant='contained' color='secondary' startIcon={<DeleteForeverIcon />} onClick={toggleAlert} disableRipple>
							Delete
						</Button>
					)}
				</div>
			)}
			{isSign && <h3 className='section-title'>Make Corrections {applicationName} Application</h3>}
		</Fragment>
	);
};

const ApplicationForm = ({ account, history }) => {
	const [openAlert, setOpenAlert] = useState(false);
	const [isValidApplication, setIsValidApplication] = useState(true);

	const location = useLocation();
	const params = useParams();

	const { period } = account.periodSettings;

	const toggleAlert = () => setOpenAlert(!openAlert);

	const handleValidApplication = (bool) => setIsValidApplication(bool);

	const handleDeleteApplication = async () => {
		const { applicationId } = params,
			{ tokenAuth } = account;
		const body = { applicationId, delete: true };

		await API.DeleteParentApplication(tokenAuth, body);

		window.location.replace('/');
	};

	const applicationType = location.pathname.split('/')[2].toUpperCase();
	const isSign = location.pathname.split('/')[3] == 'sign';
	const editId = params.applicationId;
	const codeValidated = location.state?.codeValidated;
	const Content = applications[`${applicationType}Application`];

	return (
		<Layout history={history} period={period}>
			<div className='application-page application-form-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<div className='user__column'>
							<UserInformation renderButton={<UserInformationButton />} />
						</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<div className='form__column'>
							<SectionTitle applicationType={applicationType} isEdit={editId} isSign={isSign} toggleAlert={toggleAlert} isValidApplication={isValidApplication} />
							<div className='application-form__content'>
								<Content account={account} editId={editId} onValidApplication={handleValidApplication} codeValidated={codeValidated} />
							</div>
						</div>
					</Grid>
				</Grid>
			</div>

			<Alert
				open={openAlert}
				title='Delete Application'
				text='Are you sure you want to delete this application?'
				confirmButtonText='Delete'
				onConfirm={handleDeleteApplication}
				onCancel={toggleAlert}
			/>
		</Layout>
	);
};

export default connect((s) => ({ account: s.account }))(ApplicationForm);
