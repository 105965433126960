import FetchRequest from './request';
import * as c from './constants';

class ApiModel {
	// Auth
	Login = (credentials) => FetchRequest(c.LOGIN, 'POST', null, credentials);
	// User
	GetAccountToken = (token) => FetchRequest(c.GETCURRENT, 'GET', token);
	CompleteAccount = (data) => FetchRequest(c.COMPLETEACCOUNT, 'POST', null, data);
	CreateAccount = (data) => FetchRequest(c.CREATEACCOUNT, 'POST', null, data); //put token
	RecoveryPassword = (data) => FetchRequest(c.RECOVERYPASSWORD, 'POST', null, data);
	ResetPassword = (data) => FetchRequest(c.RESETPASSWORD, 'POST', null, data);
	ValidateRecoveryCode = (data) => FetchRequest(c.VALIDATERECOVERYCODE, 'POST', null, data);
	GetAllAccount = (token) => FetchRequest(c.GETALLACCOUNT, 'GET', token);
	CheckExpirationDate = (code) => FetchRequest(c.CHECKEXPIRATIONDATE + '/' + code, 'GET', null);
	GetAccount = (token, _id) => FetchRequest(c.GETACCOUNT, 'POST', token, { _id });
	UpdateAccount = (token, data) => FetchRequest(c.UPDATEACCOUNT, 'POST', token, data);
	DeleteAccount = (token, _id) => FetchRequest(c.DELETEACCOUNT, 'DELETE', token, { _id });
	GetUserByEmailPhone = (data) => FetchRequest(c.GETUSERBYEMAILPHONE, 'POST', null, data);
	UpdatePassword = (token, data) => FetchRequest(c.UPDATEPASSWORDACCOUNT, 'POST', token, data);
	UpdateQuestions = (token, data) => FetchRequest(c.UPDATEQUESTIONS, 'POST', token, data);

	// Security Question
	GetQuestion = () => FetchRequest(c.GETQUESTION, 'GET', null);
	GetQuestionsByAccountId = (data) => FetchRequest(c.GETRANDOMQUESTIONSBYACCOUNTID, 'POST', null, data);
	GetAllQuestionsByAccountId = (token, data) => FetchRequest(c.GETALLQUESTIONSBYACCOUNTID, 'POST', token, data);
	ValidateQuestion = (data) => FetchRequest(c.VALIDATEQUESTION, 'POST', null, data);

	// Application
	GetCriteria = (token) => FetchRequest(c.GETCRITERIA, 'GET', token);
	CreateLIApplication = (token) => FetchRequest(c.CREATELIAPPLICATION, 'POST', token);
	GetParentApplications = (token) => FetchRequest(c.GETPARENTAPPLICATIONS, 'GET', token);
	DeleteParentApplication = (token, data) => FetchRequest(c.DELETEPARENTAPPLICATION, 'POST', token, data);
	CreateDDApplication = (token) => FetchRequest(c.CREATEDDAPPLICATION, 'POST', token);
	CreateLIStudent = (token, data) => FetchRequest(c.CREATELISTUDENT, 'POST', token, data);
	UpdateLIStudent = (token, data) => FetchRequest(c.UPDATELISTUDENT, 'POST', token, data);
	DeleteLIStudent = (token, data) => FetchRequest(c.DELETELISTUDENT, 'POST', token, data);
	UpdateDDStudent = (token, data) => FetchRequest(c.UPDATEDDSTUDENT, 'POST', token, data);
	UpdateDDPrerequisites = (token, data) => FetchRequest(c.UPDATEDDPREREQUISITES, 'POST', token, data);
	SetSharingPermissions = (token, data) => FetchRequest(c.SETSHARINGPERMISSIONS, 'POST', token, data);
	SetAgreeStatements = (token, data) => FetchRequest(c.SETAGREESTATEMENTS, 'POST', token, data);
	SetSubmittedAgreements = (token, data) => FetchRequest(c.SETSUBMITTEDAGREEMENTS, 'POST', token, data);
	GetFinancialMembers = (token, data) => FetchRequest(c.GETFINANCIALMEMBERS, 'GET', token, data);
	CreateApplicationFinancial = (token, data) => FetchRequest(c.CREATEAPPLICATIONFINANCIAL, 'POST', token, data);
	UpdateApplicationFinancial = (token, data) => FetchRequest(c.UPDATEAPPLICATIONFINANCIAL, 'POST', token, data);
	DeleteApplicationFinancial = (token, data) => FetchRequest(c.DELETEAPPLICATIONFINANCIAL, 'POST', token, data);
	RequestSignature = (token, data) => FetchRequest(c.REQUESTSIGNATURE, 'POST', token, data);
	UpdateSignature = (token, data) => FetchRequest(c.UPDATESIGNATURE, 'POST', token, data);
	GetApplication = (token, data) => FetchRequest(c.GETAPPLICATION, 'GET', token, data);
	GetSignatureRequestDownload = (token, data) => FetchRequest(c.GETSIGNATUREREQUESTDOWNLOAD, 'GET', token, data);
	SetHouseholdMembers = (token, data) => FetchRequest(c.SETHOUSEHOLDMEMBERS, 'POST', token, data);
	UpdateFinancialQuestions = (token, data) => FetchRequest(c.UPDATEFINANCIALQUESTIONS, 'POST', token, data);
	GetHistoryApplications = (token, data) => FetchRequest(c.GETHISTORYAPPLICATIONS, 'GET', token, data);

	// School
	GetSchoolNames = (token) => FetchRequest(c.GETSCHOOLNAMES, 'GET', token);

	// File
	GetUploadFileData = (token, data) => FetchRequest(c.GETUPLOADFILEDATA, 'POST', token, data);
	GetDownloadFileUrl = (token, data) => FetchRequest(c.GETDOWNLOADFILEURL, 'POST', token, data);
	DeleteFile = (token, data) => FetchRequest(c.DELETEFILE, 'POST', token, data);

	// Site
	ValidateSubmission = (token, data) => FetchRequest(c.VALIDATESUBMISSIONS, 'POST', token, data);
	GetPeriods = (token) => FetchRequest(c.GETPERIODS, 'GET', token);
	GetIncomeTable = (token) => FetchRequest(c.GETINCOMETABLE, 'GET', token);

	// Media
	GetUploadMediaData = (token, data) => FetchRequest(c.GETUPLOADMEDIADATA, 'POST', token, data);
	GetDownloadMediaUrl = (token, data) => FetchRequest(c.GETDOWNLOADMEDIAURL, 'POST', token, data);
	DeleteMedia = (token, data) => FetchRequest(c.DELETEMEDIA, 'POST', token, data);
	AddMedia = (token, data) => FetchRequest(c.ADDMEDIA, 'POST', token, data);

}

export default new ApiModel();
