export const COLORS = {
	Secondary: '#9BB43B', //green

	Success: '#28a745',
	Danger: '#dc3545',
};

export const REGEX = {
	passwordRegEx: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~#$^+=!*()@%&.`|{}\\[\]:;',/<>?])(?=.{8,})/,
	emailRegEx: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
	phoneRegEx: /^[0-9]{10}$/,
	zipRegEx: /^\d{5}$|^\d{5}-\d{4}$/,
	currencyRegEx: /[^0-9.-]+/g,
	recoveryCodeRegEx: /^[0-9]{6}$/,
};
