import React, { Fragment } from 'react';
import { Button } from '@material-ui/core';

const Message = ({ account, onClose, onSetContent }) => {
	const renderHTML = () => {
		const { noteClosedSubmission } = account.siteSettings;
		return { __html: noteClosedSubmission };
	};

	return (
		<Fragment>
			<div className='message-modal'>
				<div dangerouslySetInnerHTML={renderHTML()} />
			</div>
			<div className='admin-message-modal__buttons'>
				<Button variant='contained' color='primary' onClick={onClose} fullWidth disableRipple>
					Close
				</Button>
				<Button variant='contained' color='primary' onClick={() => onSetContent('CodeForm')} fullWidth disableRipple>
					I have a code
				</Button>
			</div>
		</Fragment>
	);
};

export default Message;
