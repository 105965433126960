import * as types from '../constants';
import API from '../../config/api';

const loadedApplication = (application) => ({
	type: types.LOADED_APPLICATION,
	loading: false,
	application,
});

const loadingApplication = () => ({
	type: types.LOADING_APPLICATION,
	loading: true	
});


export const getApplicationRdx = (token, id) => dispatch => {	
	dispatch(loadingApplication());	
	return API.GetApplicationById(token, {id}).then((response) => {
		const { status, message, data } = response;
		if (status !== 201) return;

		dispatch(loadedApplication(data));
	});
};

export const setApplicationRdx = data => dispatch => {	
	dispatch(loadedApplication(data));
};

export const setLoadingApplicationRdx = () => dispatch => {	
	dispatch(loadingApplication());	
};
