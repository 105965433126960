import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { Grid } from '@material-ui/core';

import FinancialInformation from '../../component/FinancialInformation';
import FinancialAttachments from '../../component/FinancialAttachments';

const DDFinancialInformation = ({ application, account, lock }, ref) => {
	const [selectedMember, setSelectedMember] = useState(null);
	const [memberAttachment, setMemberAttachment] = useState(null);

	const financialAttachmentsRef = useRef(null);
	const financialInformationRef = useRef(null);

	useImperativeHandle(ref, () => ({
		saveFinancialOnSubmit: () => financialInformationRef.current?.saveFinancialOnSubmit(),
		openFirstFinancial: () => financialInformationRef.current.openFirstFinancial(),
	}));

	const setAttachment = (fileKey, responseKey) => setMemberAttachment({ fileKey, responseKey });

	const clearAttachments = () => financialAttachmentsRef.current.clearAttachments();

	const { applicationId, period, previousPeriod } = application,
		{ tokenAuth } = account;

	const current = period == null ? '' : parseInt(period.split('/')[0]);
	const previous = previousPeriod == null ? '' : parseInt(previousPeriod.split('/')[0]);

	return (
		<div className='form__section'>
			<div className='form__section--header'>
				<h3>3. Household Members and Financial Information</h3>
			</div>

			<div className='form__section--body'>
				<Grid container spacing={3}>
					<Grid item xs={12} lg={7}>
						<div className='section__student-financial'>
							<FinancialInformation
								ref={financialInformationRef}
								application={application}
								account={account}
								memberAttachment={memberAttachment}
								onClearAttachments={clearAttachments}
								onSetSelectedParentMember={(member) => setSelectedMember(member)}
							/>

							<div style={{ marginTop: 30 }}>
								<span style={{ fontSize: 14, lineHeight: '23px', fontFamily: 'HelveticaLight' }}>
									By signing at submit I certify that all information on this application is true and all income has been reported and that I have truthfully completed the Financial Worksheet as
									required by the State of Arizona. I also agree to all that the Arizona Leadership Foundation and my private school are allowed to share tuition balance information throughout the
									year. This information is kept confidential between the Arizona Leadership Foundation and the school and is only used to determine Financial Aid needs.
								</span>
							</div>
						</div>
					</Grid>
					<Grid item xs={12} lg={5}>
						<div className='attachements'>
							<div className='attachements__header'>
								<h4>Attachments</h4>
							</div>
							<div className='attachements__body'>
								<div className='instructions'>Please attach a copy of pages 1 & 2 of your {previous} Federal Income Tax Return (IRS 1040)</div>
								<div className='instructions'>
									If you do not file taxes, other proof of income will be needed (Nutrition Assistance / Food Stamp Program, Unemployment Program, Social Security Disability, etc.).
								</div>
								<div className='instructions'>
									If you have filed an extension, submit pgs. 1 & 2 of your {previous - 1} federal taxes and a copy of your {previous} IRS extension notice. {previous} taxes must be submitted by
									October 15, {current}. Please mark out Social Security numbers.
								</div>
								<div className='confidential'>
									All information is held strictly confidential. Arizona Leadership Foundation will not share or disseminate this information in any way with any organization. Please mark out Social
									Security Numbers
								</div>
								<FinancialAttachments ref={financialAttachmentsRef} applicationId={applicationId} previousYear={previous} selectedMember={selectedMember} token={tokenAuth} onSetAttachment={setAttachment} />
							</div>
						</div>
					</Grid>
				</Grid>
			</div>

			{lock && <div className='layer-locked' />}
		</div>
	);
};

export default forwardRef(DDFinancialInformation);
