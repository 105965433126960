import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import Layout from '../../component/partials/Layout';
import UserInformation from '../../component/partials/UserInformation';
import ApplicationBox from './ApplicationBox';
import NewApplicationModal from './NewApplicationModal';
import AdminMessageAlert from '../../component/AdminMessageAlert';
import Loader from '../../component/Loader';
// import CodeModal from '../../component/CodeModal';

import API from '../../config/api';
import { ContactSupportOutlined } from '@material-ui/icons';

const Application = ({ account, history }) => {
	const [loading, setLoading] = useState(true);
	const [openModal, setOpenModal] = useState(false);
	const [openAdminAlert, setOpenAdminAlert] = useState(false);
	const [awaitingApplications, setAwaitingApplications] = useState([]);
	const [submittedApplications, setSubmittedApplications] = useState([]);
	const [unsubmittedApplications, setUnsubmittedApplications] = useState([]);
	const [openCodeModal, setOpenCodeModal] = useState(false);
	const [code, setCode] = useState('');
	const [error, setError] = useState('');
	const [showMessage, setShowMessage] = useState(true);
	const [codeValidated, setCodeValidated] = useState(false);
	const [newAppType, setNewAppType] = useState('LI')

	const { isClosedSubmission } = account.siteSettings;
	const { period } = account.periodSettings;

	useEffect(() => getApplications(), []);

	useEffect(() => {
		if (localStorage.getItem('code') != null) setCode(localStorage.getItem('code'));
		else localStorage.setItem('code', '');
	}, []);

	useEffect(() => {
		if (!openModal) setCodeValidated(false);
	}, [openModal]);

	const getApplications = () => {
		API.GetParentApplications(account.tokenAuth).then((res) => {
			setAwaitingApplications(res.data.awaiting);
			setSubmittedApplications(res.data.submitted);
			setUnsubmittedApplications(res.data.unsubmitted);

			setLoading(false);
		});
	};

	const toggleAdminAlert = () => setOpenAdminAlert(!openAdminAlert);

	const handleDelete = (applicationId, type) => {
		type == 'unsubmitted'
			? setUnsubmittedApplications(unsubmittedApplications.filter((d) => d.applicationId != applicationId))
			: setSubmittedApplications(submittedApplications.filter((d) => d.applicationId != applicationId));
	};

	const renderApplications = (data, status) => {
		if (!data || data.length == 0) return <div className='empty-list'>No applications to display</div>;
		return data.map((d) => <ApplicationBox key={d.applicationId} data={d} status={status} account={account} onDelete={handleDelete} />);
	};

	// Code Modal

	const handleChangeInput = (e) => {
		const { value } = e.target;
		setError(null);
		setCode(value);
	};

	const submit = () => {
		if (code == null) return setError('Code is required');
		const token = account.tokenAuth;
		API.ValidateSubmission(token, { code })
			.then((res) => {
				if (res.data.validated) {
					handleOpenModal();
					toggleModal();
					localStorage.setItem('code', code);
				} else {
					setError('This code is not valid');
					setCode(null);
				}
			})
			.catch((err) => {
				console.log(err);
				setCode(null);
			});
	};

	const closeCodeModal = () => {
		// toggleModal();
		setCode(null);
		setError('');
		setOpenCodeModal(!openCodeModal);
		setShowMessage(true);
	};

	const handleOpenModal = () => {
		// toggleModal();
		setOpenCodeModal(!openCodeModal);
	};

	const showInputCode = () => {
		setShowMessage(!showMessage);
	};

	const onValidated = (isValidated) => {
		setCodeValidated(isValidated);		
		history.push({ pathname: `/application/${newAppType}/create`, state: { isValidated } });
	};

	//const toggleModal = () => setOpenModal(!openModal);
	const toggleModal = () => {	
		history.push('/start-application');
	};

	const toggleCodeModal = () => setOpenCodeModal(!openCodeModal);

	const goToApplication = (type) => {
		setNewAppType(type);
		if(isClosedSubmission){
			toggleCodeModal();
		}else{
			history.push({ pathname: `/application/${type}/create`, state: { codeValidated } });
		};		
	};

	return (
		<Layout history={history} period={period}>
			<div className='application-page'>
				<Grid container spacing={3}>
					<Grid xs={12} md={4} lg={3} item>
						<div className='user__column'>
							<Grid container>
								<Grid item xs={12} sm={6} md={12}>
									<UserInformation />
								</Grid>
								<Grid item xs={12} sm={6} md={12}>
									<div className='button__container'>
										<Button
											variant='contained'
											color='secondary'
											className='new-application__button'
											startIcon={<AddIcon />}
											fullWidth
											disableRipple
											onClick={toggleModal}>
											Start new application
										</Button>
									</div>
								</Grid>
							</Grid>
						</div>
					</Grid>
					<Grid xs={12} md={8} lg={9} item>
						<div className='applications__column'>
							{!loading ? (
								<Fragment>
									{awaitingApplications.length > 0 && (
										<div className='awating-applications application__section'>
											<h3 className='section-title'>Action Needed-Incomplete</h3>
											{renderApplications(awaitingApplications, 'awaiting')}
										</div>
									)}

									<div className='unsubmitted-applications application__section'>
										<h3 className='section-title'>Unsubmitted Applications</h3>
										{renderApplications(unsubmittedApplications, 'unsubmitted')}
									</div>

									<div className='submitted-applications application__section'>
										<h3 className='section-title'>Submitted Applications</h3>
										{renderApplications(submittedApplications, 'submitted')}
									</div>
								</Fragment>
							) : (
								<Loader loading />
							)}
						</div>
					</Grid>
				</Grid>
			</div>

			<NewApplicationModal open={openModal} onClose={toggleModal} goToApplication={goToApplication} />
			<AdminMessageAlert type={newAppType} open={openCodeModal} onClose={toggleCodeModal} onValidated={onValidated} />
			{/* <CodeModal
				showMessage={showMessage}
				open={false}
				onClose={handleOpenModal}
				handleChangeInput={handleChangeInput}
				submit={submit}
				error={error}
				showInputCode={showInputCode}
				closeCodeModal={closeCodeModal}
			/> */}
		</Layout>
	);
};

export default connect((s) => ({ account: s.account }))(Application);
