import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormControl, FormControlLabel, RadioGroup, Radio, Button, Collapse, Box } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';

import IncomeTable from '../../component/IncomeTable';
import Modal from '../../component/Modal';

const RadioButton = (props) => <Radio color='primary' icon={<span className='radio__light-icon' />} checkedIcon={<span className='radio__light-icon checked' />} {...props} />;

const FinancialTable = ({ open, previous }) => (
	<Collapse in={open} timeout='auto' unmountOnExit>
		<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
			<div className='financial-message-title'>
				<p>Applications must be completed by a legal guardian and submitted for each school year</p>
			</div>
			<IncomeTable />
			<div className='financial-message-list' style={{ paddingTop: 20 }}>
				<h4 style={{ textAlign: 'center' }}>INCOME REQUIREMENT</h4>
				<ul className='requirements-list bullet-list'>
					<li>The family’s income cannot exceed 185% of the income limit required to qualify for Reduced Price Meals under the National School Lunch and Child Nutrition Acts.</li>
					<li>
						Submit {previous} IRS Form 1040 pages 1&2 and if more than 4 dependents the Federal Supporting Statement Form 1040 Dependents. If your income is below the income threshold required for
						filing taxes, submit a proof of income.
					</li>
				</ul>
			</div>
			<hr />
		</Box>
	</Collapse>
);

const LIApplication = ({ goToApplication, previous }) => {
	const [open, setOpen] = useState(false);

	return (
		<Fragment>
			<p>
				Family must meet the{' '}
				<Button variant='contained' size='small' color='primary' disableRipple onClick={() => setOpen(!open)}>
					income requirements
				</Button>{' '}
				and one of the following:
			</p>
			<FinancialTable open={open} previous={previous} />
			<ul className='requirements-list bullet-list'>
				<li>
					Attended an Arizona public school as a full-time student for at least 90 days of the prior fiscal year or one full semester and then transferred from the public school to a private school;
					Those students who transfer to a qualified school after the first full semester are eligible to receive a scholarship that same academic year.
				</li>
				<li>Is enrolling in a private school kindergarten. The student must be 5 years old as of September 1st.</li>
				<li>Is enrolling in a private preschool program for students with disabilities; Student must have an IEP, MET or ISP issued by an Arizona public school.</li>
				<li>Is a dependent of a member of the armed forces of the United States who is stationed in Arizona pursuant to military orders.</li>
				<li>
					Received a <u>low-income corporate scholarship</u> in a prior year and the child continued to attend a private school in subsequent years; OR Received an{' '}
					<u>original individual</u> scholarship or a <u>switcher individual scholarship</u> in a prior year and the child continued to attend a private school in subsequent years.
				</li>
				<li>Student was <b>homeschooled in a prior school year and then directly enrolled in a qualified private school.</b> The student is also eligible if the transition occurs within the same academic year, as long as the transition is from homeschooling directly to the qualified private school.</li>
				<li>Student moved to Arizona from <b>out of state, or internationally, and enrolled directly</b> in a qualified private school.</li>
				<li>Student participated in the <b>Empowerment Scholarship Account (ESA) program</b> and did not renew the account or accept the scholarship, in order to accept an STO scholarship.</li>
			</ul>
			<Button variant='contained' color='secondary' startIcon={<AddIcon />} onClick={() => goToApplication('li')} fullWidth disableRipple>
				Start new Low Income application
			</Button>
		</Fragment>
	);
};

const DDApplication = ({ goToApplication }) => (
	<Fragment>
		<ul className='requirements-list bullet-list' style={{ marginTop: 0 }}>
			<li>Student has an IEP, MET or ISP from an Arizona public school (for preschool and grades K-12)</li>
			<li>Student has a 504 plan from an Arizona public school (for grades K-12 only)</li>
			<li>Student was placed at one time in the Arizona foster care system (for grades K-12 only and to be verified by the Arizona Department of Child Safety (DCS))</li>
		</ul>
		<Button variant='contained' color='secondary' startIcon={<AddIcon />} onClick={() => goToApplication('dd')} fullWidth disableRipple>
			Start new Disabled/Displaced application
		</Button>
	</Fragment>
);

const NewApplicationModal = ({ open, onClose, account, goToApplication }) => {
	const [radioValue, setRadioValue] = useState('LIApplication');

	const handleChange = (event) => setRadioValue(event.target.value);

	const components = { LIApplication, DDApplication };
	const Application = components[radioValue];

	const isClosedSubmission = account.siteSettings && account.siteSettings.isClosedSubmission;
	const { previousYear } = account.periodSettings;

	return (
		<Modal open={open} onClose={onClose}>
			<div className='new-application__modal'>
				<h3 className='section-title'>
					Select type of Application <small>(One student per application)</small>
				</h3>
				<FormControl component='fieldset'>
					<RadioGroup name='application_type' value={radioValue} onChange={handleChange}>
						<div className={`radio__container ${radioValue !== 'LIApplication' ? 'inactive' : ''}`}>
							<FormControlLabel /*disabled={isClosedSubmission}*/ value='LIApplication' control={<RadioButton />} label='Low Income Corporate Scholarship Application (A.R.S § 43-1183)' />
						</div>
						<div className={`radio__container ${radioValue !== 'DDApplication' ? 'inactive' : ''}`}>
							<FormControlLabel /*disabled={isClosedSubmission}*/ value='DDApplication' control={<RadioButton />} label='Disabled/Displaced Student Scholarship Application' />
						</div>
					</RadioGroup>
				</FormControl>

				<Application goToApplication={goToApplication} previous={previousYear} />
			</div>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account,
	};
};

export default connect(mapStateToProps)(NewApplicationModal);
