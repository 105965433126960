import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';

import { BorderedPanel, BorderedPanelHeader } from '../BorderedPanel';
import MembersList from './MembersList';
import MemberForm from './MemberForm';

const FinancialInformation = ({ application, account, memberAttachment, onClearAttachments, onSetSelectedParentMember }, ref) => {
	const [members, setMembers] = useState([...application.financialMembers]);
	const [selectedMember, setSelectedMember] = useState(null);
	const [isEdit, setIsEdit] = useState(false);

	const memberFormRef = useRef(null);

	useImperativeHandle(ref, () => ({
		saveFinancialOnSubmit: () => memberFormRef.current.saveFinancialOnSubmit(),
		openFirstFinancial: () => handleEdit({ ...members[0], index: 1 }, true),
	}));

	const handleUpdateMembers = (member) => setMembers([...members, member]);

	const handleUpdateSelectedMember = (member) => {
		let _members = [...members];
		const index = _members.findIndex((d) => d.financialId == member.financialId);
		_members[index] = member;
		setMembers(_members);
	};

	const handleEdit = (member, edit) => {
		setIsEdit(edit);
		setSelectedMember(member);
		onSetSelectedParentMember(member);
	};

	const handleDelete = (financialId) => setMembers(members.filter((d) => d.financialId != financialId));

	const membersCount = members.length;

	return (
		<div className='financial-information__component financial-member__content'>
			<BorderedPanel>
				<BorderedPanelHeader>
					<h4>
						<span>List all members in household</span>
						<span>(Please include ALL guardians, children and other family members living in the home)</span>
					</h4>
				</BorderedPanelHeader>
				<MembersList account={account} application={application} members={members} isEdit={isEdit} onEdit={handleEdit} onDelete={handleDelete} />
				<MemberForm
					ref={memberFormRef}
					account={account}
					members={members}
					editMode={isEdit}
					application={application}
					selectedMember={selectedMember}
					memberAttachment={memberAttachment}
					membersCount={membersCount}
					onEdit={handleEdit}
					onClearAttachments={onClearAttachments}
					onUpdateMembers={handleUpdateMembers}
					onUpdateSelectedMember={handleUpdateSelectedMember}
				/>
			</BorderedPanel>
		</div>
	);
};

FinancialInformation.defaultProps = {
	onSetSelectedParentMember: () => {},
	onClearAttachments: () => {},
};

export default forwardRef(FinancialInformation);
