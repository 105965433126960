// StartApplication/ApplicationTypeSelector.jsx
import React, { useRef } from 'react';
import { Grid, Paper, Typography, Button, Box, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import { Rowing } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.03)',
      boxShadow: theme.shadows[3],
    },
  },
  cardActive: {
    borderColor: '#9bb43b',
    boxShadow: theme.shadows[2],
  },
  cardInactive: {
    opacity: 0.6,
  },
  // Estilos para vista móvil
  mobileCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `2px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.01)',
      boxShadow: theme.shadows[1],
    },
  },
  mobileCardActive: {
    borderColor: '#9bb43b',
    boxShadow: theme.shadows[1],
  },
  mobileCardInactive: {
    opacity: 0.6,
  },
  mobileCardContent: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    textAlign: 'left',
  },
  mobileCardExpanded: {
    maxHeight: '500px',
    transition: 'max-height 0.3s ease-in-out',
    overflow: 'hidden',
  },
  mobileCardCollapsed: {
    maxHeight: '0',
    overflow: 'hidden',
    transition: 'max-height 0.3s ease-in-out',
  },
  descriptionContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  iconMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  iconContainer: {
    backgroundColor: '#9bb43b',
    color: '#fff',
    width: 60,
    height: 60,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  mobileIconContainer: {
    backgroundColor: '#9bb43b',
    color: '#fff',
    width: 40,
    height: 40,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  disabledIconContainer: {
    backgroundColor: '#9bb43b',
  },
  cardTitle: {
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    marginLeft: theme.spacing(2),
    textAlign: 'center',
  },
  cardSubtitle: {
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  cardDescription: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontSize: '0.875rem',
  },
  mobileCardDescription: {
    fontSize: '0.75rem',
  },
  scholarshipText: {
    fontSize: '0.75rem',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  button: {
    marginTop: 'auto',
  },
}));

const ApplicationTypeSelector = ({ selectedType, onSelectType }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const lowIncomeRef = useRef(null);
  const disabledRef = useRef(null);

  const handleSelectLowIncome = () => {
    onSelectType('lowIncome');
    if (isMobile && lowIncomeRef.current) {
      // Scroll to content after a short delay to allow state change
      setTimeout(() => {
        lowIncomeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  };

  const handleSelectDisabled = () => {
    onSelectType('disabled');
    if (isMobile && disabledRef.current) {
      // Scroll to content after a short delay to allow state change
      setTimeout(() => {
        disabledRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    }
  };

  // Renderizado para vista móvil
  if (isMobile) {
    return (
      <div className={classes.root}>
        {/* Low Income Card */}
        <div>
          <Paper
            className={`${classes.mobileCard} ${selectedType === 'lowIncome' ? classes.mobileCardActive : ''} ${selectedType === 'disabled' ? classes.mobileCardInactive : ''}`}
            onClick={handleSelectLowIncome}
          >
            <Box display="flex" alignItems="center">
              {/* <div className={classes.mobileIconContainer}>
                <AttachMoneyIcon />
              </div> */}
              <div className={classes.mobileCardContent}>
                <Typography variant="subtitle1">
                  Low Income
                </Typography>
                <Typography variant="body2" className={classes.scholarshipText}>
                  Scholarship Application
                </Typography>
              </div>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleSelectLowIncome}
            >
              Select
            </Button>
          </Paper>
          
          {/* Low Income Expandable Content */}
          <div 
            ref={lowIncomeRef}
            className={selectedType === 'lowIncome' ? classes.mobileCardExpanded : classes.mobileCardCollapsed}
          >
            <div className={classes.descriptionContainer}>
              <Typography variant="body2" className={classes.mobileCardDescription}>
                For families that meet the income requirements (185% of the reduced-price meal level) and One of the student eligibility
              </Typography>
            </div>
          </div>
        </div>

        {/* Disabled Card */}
        <div>
          <Paper
            className={`${classes.mobileCard} ${selectedType === 'disabled' ? classes.mobileCardActive : ''} ${selectedType === 'lowIncome' ? classes.mobileCardInactive : ''}`}
            onClick={handleSelectDisabled}
          >
            <Box display="flex" alignItems="center">
              {/* <div className={`${classes.mobileIconContainer} ${classes.disabledIconContainer}`}>
                <AccessibilityNewIcon />
              </div> */}
              <div className={classes.mobileCardContent}>
                <Typography variant="subtitle1">
                  Disabled/Displaced
                </Typography>
                <Typography variant="body2" className={classes.scholarshipText}>
                  Scholarship Application
                </Typography>
              </div>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleSelectDisabled}
            >
              Select
            </Button>
          </Paper>
          
          {/* Disabled Expandable Content */}
          <div 
            ref={disabledRef}
            className={selectedType === 'disabled' ? classes.mobileCardExpanded : classes.mobileCardCollapsed}
          >
            <div className={classes.descriptionContainer}>
              <Typography variant="body2" className={classes.mobileCardDescription}>
                For students with an IEP, MET or ISP, with a 504 plan or who have been in the Arizona foster care system
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Renderizado para vista desktop
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={12} sm={6}>
        <Paper
          className={`${classes.card} ${selectedType === 'lowIncome' ? classes.cardActive : ''} ${selectedType === 'disabled' ? classes.cardInactive : ''}`}
          onClick={handleSelectLowIncome}
        >
          <div className={classes.iconMainContainer}>
            {/* <div className={classes.iconContainer}>
              <AttachMoneyIcon fontSize="large" />
            </div> */}
            <Typography variant="h6" className={classes.cardTitle}>
              Low Income <br />Scholarship Application
            </Typography>
          </div>
          <Typography variant="body2" className={classes.cardDescription}>
            For families that meet the income requirements <br />(185% of the reduced-price meal level) and One of the student eligibility
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleSelectLowIncome}
          >
            Select
          </Button>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Paper
          className={`${classes.card} ${selectedType === 'disabled' ? classes.cardActive : ''} ${selectedType === 'lowIncome' ? classes.cardInactive : ''}`}
          onClick={handleSelectDisabled}
        >
          <div className={classes.iconMainContainer}>
            {/* <div className={`${classes.iconContainer} ${classes.disabledIconContainer}`}>
              <AccessibilityNewIcon />
            </div> */}
            <Typography variant="h6" className={classes.cardTitle}>
              Disabled/Displaced <br />Scholarship Application
            </Typography>
          </div>
          <Typography variant="body2" className={classes.cardDescription}>
            For students with an IEP, MET or ISP, with a 504 plan or who have been in the Arizona foster care system
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={handleSelectDisabled}
          >
            Select
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ApplicationTypeSelector;