import React from 'react';

const DDInstructions = ({}) => (
	<div className='form__section'>
		<h3 className='strong-text'>Instructions</h3>
		<p>1 - There are 5 sections, each must be fully completed!</p>
		<p>2 - Use one application per child.</p>
		<p>3 - Submit the completed application with neccesary documents.</p>
	</div>
);

export default DDInstructions;
