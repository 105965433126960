import * as types from '../constants';
import API from '../../config/api';

const loadIncomeTable = (incomes) => ({
	type: types.LOAD_INCOME_TABLE,
	incomes,
});

export const getIncomes = (token) => (dispatch) => {	
	API.GetIncomeTable(token).then((res) => {
		if (res.status !== 201) return;
		dispatch(loadIncomeTable(res.data));
	});
};
