import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { BorderedPanelSection, BorderedPanelSectionHeader } from '../../component/BorderedPanel';
import Alert from '../../component/Alert';

import API from '../../config/api';

import MediaManager from '../../component/MediaManager';

const Student = ({ index, data, selectedStudent, applicationId, version, applicationPeriod, applicationPreviousPeriod, criteria, token, onEdit, onDelete }) => {
	const [expanded, setExpanded] = useState(true);
	// const [expanded, setExpanded] = useState(data.expanded)
	const [isEdit, setIsEdit] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);



	useEffect(() => {
		if (!selectedStudent) return setIsEdit(false);
		setIsEdit(selectedStudent.studentId == data.studentId);
	}, [selectedStudent]);

	const htmlLabel = (label) => {
		return { __html: label };
	};

	const setStudentName = () => {
		const firstname = data.firstName || '';
		const lastname = data.lastName || '';

		return `${firstname} ${lastname}`;
	};

	const setGender = () => {
		let result;
		switch (data.gender) {
			case 'M':
				result = 'Male';
				break;
			case 'F':
				result = 'Female';
				break;
		}
		return result;
	};

	const dateFormat = () => {
		let date = '';
		if (data.dateOfBirth) date = moment(data.dateOfBirth.split('T')[0]).format('MM/DD/YYYY');
		return date;
	};

	const handleExpansionChange = () => setExpanded(!expanded);

	const toggleAlert = () => setOpenAlert(!openAlert);

	const handleEdit = (event) => {
		event.stopPropagation();
		onEdit({ ...data, index }, true);
	};

	const handleRemove = (event) => {
		event.stopPropagation();
		toggleAlert();
	};

	const handleDeleteStudent = () => {
		API.DeleteLIStudent(token, { studentId: data.studentId }).then((res) => {
			onDelete(data.studentId);
		});
	};

	const handleViewFile = (fileKey) => {
		const { studentId } = data;
		const body = { applicationId, studentId, fileKey };

		const viewFile = window.open();

		API.GetDownloadFileUrl(token, body).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	const selectedCriteria = criteria.length > 0 && data.criteriaId ? criteria.filter((d) => d.criteriaId == data.criteriaId)[0].description.replace('{:previousPeriod}',applicationPreviousPeriod) : '';

	const renderMediaUploader = () =>{
		return (
			<Fragment>
			{data.criteriaId == 1 && (			
				<MediaManager title="Attendance Verification" keyName="attendance_verification" readOnly="true" />
			)}
			{data.criteriaId == 3 && (
				<MediaManager title="Preschool Application" keyName="preschool_application" readOnly="true" />
			)}
			{data.criteriaId == 4 && (
				<MediaManager title="Military Order" keyName="military_order_station" readOnly="true" />
			)}
			{(data.criteriaId == 5 || data.criteriaId == 7) && (
				<MediaManager title="School Tuition Organization Award Verification" keyName="award_verification" readOnly="true" />
			)}
			{((data.hasDisability && data.grade == 'PK') || data.criteriaId == 3) && (
				<MediaManager title="IEP or MET" keyName="pk_has_disability" readOnly="true" />
			)}
			{data.criteriaId == 8 && (							
				<MediaManager title="County Homeschool Withdrawal Form" keyName="homeschool_withdrawal" readOnly="true" />		
			)}			
			{data.criteriaId == 9 && (							
				<MediaManager title="Out of State School Enrollment" keyName="out_enrollment" readOnly="true" />		
			)}			
			{data.criteriaId == 10 && (							
				<MediaManager title="ESA Letter" keyName="esa_letter" readOnly="true" />		
			)}			
			</Fragment>
		);
	};

	const renderFileUploader = () =>{
		return (
			<Fragment>
			{data.criteriaId == 1 && data.attendance_verification_key && (
				<FormControl className='attachment' fullWidth>
					<label>Attendance Verification</label>
					<div className='simple-attachment__box'>
						<div className='attachment__box--header'>
							<div className='left-content'>
								<h5>
									<CheckCircleIcon color='primary' />
									File uploaded
								</h5>
								<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile('attendance_verification')} disableRipple>
									View file
								</Button>
							</div>
						</div>
					</div>
				</FormControl>
			)}
			{data.criteriaId == 3 && data.preschool_application_key && (
				<FormControl className='attachment' fullWidth>
					<label>Preschool Application</label>
					<div className='simple-attachment__box'>
						<div className='attachment__box--header'>
							<div className='left-content'>
								<h5>
									<CheckCircleIcon color='primary' />
									File uploaded
								</h5>
								<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile('preschool_application')} disableRipple>
									View file
								</Button>
							</div>
						</div>
					</div>
				</FormControl>
			)}
			{data.criteriaId == 4 && data.military_order_station_key && (
				<FormControl className='attachment' fullWidth>
					<label>Military Order</label>
					<div className='simple-attachment__box'>
						<div className='attachment__box--header'>
							<div className='left-content'>
								<h5>
									<CheckCircleIcon color='primary' />
									File uploaded
								</h5>
								<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile('military_order_station')} disableRipple>
									View file
								</Button>
							</div>
						</div>
					</div>
				</FormControl>
			)}
			{(data.criteriaId == 5 || data.criteriaId == 7) && data.award_verification_key && (
				<FormControl className='attachment' fullWidth>
					<label>School Tuition Organization Award Verification</label>
					<div className='simple-attachment__box'>
						<div className='attachment__box--header'>
							<div className='left-content'>
								<h5>
									<CheckCircleIcon color='primary' />
									File uploaded
								</h5>
								<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile('award_verification')} disableRipple>
									View file
								</Button>
							</div>
						</div>
					</div>
				</FormControl>
			)}
			{((data.hasDisability && data.grade == 'PK') || data.criteriaId == 3) && data.pk_has_disability_key && (
				<FormControl className='attachment' fullWidth>
					<label>IEP or MET</label>
					<div className='simple-attachment__box'>
						<div className='attachment__box--header'>
							<div className='left-content'>
								<h5>
									<CheckCircleIcon color='primary' />
									File uploaded
								</h5>
								<Button color='primary' className='view-button' startIcon={<VisibilityIcon color='primary' />} onClick={() => handleViewFile('pk_has_disability')} disableRipple>
									View file
								</Button>
							</div>
						</div>
					</div>
				</FormControl>
			)}
			</Fragment>
		);
	};

	return (
		<Fragment>
			<ExpansionPanel className={`transparent ${isEdit ? 'selected-student' : ''}`} expanded={expanded} onChange={handleExpansionChange}>
				<ExpansionPanelSummary id={`student-panel-${index}`} expandIcon={<ExpandMoreIcon />}>
					<BorderedPanelSectionHeader showButtons disableButtons={false} onEdit={(e) => handleEdit(e)} onRemove={(e) => handleRemove(e)}>
						{/* <h4>Student {index} - <span className="text-transform-none">{`${data.firstName} ${data.lastName}`}</span></h4> */}
						<h4>
							<span className='text-transform-none'>{setStudentName()}</span>
						</h4>
					</BorderedPanelSectionHeader>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<div className='box__summary'>
						<div className='student-information'>
							<p>
								<span>Gender:</span> {setGender()}
							</p>
							<p>
								<span>Date of Birth:</span> {dateFormat()}
							</p>
							<p>
								<span>Grade {applicationPeriod}:</span> {data.grade}
							</p>
							{data.grade == 'PK' && (
								<p>
									<span>Has Disability:</span> {data.hasDisability ? 'Yes' : 'No'}
								</p>
							)}
							<p>
								<span>Private School in {applicationPeriod}:</span> {data.school}
							</p>
							<p>
								<span>City:</span> {data.city}
							</p>
							<p>
								<span>Did student attend a different school last year?</span> {data.isLastYearDifferentSchool ? 'Yes' : 'No'}
							</p>
							{data.isLastYearDifferentSchool && (
								<Fragment>
									<p>
										<span>Previous School Name: </span> {data.lastYearSchool}
									</p>
									<p>
										<span>Previous School City: </span> {data.lastYearCity}
									</p>
									<p>
										<span>Previous School State: </span> {data.lastYearState}
									</p>
								</Fragment>
							)}
							<p>
								<span>Student spotlight?</span> {data.isSpotlightStudent ? 'Yes' : 'No'}
							</p>
						</div>
						<div className='student-skills'>
							<p>
								<span>Leadership and Extracurricular Activities:</span> {data.achievements}
							</p>
							<p>
								<span>Academic Merit:</span> {data.merits}
							</p>
							<p>
								<span>Additional Personal or Economic Factors:</span>
								{data.involvements}
							</p>
							<p>
								<span>Criteria Eligibility:</span>
								{data.criteriaId != 6 ? <span dangerouslySetInnerHTML={htmlLabel(selectedCriteria)} /> : `${selectedCriteria} School: ${data.criteria_6_school}, City: ${data.criteria_6_city}`}
							</p>

							{version >= 30 && !isEdit ? renderMediaUploader():renderFileUploader()}							
							
						</div>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<Alert open={openAlert} title='Delete Student' text='Are you sure you want to delete this student?' confirmButtonText='Delete' onConfirm={handleDeleteStudent} onCancel={toggleAlert} />
		</Fragment>
	);
};

const StudentsList = ({ account, students, selectedStudent, criteria, onEdit, onDelete, application }) => {
	const { tokenAuth } = account,
		{ applicationId, period, previousPeriod, version } = application;

	return (
		<div className='students-list'>
			{students.length > 0 && (
				<BorderedPanelSection>
					{students.map((d, i) => (
						<Student
							key={d.studentId}
							index={++i}
							data={d}
							selectedStudent={selectedStudent}
							applicationId={applicationId}
							version={version}
							applicationPeriod={period}
							applicationPreviousPeriod={previousPeriod}
							criteria={criteria}
							token={tokenAuth}
							onEdit={onEdit}
							onDelete={onDelete}
						/>
					))}
				</BorderedPanelSection>
			)}
		</div>
	);
};

export default StudentsList;
