import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import FinancialInformationComponent from '../../component/FinancialInformation';
import Loader from '../../component/Loader';

import API from '../../config/api';

const FinancialInformation = ({ account }) => {
	const [loading, setLoading] = useState(true);
	const [application, setApplication] = useState({});

	useEffect(() => getMembers(), []);

	const getMembers = () => {
		API.GetFinancialMembers(account.tokenAuth).then((response) => {
			let financialMembers = [];
			if (response.status == 201) financialMembers = response.data;
			setApplication({ financialMembers });
			setLoading(false);
		});
	};

	return (
		<div className='profile-page__financial-information--tab'>
			<h3 className='section-title'>Profile: Financial Information</h3>
			<Grid container>
				<Grid item xs={12} lg={8}>
					<div className='financial-panel__container'>
						{!loading ? <FinancialInformationComponent application={application} account={account} onSetSelectedParentMember={() => {}} onClearAttachments={() => {}} /> : <Loader loading />}
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default FinancialInformation;
