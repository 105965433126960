import React, { Fragment } from 'react';

import StudentInformationView from './ApplicationReadOnlyComponent/StudentInformationView';
import FinancialInformationView from './ApplicationReadOnlyComponent/FinancialInformationView';
import LIAgreementsView from './ApplicationReadOnlyComponent/LIAgreementsView';
import ApplicationNotFound from './ApplicationNotFound';

const LIView = (props) => {

	return (
		<Fragment>
			{!props.error ? (
				<Fragment>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>1. Student Information</h3>
						</div>
						<div className='form__section--body'>
							<StudentInformationView
								applicationId={props.application.applicationId}								
								students={props.application.students}
								applicationType={props.application.applicationType}								
								applicationPeriod={props.application.period}
								applicationPreviousPeriod={props.application.previousPeriod}
								token={props.account.tokenAuth}
								version={props.application.version||10}								
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>2. Household Members and Financial Information</h3>
						</div>
						<div className='form__section--body'>
							<FinancialInformationView
								applicationType={props.application.applicationType}
								financialInformation={props.application.financialMembers}
								account={props.account}
								token={props.account.tokenAuth}
								files={props.application.files}
								id={props.application.applicationId}
								previousYear={props.application.previousPeriod.split('/')[0]}
								version={props.application.version||10}								
							/>
						</div>
					</div>
					<div className='form__section'>
						<div className='form__section--header'>
							<h3>3. Agreement Statements</h3>
						</div>
						<div className='form__section--body'>
							<LIAgreementsView
								submittedAgreements={props.application.submittedAgreements}
								agreements={props.application.agreements}
								blueprintSubmitted={props.application.blueprintSubmitted}
								isAgreeStatements={props.application.isAgreeStatements}
							/>
						</div>
					</div>
				</Fragment>
			) : (
				<ApplicationNotFound message={props.error} />
			)}
		</Fragment>
	);
};

export default LIView;
