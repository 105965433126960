import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Box, Button, Typography, IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl, FormHelperText } from '@material-ui/core';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import LayoutLogin from '../../component/partials/LayoutLogin';
import MessageBox from '../../component/partials/Alerts/MessageBox';
import Tooltip from '../../component/Tooltip';

import Api from '../../config/api';
import { REGEX } from '../../utils/constants';
import Loader from '../../component/Loader';
import { isEmpty, isEqual } from 'lodash';
import Alert from '@material-ui/lab/Alert';

const ResetPassword = (props) => {
	const [getError, setError] = useState(false);
	const [showMessage, setShowMessage] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [specialCharacter, setSpecialCharacter] = useState("~!@#$%^&*_-+=`|(){}[]:;'<>,.?/`");
	const [loading, setLoading] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [accountExpiration, setAccountExpiration] = useState(false);
	const [isPhoneRecoveryCode, setIsPhoneRecoveryCode] = useState(false);
	const [getForm, setForm] = useState({
		password: '',
		repassword: '',
	});

	const [values, setValues] = React.useState({
		amount: '',
		password: '',
		weight: '',
		weightRange: '',
		showPassword: false,
	});

	useEffect(() => {
		CheckExpirationDate();
	}, []);

	const validate = async () => {
		setLoading(true);

		if (getForm.password.length > 32) {
			setError(true);
			setErrorMessage('Password must be 8 - 32 characters long');
			setLoading(false);
			return;
		}

		if (isEmpty(getForm.password)) {
			setError(true);
			setErrorMessage('Password is required');
			setLoading(false);
			return;
		}
		if (!isEqual(getForm.password, getForm.repassword)) {
			setError(true);
			setErrorMessage('The passwords are different');
			setLoading(false);
			return;
		}
		if (!REGEX.passwordRegEx.test(getForm.password)) {
			setError(true);
			setErrorMessage('Password criteria has not been met. Hover over question mark for requirements.');
			setLoading(false);
			return;
		}

		await Api.ResetPassword({ code: props.match.params.code, password: getForm.password, repassword: getForm.repassword })
			.then((res) => {
				if (res.status !== 201) {
					setLoading(false);
					setAlertMessage(res.message);
					return;
				}
				setAccountExpiration(false);
				setTimeout(() => {
					setLoading(false);
					setAlertMessage('Password is updated successfuly');
					setShowMessage(true);
				}, 500);
			})
			.catch((err) => {
				console.log('Error: ', err);
				setLoading(false);
			});
	};

	const CheckExpirationDate = async () => {
		if (props.match.params.code) {
			const account = await Api.CheckExpirationDate(props.match.params.code)
				.then((res) => res)
				.catch((err) => console.log(err));
			if (!account.data) {
				const isPhoneCode = REGEX.recoveryCodeRegEx.test(props.match.params.code);
				if (isPhoneCode) {
					setIsPhoneRecoveryCode(true);
				}
				setAccountExpiration(true);
				setShowMessage(true);
			}
		}
	};

	const onChangeForm = (e) => {
		const { name, value } = e.target || e;
		setAlertMessage('');

		if(name === 'password' || name === 'repassword') {			
			setForm({ ...getForm, [name]: value.replace(/\s/g, '') });
		}else{
			setForm({ ...getForm, [name]: value });
		}		

	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<LayoutLogin title='Reset Password'>
			<Grid item xs={12}>
				<div className='reset_container'>
					<div className='page__password--flex'>
						{!showMessage ? (
							<Fragment>
								<form className='page__password--form' noValidate autoComplete='off'>
									<span style={{ paddingTop: 10 }} className='reset_container_box_title'>Create New Password</span>
									<div className='page__password--help'>
										<FormControl className='login__form--password' variant='outlined' size='medium'>
											<InputLabel htmlFor='outlined-adornment-password'>New Password</InputLabel>
											<OutlinedInput
												id='outlined-adornment-password'
												name='password'
												label='New Password'
												size='small'
												type={values.showPassword ? 'text' : 'password'}
												onChange={onChangeForm}
												value={getForm.password}
												endAdornment={
													<InputAdornment position='end'>
														<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
															{values.showPassword ? <Visibility /> : <VisibilityOff />}
														</IconButton>
													</InputAdornment>
												}
												error={getError}
											/>
											<FormHelperText id='my-helper-text' style={{ color: 'red' }}>
												{setError ? errorMessage : null}
											</FormHelperText>
										</FormControl>
										<Tooltip
											className='tooltip_space'
											tooltipStyle={{
												backgroundColor: '#ffffff',
												color: 'black',
												maxWidth: 280,
												fontSize: 12,
												border: '1px solid #9bb43b',
												padding: 10,
											}}
											title={
												<Fragment>
													<Typography variant='body1'>Criteria:</Typography>
													<br />
													<Typography variant='caption'>
														Must be between 8 - 32 characters long <br />
														Alphabet uppercase letter (A-Z) include at least 1<br /> Alphabet lowercase letter (a-z) include at least 1<br /> Decimal digit number (0-9) include at least 1 <br />
														Special characters such as <i>{specialCharacter}</i>
														<br />
														include at least 1{' '}
													</Typography>
												</Fragment>
											}
										/>
									</div>
									<span style={{ paddingTop: 20 }}></span>
									<FormControl className='login__form--password' variant='outlined' size='medium'>
										<InputLabel htmlFor='outlined-adornment-password'>Confirm Password</InputLabel>
										<OutlinedInput
											id='outlined-adornment-password'
											name='repassword'
											label='Confirm Password'
											size='small'
											type={values.showPassword ? 'text' : 'password'}
											onChange={onChangeForm}
											value={getForm.repassword}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
														{values.showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											error={getError}
										/>
									</FormControl>
									{!!alertMessage && (
										<span style={{ marginTop: 20 }}>
											<Alert onClose={() => setAlertMessage('')} severity='success'>
												{alertMessage}
											</Alert>
										</span>
									)}
								</form>
								<div className='password__button--box' display='flex'>
									<Box className='reset_button'>
										{!loading ? (
											<Button variant='contained' onClick={validate}>
												SUBMIT
											</Button>
										) : (
											<div style={{ marginTop: 15 }}>
												<Loader loading={true} />
											</div>
										)}
									</Box>
								</div>
							</Fragment>
						) : accountExpiration ? (
							<MessageBox
								title='We cannot reset your account password'
								subtitle={
									<Fragment>
										<p style={{ textAlign: 'left' }}>The {isPhoneRecoveryCode?"code":"link"} you received by {isPhoneRecoveryCode?"Phone Number":"email"} has expired or is invalid.</p>
										<p style={{ textAlign: 'left' }}>If the {isPhoneRecoveryCode?"code":"link"} expired, you need to start the reset password process again.</p>
									</Fragment>
								}
							/>
						) : (
							<MessageBox />
						)}
					</div>
				</div>
			</Grid>
		</LayoutLogin>
	);
};

ResetPassword.propTypes = {};

export default ResetPassword;
