import React, { useState, Fragment } from 'react';
import CurrencyFormat from 'react-currency-format';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { BorderedPanelSection, BorderedPanelSectionHeader } from '../../BorderedPanel';
import Alert from '../../../component/Alert';

import API from '../../../config/api';
import { REGEX } from '../../../utils/constants';

const { currencyRegEx } = REGEX;

const Member = ({ index, data, isEdit, accountId, applicationId, token, onEdit, onDelete }) => {
	const [expanded, setExpanded] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);

	const clearCurrency = () => {
		const yearlyEarnings = Number(data.yearlyEarnings.replace(currencyRegEx, ''));
		const yearlyWelfare = Number(data.yearlyWelfare.replace(currencyRegEx, ''));
		const yearlyPensions = Number(data.yearlyPensions.replace(currencyRegEx, ''));
		const yearlyOthers = Number(data.yearlyOthers.replace(currencyRegEx, ''));

		return { yearlyEarnings, yearlyWelfare, yearlyPensions, yearlyOthers };
	};

	const totalIncomes = () => {
		const amounts = clearCurrency();
		const total = Object.values(amounts).reduce((a, b) => a + b);

		return <CurrencyFormat value={total} displayType='text' prefix='$' thousandSeparator fixedDecimalScale decimalScale={2} />;
	};

	const handleExpansionChange = () => setExpanded(!expanded);

	const toggleAlert = () => setOpenAlert(!openAlert);

	const handleEdit = (event) => {
		event.stopPropagation();
		onEdit({ ...data, index }, true);
	};

	const handleRemove = (event) => {
		event.stopPropagation();
		toggleAlert();
	};

	const handleDeleteMember = () => {
		let body = { financialId: data.financialId };

		applicationId ? (body = { ...body, applicationId, accountId: null }) : (body = { ...body, accountId, applicationId: null });

		API.DeleteApplicationFinancial(token, body).then((res) => {
			onDelete(data.financialId);
		});
	};

	const types = { C: 'Child', G: 'Guardian', O: 'Other' };
	const memberType = types[data.type];

	return (
		<Fragment>
			<ExpansionPanel className='transparent' expanded={expanded} onChange={handleExpansionChange}>
				<ExpansionPanelSummary id='member-panel-1' expandIcon={<ExpandMoreIcon />}>
					<BorderedPanelSectionHeader showButtons disableButtons={isEdit} disableRemove={index === 1} onEdit={(e) => handleEdit(e)} onRemove={(e) => handleRemove(e)}>
						<h4>
							{`Member #${index} - `}
							<span className='text-transform-none'>
								{`${data.firstName} ${data.lastName}`} <small>({memberType})</small>
							</span>
						</h4>
					</BorderedPanelSectionHeader>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<div className='member-incomes'>
						<p>
							Type of Member: <span>{memberType}</span>
						</p>
						<p>
							Yearly Gross Earning from work: <span>{data.yearlyEarnings}</span>
						</p>
						<p>
							Yearly Welfare, Child Support, Alimony: <span>{data.yearlyWelfare}</span>
						</p>
						<p>
							Yearly Pensions, Retirement, Social Security: <span>{data.yearlyPensions}</span>
						</p>
						<p>
							Yearly Other Income: <span>{data.yearlyOthers}</span>
						</p>
					</div>
					<div className='total-income'>
						<p>
							Yearly Total Gross Income for family member: <span>{totalIncomes()}</span>
						</p>
					</div>
				</ExpansionPanelDetails>
			</ExpansionPanel>

			<Alert open={openAlert} title='Delete Member' text='Are you sure you want to delete this member?' confirmButtonText='Delete' onConfirm={handleDeleteMember} onCancel={toggleAlert} />
		</Fragment>
	);
};

const MembersList = ({ account, application, members, isEdit, onEdit, onDelete }) => {
	const { accountId, tokenAuth } = account,
		{ applicationId } = application;

	return (
		<Fragment>
			{members.length > 0 && (
				<BorderedPanelSection>
					{members.map((d, i) => (
						<Member key={d.financialId} index={++i} data={d} isEdit={isEdit} accountId={accountId} applicationId={applicationId} token={tokenAuth} onEdit={onEdit} onDelete={onDelete} />
					))}
				</BorderedPanelSection>
			)}
		</Fragment>
	);
};

export default MembersList;
