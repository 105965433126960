import React, { useState, Fragment, useEffect } from 'react';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import IncomeFileUploader from './IncomeFileUploader';
import api from '../../../config/api';
import ApplicationNotFound from '../../../views/Application/ApplicationNotFound';

import MediaManager from '../../../component/MediaManager';

const FILEKEYS = {
	FEDERALINCOMETAX: 'federal_income_tax',
	FEDERALINCOMETAX2: 'federal_income_tax2',
	FEDERALINCOMETAX3: 'federal_income_tax3',
	TAXEXTENSION: 'tax_extension',
	PROOFINCOME: 'proof_of_income',
	OTHERPROOFINCOME: 'other_proof_of_income',
	OTHERFILE: 'other_file',
	EXTRAFILE1: 'extra_file1',
	EXTRAFILE2: 'extra_file2',
	FOSTERSTATUS: 'foster_status',
};

const MemberFileUploader = ({ fileProps }) => {
	if (!Array.isArray(fileProps.fileKey) || !fileProps.fileKey.length) {
		return (
			<div className='member-files__file'>
				<IncomeFileUploader {...fileProps} />
			</div>
		);
	} else {
		return (
			<>
				{fileProps.fileKey.map((key, i) => (
					<div key={i} className='member-files__file'>
						<IncomeFileUploader {...{ ...fileProps, fileKey: key }} />
					</div>
				))}
			</>
		);
	}
};

const QuestionFileV2 = ({ value, question, examples, fileInfo, fileInfoNote, fileProps, onChange = () => {} }) => {
	const [answer, setAnswer] = useState({});

	useEffect(() => {
		const newValue = {};
		if (value !== null) {
			if (value) newValue.yes = true;
			else newValue.no = true;
		}
		setAnswer(newValue);
	}, [value]);

	const handleSetAnswer = (e) => {
		const newValue = { [e.target.name]: e.target.checked };
		setAnswer(newValue);
		onChange(newValue.yes ? true : newValue.no ? false : null);
	};

	return (
		<Fragment>
			<div className='member-files__question'>
				<p className='question'>{question}</p>
				{!!examples && <span className='question-examples'>{examples}</span>}
				<div className='answers'>
					<FormControlLabel control={<Checkbox color='primary' name='yes' onChange={handleSetAnswer} checked={!!answer.yes} />} label='Yes' />
					<FormControlLabel control={<Checkbox color='primary' name='no' onChange={handleSetAnswer} checked={!!answer.no} />} label='No' />
				</div>
			</div>
			{answer.yes && !answer.no && (
				<Fragment>
					<div className='more-info'>
						{fileInfo}
						<br />
						{!!fileInfoNote && <span>{fileInfoNote}</span>}
					</div>
					<MemberFileUploader fileProps={fileProps}></MemberFileUploader>
				</Fragment>
			)}
		</Fragment>
	);
};

const QuestionFileV3 = ({ value, question, examples, fileInfo, fileInfoNote, fileProps, onChange = () => {} }) => {
	const [answer, setAnswer] = useState({});

	useEffect(() => {
		const newValue = {};
		if (value !== null) {
			if (value) newValue.yes = true;
			else newValue.no = true;
		}
		setAnswer(newValue);
	}, [value]);

	const handleSetAnswer = (e) => {
		const newValue = { [e.target.name]: e.target.checked };
		setAnswer(newValue);
		onChange(newValue.yes ? true : newValue.no ? false : null);
	};

	let keyName = '';
	if (!Array.isArray(fileProps.fileKey) || !fileProps.fileKey.length) {
		keyName = fileProps.fileKey;
	}else{
		keyName = fileProps.fileKey[0];
	}

	return (
		<Fragment>
			<div className='member-files__question'>
				<p className='question'>{question}</p>
				{!!examples && <span className='question-examples'>{examples}</span>}
				<div className='answers'>
					<FormControlLabel control={<Checkbox color='primary' name='yes' onChange={handleSetAnswer} checked={!!answer.yes} />} label='Yes' />
					<FormControlLabel control={<Checkbox color='primary' name='no' onChange={handleSetAnswer} checked={!!answer.no} />} label='No' />
				</div>
				{answer.yes && !answer.no && (
					<MediaManager title={fileInfo} info={fileInfoNote} keyName={keyName} />
				)}
			</div>
			
		</Fragment>
	);
};

const QuestionFile = (props) => {
	if(props.version < 30){
		return QuestionFileV2(props);
	}else{
		return QuestionFileV3(props);
	}
};

const Question = ({ value, question, onChange = () => {} }) => {
	const [answer, setAnswer] = useState({});

	useEffect(() => {
		const newValue = {};
		if (value !== null) {
			if (value) newValue.yes = true;
			else newValue.no = true;
		}
		setAnswer(newValue);
	}, [value]);

	const handleSetAnswer = (e) => {
		const newValue = { [e.target.name]: e.target.checked };
		setAnswer(newValue);
		onChange(newValue.yes ? true : newValue.no ? false : null);
	};

	return (
		<Fragment>
			<div className='member-files__question'>
				<p className='question'>{question}</p>	
				<div className='answers'>
					<FormControlLabel control={<Checkbox color='primary' name='yes' onChange={handleSetAnswer} checked={!!answer.yes} />} label='Yes' />
					<FormControlLabel control={<Checkbox color='primary' name='no' onChange={handleSetAnswer} checked={!!answer.no} />} label='No' />
				</div>
			</div>
			
		</Fragment>
	);
};

const MemberFiles = ({ account, application, members, onSetAttachment }) => {
	const [questionStates, setQuestionStates] = useState({
		taxReturn: !application.financialQuestions ? null : application.financialQuestions.q1_tax_return,
		taxExtension: !application.financialQuestions ? null : application.financialQuestions.q2_tax_extension,
		proofIncome: !application.financialQuestions ? null : application.financialQuestions.q3_proof_income,
		otherProofIncome: !application.financialQuestions ? null : application.financialQuestions.q4_other_proof_income,
		otherFile: !application.financialQuestions ? null : application.financialQuestions.q5_other_file,
		fosterStatus: !application.financialQuestions ? null : application.financialQuestions.q6_foster_status,
	});
	const { tokenAuth } = account,
		{ applicationId, previousPeriod, applicationType } = application;

	const previous = previousPeriod == null ? '' : parseInt(previousPeriod.split('/')[0]);
	const showOtherSources =
		questionStates.taxReturn || (questionStates.taxExtension !== null && questionStates.proofIncome !== null) || (questionStates.taxExtension == true && questionStates.proofIncome == null);

	const handleQuestionFileChange = (name) => (values) => {
		const newValues = { ...questionStates, [name]: values };
		let { taxReturn, taxExtension, proofIncome, otherProofIncome, otherFile, fosterStatus } = newValues;
		taxExtension = taxReturn !== false ? null : taxExtension;
		proofIncome = taxReturn !== false ? null : proofIncome;
		otherProofIncome = (taxReturn || taxExtension || proofIncome) || (taxReturn!== null && taxExtension !== null && proofIncome !== null) ? otherProofIncome : null;
		otherFile = (taxReturn || taxExtension || proofIncome) || (taxReturn!== null && taxExtension !== null && proofIncome !== null) ? otherFile : null;

		api.UpdateFinancialQuestions(tokenAuth, {
			applicationId,
			q1_tax_return: taxReturn,
			q2_tax_extension: taxExtension,
			q3_proof_income: proofIncome,
			q4_other_proof_income: otherProofIncome,
			q5_other_file: otherFile,
			q6_foster_status: fosterStatus,
		});

		setQuestionStates({ taxReturn, taxExtension, proofIncome, otherProofIncome, otherFile, fosterStatus });
	};

	const handleViewFile = (fileKey) => {
		const { financialId } = members[0];
		const data = { applicationId, financialId, fileKey };

		const viewFile = window.open();

		api.GetDownloadFileUrl(tokenAuth, data).then((res) => {
			viewFile.location.href = res.data;
			viewFile.focus();
		});
	};

	const handleDeleteFile = (fileKey) => {
		const { financialId } = members[0];
		const data = { applicationId, financialId, fileKey };
		api.DeleteFile(tokenAuth, data);
	};

	const filePropsBase = { applicationId, selectedMember: members[0], token: tokenAuth, onSetAttachment, onViewFile: handleViewFile, onDeleteFile: handleDeleteFile };

	//console.log(filePropsBase);

	return (
		<div className='member-files'>
			<div className='member-files__main-question'>
				<h4>PROOF OF INCOME</h4>
				<QuestionFile
					value={questionStates.taxReturn}
					question={`Did you file a TAX RETURN for ${previous}?`}
					fileInfo='Please upload pages 1 & 2 of IRS FORM 1040'
					fileInfoNote={`If you claimed more than 4 dependents on your ${previous} taxes, please upload the Federal Supporting Statement form 1040-Dependents.`}
					fileProps={{ ...filePropsBase, fileKey: [FILEKEYS.FEDERALINCOMETAX,FILEKEYS.FEDERALINCOMETAX2,FILEKEYS.FEDERALINCOMETAX3] }}
					version={application.version}
					onChange={handleQuestionFileChange('taxReturn')}					
				/>

				{questionStates.taxReturn === false && (
					<>
						<QuestionFile
							value={questionStates.taxExtension}
							question={`Did you file a TAX EXTENSION for ${previous}?`}
							fileInfo='Please upload your Tax Extension Notice and pages 1 & 2 of the previous year IRS FORM 1040'
							fileInfoNote='Tax extension return filing deadline is October 15th.'
							fileProps={{ ...filePropsBase, fileKey: FILEKEYS.TAXEXTENSION }}
							version={application.version}
							onChange={handleQuestionFileChange('taxExtension')}
						/>
						{questionStates.taxExtension === false && (
							<QuestionFile
								value={questionStates.proofIncome}
								question={`Did you NOT file a TAX RETURN for ${previous} because you were not required to file taxes?`}
								fileInfo='Please upload a copy of other proof of income'
								fileInfoNote='(most recent paystub(s), W-2 from employer, etc.) for ALL household members receiving income.'
								fileProps={{ ...filePropsBase, fileKey: FILEKEYS.PROOFINCOME }}
								version={application.version}
								onChange={handleQuestionFileChange('proofIncome')}
							/>
						)}
					</>
				)}
			</div>
			{showOtherSources && (
				<div className='member-files__other-questions'>
					<h4>Other sources of income</h4>
					<QuestionFile
						value={questionStates.otherProofIncome}
						question='Does your household receive OTHER sources of income?'
						examples='Examples: Nutrition Assistance (Food Stamp Program), Cash Assistance, AHCCCS, Social Security, Disability, Adoption Subsidy, Distribution from IRA, Pension, Annuity, Retirement, Trust, etc.)'
						fileInfo='Please upload a copy of proof of income for ALL household members receiving income from any of the sources listed above.'
						fileProps={{ ...filePropsBase, fileKey: FILEKEYS.OTHERPROOFINCOME }}
						version={application.version}
						onChange={handleQuestionFileChange('otherProofIncome')}
					/>
					<QuestionFile
						value={questionStates.otherFile}
						question='Have you had any recent changes in your household income?'
						examples='Example: job loss, furlough, reduced hours, etc.'
						fileInfo='Please, upload a copy of ANY of the following'
						fileInfoNote='notice from employer of layoff/furlough/reduced hours, unemployment (if receiving), current paystub(s) reflecting reduced hours or any additional documentation you can provide.'
						fileProps={{ ...filePropsBase, fileKey: [FILEKEYS.OTHERFILE, FILEKEYS.EXTRAFILE1, FILEKEYS.EXTRAFILE2] }}
						version={application.version}
						onChange={handleQuestionFileChange('otherFile')}
					/>
				</div>
			)}
			<div className='member-files__other-questions last'>
				<h4>FOSTER STATUS</h4>
				{applicationType == 'LI'? 
				<QuestionFile
					value={questionStates.fosterStatus}
					question='Is the student in ACTIVE foster care through DCS?'
					fileInfo='Please provide documentation showing Active foster status.'
					fileProps={{ ...filePropsBase, fileKey: FILEKEYS.FOSTERSTATUS }}
					version={application.version}
					onChange={handleQuestionFileChange('fosterStatus')}
				/>
				:
				<Question
					value={questionStates.fosterStatus}
					question='Is the student in ACTIVE foster care through DCS?'
					onChange={handleQuestionFileChange('fosterStatus')}
				/>
				}
			</div> 
			{(questionStates.taxReturn || questionStates.taxReturn === false) && (
				<div className='member-files__confidential'>
					All information is held strictly confidential. Arizona Leadership Foundation will not share or disseminate this information in any way with any other organization. Please mark out Social
					Security Numbers
				</div>
			)}
		</div>
	);
};

export default MemberFiles;
