import React from 'react';
import { FormControl } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

const DDAgreementsView = (props) => (
	<div className='section__student-agreements-statements'>
		<FormControl component='fieldset' style={{ color: '#707070' }}>
			<div className='agreements-statements-box'>
				<h3 className='strong-text'>I agree to the following:</h3>
				<ul className='agreements__list bullet-list'>
					<li>
						Financial aid awarded to the applicant (s) must be used, as allowed by Arizona law, solely for tuition expenses at a qualified private school of the applicant’s choice. Any scholarship
						amount above the student’s tuition balance, must be returned by the private school, to the Arizona Leadership Foundation for reallocation.
					</li>
					<li>The sum of a student’s D/D scholarships is limited to 90% of state aid or cost of tuition, whichever is less.</li>
					<li>
						A student that receives ESA (Empowerment Scholarship Account) money from the Arizona Department of Education cannot receive funds from any school tuition organization for the same academic
						period. The applicant is not receiving ESA money for the {props.applicationPeriod} school year.
					</li>
					<li>Arizona Leadership Foundation has absolute and sole discretion in awarding financial aid.</li>
					<li>
						Financial aid is distributed without regard to ethnicity, sex, or handicap. Financial aid is only for the current academic year and continuing aid is based upon the applicant’s eligibility
						and funds available for disbursement.
					</li>
					<li>The applicant meets the criteria set forth in Section 2.</li>
				</ul>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 20, paddingTop: 20 }}>
					<span style={{ marginLeft: 6 }}>{props.isAgreeStatements ? <CheckIcon /> : <CloseIcon />}</span>
					<p style={{ paddingLeft: 25 }}>I agree with the statements above</p>
				</div>
			</div>
		</FormControl>
	</div>
);

export default DDAgreementsView;
