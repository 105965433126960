import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const NotAllowed = () => {
	return (
		<div className='allowed-section' style={{ backgroundColor: '#b9b9b9' }}>
			<div class='center'>
				<table border='0' cellspacing='0' cellpadding='0' width='100%' height='100%'>
					<tr>
						<td colspan='5'>
							<h1 align='center'>Please upgrade your browser to use Arizona Leader</h1>
							<img class='ck' src='../../../assets/img/logo-allowed.png' alt='Arizona Leader' width='120' />
							<p>
								We built Arizona Leader using the latest technology. This makes Arizona Leader faster and easier to use. Unfortunately, your browser doesn't support those technologies. Download one of
								these great browsers and you'll be on your way:
							</p>
						</td>
					</tr>
					<tr>
						<td>
							<a href='#'>
								<img src='../../../assets/img/chrome.jpg' alt='Google Chrome' width='80' height='80' />
							</a>
							<p>Google Chrome</p>
						</td>
						<td>
							<a href='#'>
								<img src='../../../assets/img/safari.jpg' alt='Apple Safari' width='80' height='80' />
							</a>
							<p>Apple Safari</p>
						</td>
						<td>
							<a href='#'>
								<img src='../../../assets/img/edge.jpg' alt='Microsoft Edge' width='80' height='80' />
							</a>
							<p>Microsoft Edge</p>
						</td>
						<td>
							<a href='#'>
								<img src='../../../assets/img/firefox.jpg' alt='Mozilla Firefox' width='80' height='80' />
							</a>
							<p align='center'>Mozilla Firefox</p>
						</td>
						<td>
							<a href='#'>
								<img src='../../../assets/img/opera.jpg' alt='Browser Opera' width='80' height='80' />
							</a>
							<p align='center'>Browser Opera</p>
						</td>
					</tr>
				</table>
			</div>
		</div>
	);
};

export default NotAllowed;
