import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, FormControl, FormControlLabel, TextField, Checkbox, FormGroup, CircularProgress } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';

import B2BlueprintFile from './Attachments/B2BlueprintFile';
import B3BlueprintFile from './Attachments/B3BlueprintFile';

import API from '../../config/api';

import MediaManager from '../../component/MediaManager';

const DDPrerequisites = ({ application, account, lock }) => {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [formError, setFormError] = useState(null);
	const [formState, setFormState] = useState({
		a1: false,
		a2: false,
		a3: false,
		b1: null,
		b2: null,
		b31: null,
		b32: null,
		b311_primaryDisability: '',
		b321_ssn: '',
		b322_previousNamesChild: '',
	});

	const autosaveRef = useRef(null);

	// handle fill form if edit mode
	useEffect(() => {
		const { prerequisites } = application;
		if (!prerequisites) return;
		setFormState(prerequisites);
	}, []);

	// handle auto save
	useEffect(() => {
		const timeout = setTimeout(() => autosaveRef.current && updatePrerequisites(), 1500);
		return () => clearTimeout(timeout);
	}, [formState]);

	// handle success
	useEffect(() => {
		if (success) setTimeout(() => setSuccess(false), 1500);
	}, [success]);

	const handleTextFieldChange = (event) => {
		const { name, value } = event.target;
		setFormState({ ...formState, [name]: value });
		autosaveRef.current = true;
	};

	const handleNumericFieldChange = (event) => {
		const re = /^[0-9\b]+$/;
		const { name, value } = event.target;
		if (value === '' || re.test(value)) {
			setFormState({ ...formState, [name]: value });
			autosaveRef.current = true;
		}
	};

	const handleCheckboxChange = (event) => {
		const { name, value, checked } = event.target;
		let bool = checked;
		if (value) bool = value == 'yes';

		if (name == 'b1') {
			if (bool) {
				setFormState({ ...formState, [name]: bool, b31: !bool, b32: !bool });
			} else {
				setFormState({ ...formState, [name]: bool, b2: null, b31: bool, b32: bool });
			}
		} else if (name == 'b2' && !bool) {
			setFormState({ ...formState, [name]: bool, b31: null, b32: null });
		} else {
			setFormState({ ...formState, [name]: bool });
		}

		autosaveRef.current = true;
	};

	const handleCheckboxChangeB3 = (event) => {
		const { name, value, checked } = event.target;
		setFormState({ ...formState, [name]: checked });
		autosaveRef.current = true;
	};

	const updatePrerequisites = () => {
		const { applicationId } = application;

		setFormError(null);
		setLoading(true);

		const data = { ...formState, applicationId };

		API.UpdateDDPrerequisites(account.tokenAuth, data).then((res) => {
			setLoading(false);
			if (res.status !== 201) setFormError(res.message);
			else setSuccess(true);
		});
	};

	const { applicationId, files, version } = application,
		{ tokenAuth } = account;

	return (
		<div className='form__section'>
			<div className='form__section--header'>
				<h3>2. Prerequisites</h3>
				{success && !loading && (
					<Button size='small' color='primary' endIcon={<SaveIcon />} className='text-transform-none' disableRipple>
						Auto-saved
					</Button>
				)}
				{loading && <CircularProgress size={15} color='primary' />}
			</div>

			<div className='form__section--body'>
				<Grid container>
					<Grid item xs={12} lg={7}>
						<div className='section__user-prerequisites'>
							<FormControl component='fieldset'>
								<div className='prerequisite-box'>
									<h3 className='strong-text'>2A: A qualified student must meet one of the following prerequisites</h3>
									<span className='instruction'>(Check the boxes that most applies):</span>
									<FormControlLabel
										control={<Checkbox color='primary' name='a1' checked={formState.a1} />}
										label='Student has an IEP, MET or ISP from an Arizona public school (for preschool and grades K-12)'
										onChange={handleCheckboxChange}
									/>
									<FormControlLabel
										control={<Checkbox color='primary' name='a2' checked={formState.a2} />}
										label='Student has a 504 plan from an Arizona public school (for grades K-12 only)'
										onChange={handleCheckboxChange}
									/>
									<FormControlLabel
										control={<Checkbox color='primary' name='a3' checked={formState.a3} />}
										label='Student was placed at one time in the Arizona foster care system (for grades K-12 only and to be verified by the Arizona Department of Child Safety (DCS))'
										onChange={handleCheckboxChange}
									/>
								</div>
								<div className='prerequisite-box'>
									<h3 className='strong-text'>Section 2B</h3>
									<FormControl className='form-control-checkbox'>
										<label>
											1 - Has student previously received a Disabled/Displaced scholarship from the Arizona Leadership Foundation? <i>(If Not, please proceed to Question 3 below)</i>
										</label>
										<FormGroup>
											<FormControlLabel control={<Checkbox color='primary' name='b1' value='yes' checked={formState.b1} />} label='Yes' onChange={handleCheckboxChange} />
											<FormControlLabel control={<Checkbox color='primary' name='b1' value='no' checked={formState.b1 == false} />} label='No' onChange={handleCheckboxChange} />
										</FormGroup>
									</FormControl>
									<FormControl className='form-control-checkbox'>
										<label>
											2 - If your child has received an Arizona Leadership Foundation Disabled / Displaced Scholarship, has their primary disability category changed from the previous school year?
										</label>
										<FormGroup>
											<FormControlLabel control={<Checkbox color='primary' name='b2' value='yes' checked={formState.b2} />} label='Yes' disabled={!formState.b1} onChange={handleCheckboxChange} />
											<FormControlLabel
												control={<Checkbox color='primary' name='b2' value='no' checked={formState.b2 == false} />}
												label='No'
												disabled={!formState.b1}
												onChange={handleCheckboxChange}
											/>
										</FormGroup>
									</FormControl>
									<span className='instruction'>If yes, please provide a copy of the updated MET/IEP and proceed to Section 3.</span>
									<div className='form-control-fileuploader'>
										{version >=30 ? (
											<MediaManager disabled={!formState.b2} keyName="b2_blueprint"/>
										):(
											<B2BlueprintFile applicationId={applicationId} token={tokenAuth} disabled={!formState.b2} files={files} />
										)}																				
									</div>
									<FormControl className='form-control-checkbox'>
										<label>
											3 - Student needs D/D qualification. <br /> If you answered NO to Question 1, complete / include info below:
										</label>
										<FormControlLabel
											control={<Checkbox color='primary' name='b31' value='yes' checked={formState.b31} />}
											label='Provide a copy of the Student’s IEP, MET, ISP or 504 plan from an Arizona public school with this application.'
											disabled={((formState.b1 == null || formState.b1 == true) && (formState.b1 == null || formState.b1 == false || formState.b2 == null || formState.b2 == false)) || formState.b2}
											onChange={handleCheckboxChangeB3}
										/>
									</FormControl>
									<div className='indented'>
										<div className='form-control-fileuploader'>
											{version >=30 ? (
												<MediaManager disabled={!formState.b31} keyName="b3_blueprint"/>
											):(
												<B3BlueprintFile applicationId={applicationId} token={tokenAuth} disabled={!formState.b31} files={files} />
											)}																				
										</div>
										<TextField
											label='List Student’s primary disability category found on the IEP or MET'
											name='b311_primaryDisability'
											value={formState.b311_primaryDisability}
											color='primary'
											variant='outlined'
											inputProps={{ maxLength: 100 }}
											onChange={handleTextFieldChange}
											disabled={!formState.b31}
											fullWidth
										/>
									</div>
									<span className='or-separator'>AND/OR</span>
									<FormControl className='form-control-prerequisite_7'>
										<FormControlLabel
											control={<Checkbox color='primary' name='b32' value='no' checked={formState.b32} />}
											label='Provide additional information to verify student was placed in Arizona foster care (A.R.S. Title 8, Chapter 5)'
											disabled={((formState.b1 == null || formState.b1 == true) && (formState.b1 == null || formState.b1 == false || formState.b2 == null || formState.b2 == false)) || formState.b2}
											onChange={handleCheckboxChangeB3}
										/>
										<div className='indented'>
											<TextField
												label='Student’s Social Security Number'
												name='b321_ssn'
												value={formState.b321_ssn}
												color='primary'
												variant='outlined'
												inputProps={{ maxLength: 9 }}
												onChange={handleNumericFieldChange}
												disabled={!formState.b32}
												fullWidth
											/>
											<TextField
												label='Any previous names the child might have gone by'
												name='b322_previousNamesChild'
												value={formState.b322_previousNamesChild}
												color='primary'
												variant='outlined'
												inputProps={{ maxLength: 100 }}
												onChange={handleTextFieldChange}
												disabled={!formState.b32}
												fullWidth
											/>
										</div>
									</FormControl>
								</div>
							</FormControl>
						</div>
					</Grid>
					{formError && (
						<Grid item xs={12}>
							<Alert severity='error' onClose={() => setFormError(null)}>
								{formError}
							</Alert>
						</Grid>
					)}
				</Grid>
			</div>

			{lock && <div className='layer-locked' />}
		</div>
	);
};

export default DDPrerequisites;
