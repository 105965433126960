import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Box, Button, TextField, Typography } from '@material-ui/core';
import Api from '../../config/api';
import { REGEX } from '../../utils/constants';
import LayoutLogin from '../../component/partials/LayoutLogin';
import Loader from '../../component/Loader';
import {useHistory} from 'react-router-dom';
import Snackbar from '../../component/Snackbar';

const SuccessBox = ({ email, isPhoneNumber }) => {

	const [codeError, setCodeError] = useState({ error: false, message: '' });
	const [loading, setLoading] = useState(false);
	const [openSnackBar, setOpenSnackBar] = useState(false);
	const [code, setCode] = useState('');
	const history = useHistory();

	const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
	}


	const handleChange = (event) => {
		const { value } = event.target;
    setCode(value);
		setCodeError({ error: false, message: '' });
	};

	const handleSubmit = (e) => {
    e.preventDefault();
		
		const isValidCode = REGEX.recoveryCodeRegEx.test(code);
		if (!isValidCode) return setCodeError({ error: true, message: 'Please enter a valid 6 digit code' });
		setLoading(true);

		Api.ValidateRecoveryCode({ code: code })
		.then((res) => {
      const { status, message } = res;
      setLoading(false);
      if (status === 400) {
			  return setCodeError({ error: true, message });
			} else {
        history.push(`/account/reset/${code}`);
			}
    })
		.catch((err) => console.log('Error: ', err));
	}

	const handleResendCode = async (e) => {
		e.preventDefault();
    setLoading(true);

	  await Api.RecoveryPassword({ email: email })
		  .then((res) => {
			  const { status, message } = res;
        setLoading(false);

			  if (status === 400)  {
				  return setCodeError({ error: true, message });
			  } else {
					setOpenSnackBar(true);
				}
      }
		).catch((err) => console.log('Error: ', err));
  }
	
	return (
	<Box className='auth-box-message' style={{ width: 350 }}>
		<Box className='forgot_success_box'>
			<h6>RECOVERY PASSWORD</h6>
			<p>
				We have sent you an {isPhoneNumber? "6 digit one time passcode" : "email"} to <span style={{ fontWeight: 'bold' }}>{isPhoneNumber? `+ *******${email.slice(7,10)}` : email}</span>
				{isPhoneNumber? ". To Continue, please enter the 6-digit-code. Recovery code will be expired in 3 minutes." : " with the steps to recover your password."}
			</p>
			{ !isPhoneNumber?
			  <><br />
			  <p>
				  <em>Note: if you cannot find our email, please check your spam or junk folders</em>
			  </p>
				</> : null
			}
		</Box>
		{isPhoneNumber?
		  <Box className='register_container_box'>
			<br />
			<Typography className='register_container_box_title'>SMS Code</Typography>
			<span className='register_span'></span>
			<form onSubmit={handleSubmit} className='register_form' autoComplete='off'>
				<TextField label='SMS Code' name='code' variant='outlined' size='small' onChange={handleChange} error={codeError.error} helperText={codeError.message} required />
				<Snackbar open={openSnackBar} severity={'success'} autoHideDuration={3000} text={'Sms recovery code has been resent'} handleClose={handleCloseSnackBar} />
				<Box className='recovery_button'>
					{!loading ? (
						<>
						  <Button style={{backgroundColor: "#a0201c"}} type='submit' variant='contained' onClick={handleResendCode}>
								RESEND
							</Button>
							<Button type='submit' variant='contained'>
								CONTINUE
							</Button>
						</>
					) : (
						<div style={{ marginTop: 15 }}>
							<Loader loading={true} />
						</div>
					)}
				</Box>
			</form>
		</Box> :
		  <Box className='button__container'>
			<Link to='/'>
				<Button variant='contained'>Back to login</Button>
			</Link>
		</Box>
    }
	</Box>
);
};

const FormBox = ({ handleComplete, handleEmail, handlePhoneNumber }) => {
	const [emailError, setEmailError] = useState({ error: false, message: '' });
	const [loading, setLoading] = useState(false);
	const [emailRecovery, setEmailRecovery] = useState(false);
	const [phoneRecovery, setPhoneRecovery] = useState(false);
	const [field, setField] = useState('');

	const handleChange = (event) => {
		const { value } = event.target;

		setField(value);
		setEmailError({ error: false, message: '' });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
   
 	  const isEmail = REGEX.emailRegEx.test(field);
		const isPhoneNumber = REGEX.phoneRegEx.test(field);

		if (emailRecovery && !isEmail) return setEmailError({ error: true, message: 'Enter a valid email' });
    if (phoneRecovery && !isPhoneNumber) return setEmailError({ error: true, message: 'Enter a valid 10 digits phone number' });
		setLoading(true);

		await Api.RecoveryPassword({ email: field })
			.then((res) => {
				const { status, message } = res;

				setLoading(false);

				if (status === 400)  {
					return setEmailError({ error: true, message });
				} else {
          handleEmail(field);
			    handlePhoneNumber(isPhoneNumber)
			    handleComplete(true);
				}

			})
			.catch((err) => console.log('Error: ', err));
			
	};

	return (
		<Box className='register_container_box'>
			<Typography className='register_container_box_title'>Forgot Password</Typography>
			{!emailRecovery && !phoneRecovery &&
			  <>
				  <span className='register_span' style={{fontSize:"12px"}}>Choose option to restore password:</span>
					<Box className='recovery_button'>
				    <Button variant='contained' onClick={()=>{setEmailRecovery(true)}}>
					    EMAIL MESSAGE
				    </Button>
				    <Button variant='contained' onClick={()=>{setPhoneRecovery(true)}}>
				      SMS CODE
		        </Button>
					</Box>
				</>
      }
			{(emailRecovery || phoneRecovery) &&
				<form onSubmit={handleSubmit} className='register_form' autoComplete='off'>
				  <TextField label={emailRecovery? "Email" : "Phone Number"} name='email' variant='outlined' size='small' onChange={handleChange} error={emailError.error} helperText={emailError.message} required />
				  <Box className='recovery_button'>
					  {!loading ? (
						  <Button type='submit' variant='contained'>
							  Submit
						  </Button>
					  ) : (
						  <div style={{ marginTop: 15 }}>
							  <Loader loading={true} />
						  </div>
					  )}
				  </Box>
			  </form>
      }
		</Box>
	);
};

const ForgotPassword = (props) => {
	const [isComplete, setIsComplete] = useState(false);
	const [email, setEmail] = useState('');
	const [isPhoneNumber, setIsPhoneNumber] = useState(false);

	return (
		<LayoutLogin title={isPhoneNumber? 'SMS Code': 'Forgot Password'}>
			<Grid item xs={12}>
				<Box className='register_container'>
					{ !isComplete ? 
					  <FormBox handleComplete={setIsComplete} handleEmail={setEmail} handlePhoneNumber={setIsPhoneNumber} /> :
						<SuccessBox email={email} isPhoneNumber={isPhoneNumber} />
					}
				</Box>
			</Grid>
		</LayoutLogin>
	);
};

export default ForgotPassword;
