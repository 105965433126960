// StartApplication/DisabledDetails.jsx
import React from 'react';
import { Box, Typography, Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { wrap } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    padding: theme.spacing(3),
  },
  requirementsList: {
    listStyleType: 'none',
    padding: 0,
  },
  requirement: {
    position: 'relative',
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '0.5em',
      width: 8,
      height: 8,
      backgroundColor: theme.palette.text.primary,
      borderRadius: '50%',
    },
  },
  actionButton: {
    marginTop: theme.spacing(3),
  },
  footer: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  subTitle: {
    display: 'flex',
    alignItems: 'center',    
    marginBottom: 25,
    paddingBottom: 10,
    borderBottom: '2px solid #000',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'wrap'
    }
  },
}));

const DisabledDetails = ({ onStartApplication }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h3 className={classes.subTitle}>Student Eligibility Criteria&nbsp;<small>(must meet 1)</small></h3>
      <Box sx={{ bgcolor: 'secondary.light', p: 2, height: '100%' }}>
        <ul className={classes.requirementsList}>
          <li className={classes.requirement}>
            Student has an IEP, MET or ISP from an Arizona public school (for preschool and grades K-12)
          </li>
          <li className={classes.requirement}>
            Student has a 504 plan from an Arizona public school (for grades K-12 only)
          </li>
          <li className={classes.requirement}>
            Student was placed at one time in the Arizona foster care system (for grades K-12 only and to be verified by the Arizona Department of Child Safety [DCS])
          </li>
        </ul>
      </Box>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}          
          size="large"
          className={classes.actionButton}
          onClick={onStartApplication}
        >
          Start new Disabled/Displaced application
        </Button>
      </div>
      <Box className={classes.footer}>
        <Typography variant="body2">
          One student per application. If you need to apply for multiple students, you must complete one application for each student.
        </Typography>
      </Box>
    </div>
  );
};

export default DisabledDetails;