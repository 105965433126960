import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {CircularProgress} from '@material-ui/core';
import { getApplicationRdx, setApplicationRdx, setLoadingApplicationRdx } from '../../redux/actions/application';
import Uploader from './UploaderMedia';
import Attachment from './UploaderBox';
import NoAttachment from './UploaderBoxEmpty';
import _uniqueId from 'lodash/uniqueId';

function MediaManager({title, info, keyName, readOnly = false, disabled = false,application, account, onUploading = () => {}, onUploaded = () => {}, setApplicationRdx/*, getApplicationRdx, setLoadingApplicationRdx*/}) {

	const { tokenAuth } = account;
	const { data } = application;
	const [media, setMedia] = useState([]);

	useEffect(() => {
		if(data.media){
			setMedia(
				data.media.filter(m=>m.key == keyName)
			);
		}
	},[application]);

	const handleUplaoded = uploaded => {		
		// call method when is uploaded file
		const updatedMedia = [...data.media,uploaded];
		setApplicationRdx({...data, media:[...updatedMedia]});
		onUploaded();
	};
	
	const handleUplaoding = (file) => {
		// call method when is uploading file
		onUploading(file);
	};

	const handleViewed = (id, url) => {

	};

	const handleDeleted = (id) => {	
		const updatedMedia = data.media.filter(m=>m.mediaId != id);
		setApplicationRdx({...data, media:[...updatedMedia]});
	};

	return (
			<div className={`form-control-media`}>				
				{!!title && <label className="media-title">{title}</label>}
				{!!info && <span className="media-info">{info}</span>}
				{!application.loading ? 
					media.length > 0 ? media.map((item, idx) => (
						<div className='media__file' key={_uniqueId('item.id')}>
							<Attachment token={tokenAuth} media={item} onViewedMedia={handleViewed} onDeletedMedia={handleDeleted} readOnly={readOnly} disabled={disabled}/>
						</div>
					)) : readOnly && <NoAttachment/>
				:(
					<div>
						<CircularProgress size={18} />
					</div>
				)}
				{!readOnly && (
					<div className={media.length>0?"media__upload last":"media__upload"}>
						<Uploader token={tokenAuth} referenceId={data.applicationId} keyName={keyName} disabled={disabled} onUploaded={handleUplaoded} onUploading={handleUplaoding}/>
					</div>
				)}			
			</div>
	)
};

const mapStateToProps = (s) => {
	return {
		application: s.applicationRdx, 
		account: s.account
	};
};

const mapDispatchToProps = {
	getApplicationRdx, setApplicationRdx, setLoadingApplicationRdx
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaManager);



