
import React from 'react';
import { Container, Grid, Typography, Box, Button, Link } from '@material-ui/core';
import { Facebook, Instagram, LinkedIn, Phone } from '@material-ui/icons';
import KeyboardArrowUpOutlinedIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import { makeStyles } from '@material-ui/core/styles';

// Definiendo estilos personalizados
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#18191a',
    color: 'white',
    padding: theme.spacing(3, 0),
  },
  logo: {
    maxWidth: '230px',
    height: 'auto',
	filter: 'brightness(0) invert(1)',
	marginBottom: '30px',    
  },
  intro: {
    maxWidth: '280px',
    fontFamily: '"Rubik", sans-serif',
    letterSpacing: '0.8px',
    fontSize: '14px',
  },
  link: {
    color: 'white',
    display: 'block',
    marginBottom: '16px',
    lineHeight: '16px',
    fontFamily: "'Rubik', sans-serif",
    fontSize: '15px',
    textDecoration: 'none', 
    '&:hover': {
      textDecoration: 'underline', 
    },
  },
  quickLinksTitle: {
    fontSize: '23px',
    textTransform: 'unset',
    letterSpacing: '0',
    marginBottom: '13px',
    fontWeight: '500',
    fontStyle: 'italic',
    fontFamily: "Math, 'Rubik', sans-serif",
  },
  socialIcon: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  backToTop: {
    position: 'fixed',
    bottom: '0',
    right: '10px',
    background: 'gray',
    padding: '5px 4px !important',
	minWidth: '32px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    color: '#fff',
    border: '0',
    cursor: 'pointer', // Agregado para mejorar la interactividad
    zIndex: 1000, // Asegúrate de que esto no interfiera con otros elementos de tu página
    '&:hover': {
      // Puedes definir estilos para el estado hover si lo deseas
      backgroundColor: 'darkgray',
    },	
  },
  rights:{
	fontSize: '14px',
	fontFamily: "'Rubik', sans-serif",
	letterSpacing: '0.8px',
	lineHeight: '1.5',
	fontWeight: '600',
  },
  contactLink: {
    display: 'flex',
    alignItems: 'center', 
	color: 'white',    
    marginBottom: '16px',
    lineHeight: '16px',
    fontFamily: "'Rubik', sans-serif",
    fontSize: '15px',
    textDecoration: 'none', 
    '&:hover': {
      textDecoration: 'underline', 
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <img 
              src="/assets/img/logo.svg" 
              alt="Arizona Leadership Foundation Logo" 
              className={classes.logo}
            />
            <Typography variant="body2" className={classes.intro}>
              A School Tuition Organization cannot award, restrict, or reserve scholarships solely on the basis of a donor’s recommendation. A taxpayer may not claim a tax credit if the taxpayer agrees to swap donations with another taxpayer to benefit either taxpayer’s own dependent. (A.R.S. 43-1089)
            </Typography>
          </Grid>
		  <Grid item xs={12} sm={5}>
		  <Typography variant="h6" gutterBottom className={classes.quickLinksTitle}>
              Quick Links
            </Typography>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={5}>            
				<Link href="https://arizonaleader.org/students-families/" className={classes.link}>
				Students & Families
				</Link>
				<Link href="https://arizonaleader.org/corporate-champions/" className={classes.link}>
				Corporate Champions
				</Link>
				<Link href="https://arizonaleader.org/schools/" className={classes.link}>
				Schools
				</Link>
				{/* Add additional links similarly */}
			</Grid>
			{/* Repeat for other grid items with links */}
			<Grid item xs={12} sm={5}>
				<Link href="https://arizonaleader.org/about-us/" className={classes.link}>
				Mission
				</Link>
				<Link href="https://arizonaleader.org/leadership/" className={classes.link}>
				Leadership
				</Link>
				<Link href="https://arizonaleader.org/student-spotlights/" className={classes.link}>
				Student Spotlights
				</Link>
				<Link href="https://arizonaleader.org/community/" className={classes.link}>
				Community
				</Link>
			</Grid>
			</Grid>

		  </Grid>

          <Grid item xs={12} sm={3}>
            <Box display="flex" justifyContent="center">
              <Link href="https://www.facebook.com/ArizonaLeader/" className={classes.link}>
                <Facebook className={classes.socialIcon} />
              </Link>
              <Link href="https://www.instagram.com/azleader/" className={classes.link}>
                <Instagram className={classes.socialIcon} />
              </Link>
              <Link href="https://www.linkedin.com/company/10172033/" className={classes.link}>
                <LinkedIn className={classes.socialIcon} />
              </Link>
            </Box>
            <Box>
				<Link href="mailto:apply@arizonaleader.org" className={classes.contactLink}>
				<MailIcon className={classes.icon} /> apply@arizonaleader.org
				</Link>
			</Box>
			<Box>
				<Link href="tel:6025257355" className={classes.contactLink}>
				<PhoneIcon className={classes.icon} /> (602) 525-7355
				</Link>
			</Box>
          </Grid>
        </Grid>		
        <Box textAlign="center" pt={4}>
          <Typography variant="body2" className={classes.rights}>
		  	<hr/><br/>
            © 2024 Arizona Leadership Foundation. All Rights Reserved.
          </Typography>
          <Button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className={classes.backToTop}>
            <KeyboardArrowUpOutlinedIcon/>
          </Button>
        </Box>
      </Container>
    </footer>
  );
};

export default Footer;
