import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Alert, AlertTitle } from '@material-ui/lab';

import Layout from '../../component/partials/Layout';
import UserInformation from '../../component/partials/UserInformation';
import Loader from '../../component/Loader';
import LIView from './LIView';
import DDView from './DDView';

import API from '../../config/api';
import HelloSignClass from '../../utils/hellosign';

import { setApplicationRdx, setLoadingApplicationRdx } from '../../redux/actions/application';


const UserInformationButton = ({}) => (
	<Link to='/' className='text-decoration-none'>
		<Button variant='contained' size='small' color='primary' disableRipple>
			<span>Back to dashboard</span>
		</Button>
	</Link>
);

const ApplicationView = ({ account, history, setApplicationRdx, setLoadingApplicationRdx }) => {
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [application, setApplication] = useState(null);

	const location = useLocation();
	const params = useParams();

	const applicationType = location.pathname.split('/')[2].toUpperCase();
	const applicationName = applicationType == 'LI' ? 'Low Income' : 'Disabled/Displaced';
	const { period } = account.periodSettings;

	useEffect(() => getApplication(), []);

	const getApplication = () => {
		const { applicationId } = params,
			{ tokenAuth } = account;

		setLoadingApplicationRdx();			
		API.GetApplication(tokenAuth, { id: applicationId }).then((response) => {
			const { status, message, data } = response;
			if (status != 201 || status == 600) {
				setError(message);
			} else {
				setApplicationRdx(data);
				setApplication(data);							
			}		
			setLoading(false);
		});
	};

	const downloadDocument = () => {
		const { signatureRequestId } = application,
			{ tokenAuth } = account;

		const HelloSign = new HelloSignClass();

		HelloSign.signatureRequestId = signatureRequestId;
		HelloSign.token = tokenAuth;

		HelloSign.DownloadSignedDocument();
	};

	const htmlLabel = (label) => {
		return { __html: label };
	};

	return (
		<Layout history={history} period={period}>
			<div className='application-page application-form-page application-view-page'>
				<Grid container spacing={3}>
					<Grid item xs={12} md={4} lg={3}>
						<div className='user__column'>
							<UserInformation renderButton={<UserInformationButton />} />
						</div>
					</Grid>
					<Grid item xs={12} md={8} lg={9}>
						<div className='form__column'>
							<h3 className='section-title'>{applicationName} Application</h3>
							{!loading ? (
								<Fragment>
									{!error && (
										<div className='status__box'>
											<span>Application Status:</span>
											<h3>{application.statuslabel}</h3>
										</div>
									)}
									{application != null && (application.status === 6 || (application.status === 7 && application.rejectedReasons.length > 0)) && (
										<div style={{ marginBottom: 40, marginTop: 40 }}>
											<Alert severity='warning'>
												<AlertTitle>Reasons: </AlertTitle>
												<Fragment>
													<ul style={{ listStyleType: 'square', listStylePosition: 'inside' }}>
														{application.rejectedReasons.length > 0 &&
															application.rejectedReasons.map((x, i) => {
																return (
																	<li key={i} style={{ paddingBottom: 15 }}>
																		<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
																	</li>
																);
															})}
													</ul>
												</Fragment>
												{application.rejectedNotes != null && (
													<Fragment>
														<AlertTitle>Note:</AlertTitle>
														<span>{application.rejectedNotes}</span>
													</Fragment>
												)}
											</Alert>
										</div>
									)}
									{
									// Requested Remove the incomplete reasons when the status show Under Review on 20250212
									}
									{application != null && (/*application.status === 4 ||*/ (application.status === 7 && application.incompletedReasons.length > 0)) && (
										<div style={{ marginBottom: 40, marginTop: 40 }}>
											<Alert severity='warning'>
												<AlertTitle>Reasons: </AlertTitle>
												<Fragment>
													<ul style={{ listStyleType: 'square', listStylePosition: 'inside' }}>
														{application.incompletedReasons.length > 0 &&
															application.incompletedReasons.map((x, i) => {
																return (
																	<li key={i} style={{ paddingBottom: 15 }}>
																		<span style={{ marginLeft: -5 }} dangerouslySetInnerHTML={htmlLabel(x.reason)}></span>
																	</li>
																);
															})}
													</ul>
												</Fragment>
												{application.incompleteNotes != null && (
													<Fragment>
														<AlertTitle>Note:</AlertTitle>
														<span>{application.incompleteNotes}</span>
													</Fragment>
												)}
											</Alert>
										</div>
									)}
									<div className='application-form__content'>
										{applicationType == 'LI' ? <LIView application={application} account={account} error={error} /> : <DDView application={application} account={account} error={error} />}
									</div>
								</Fragment>
							) : (
								<Loader loading />
							)}
						</div>
						{!loading && !error && (
							<div className='application-buttons'>
								<Button variant='contained' color='primary' startIcon={<CloudDownloadIcon />} onClick={downloadDocument} disableRipple>
									Download signed document
								</Button>
							</div>
						)}
					</Grid>
				</Grid>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	return {
		account: state.account
	};
};
const mapDispatchToProps = {
	setApplicationRdx, setLoadingApplicationRdx
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationView);

