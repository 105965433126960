import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Message from './Message';
import CodeForm from './CodeForm';
import Modal from '../Modal';

import API from '../../config/api';

const components = { Message, CodeForm };

const AdminMessageAlert = ({ account, type = 'LI', open, onClose, onValidated }) => {
	const [content, setContent] = useState('Message');
	const [error, setError] = useState('');

	const handleSubmit = (code) => {
		setError('');
		API.ValidateSubmission(account.tokenAuth, { type:(type.toUpperCase()=='LI'?'LI':'DD'), code }).then((res) => {
			if (!res.data.validated) return setError('This code is not valid for this type of application');

			// localStorage.setItem('code', code)
			handleClose();
			onValidated(!!code);
		});
	};

	const handleClose = () => {
		onClose();
		setError('');
		setContent('Message');
	};

	const Content = components[content];

	return (
		<Modal open={open} onClose={handleClose} showHeader={false} autoHeight>
			<div className='admin-message-modal'>
				<Content account={account} error={error} onClose={handleClose} onSubmit={handleSubmit} onSetContent={(view) => setContent(view)} />
			</div>
		</Modal>
	);
};

AdminMessageAlert.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onValidated: PropTypes.func.isRequired,
};

export default connect((s) => ({ account: s.account }))(AdminMessageAlert);
