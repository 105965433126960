import React, { useState } from 'react';
import PropTypes from 'prop-types';

import API from '../config/api';

const FileUploader = ({ onChange, onUploadStart, id, applicationId, fileKey, studentId, financialId, token, disabled, fullWidth }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [file, setFile] = useState(null);

	const handleChange = async (file) => {
		const acceptedTypes = [
			'',
			'image/png',
			'image/jpeg',
			'application/pdf',
			'image/gif',
			'image/heic',
			'image/heif',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		];

		if (!file || !acceptedTypes.includes(file.type)) return setError('File extension not allowed.');
		else setError(null);

		setFile(file);
		setLoading(true);
		onUploadStart();

		// Request file upload permissions
		let data = { applicationId, fileKey, fileName: file.name };
		if (studentId) data.studentId = studentId;
		if (financialId) data.financialId = financialId;
		const apiResponse = await API.GetUploadFileData(token, data);
		//console.log('apiResponse :>> ', apiResponse);
		// Upload file to S3
		uploadFile(apiResponse.data, file);
	};

	const uploadFile = async (data, file) => {		
		const { url, fields } = data;
		console.log(data);
		const entries = Object.entries(fields);

		const formData = new FormData();

		for (const [key, value] of entries) {
			formData.append(key, value);
		}

		formData.append('file', file);

		await fetch(url, { method: 'POST', body: formData });

		setLoading(false);
		onChange({ file, url, fields });
	};

	const setClasses = () => {
		let classes = '';

		if (loading) classes += 'uploading ';
		if (disabled) classes += 'disabled ';
		if (fullWidth) classes += 'full-width';

		return classes;
	};

	const classes = setClasses();

	return (
		<div className={`file-uploader ${classes}`}>
			<input type='file' id={id} onChange={(e) => handleChange(e.target.files[0])} disabled={disabled} accept='.png, .jpg, .jpeg, .pdf, .gif, .docx, .doc, .xlsx, .xls, .pptx, .ppt, .heic, .heif' />
			<label htmlFor={id}>
				<div className='button'>{!loading ? 'Choose file' : 'Uploading...'}</div>
				<div className='file-name--holder'>{!file ? 'Upload your file' : file.name}</div>
			</label>
			{error && <span className='error-label'>{error}</span>}
		</div>
	);
};

FileUploader.propTypes = {
	onChange: PropTypes.func.isRequired,
	onUploadStart: PropTypes.func,
	id: PropTypes.string,
	disabled: PropTypes.bool,
	fullWidth: PropTypes.bool,
	token: PropTypes.string.isRequired,
	applicationId: PropTypes.number,
	fileKey: PropTypes.string.isRequired,
};

FileUploader.defaultProps = {
	id: 'file-uploader-input',
	disabled: false,
	fullWidth: false,
	onUploadStart: () => {},
};

export default FileUploader;
